import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import EChartsReact from "echarts-for-react";

const data = {
  "data": {
    "hour": [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24
    ],
    "location": {
      "Aspen 2NE 2NW": [
        71,16,12,3,29,35,67,85,62,23,57,1,54,87,69,7,52,63,31,66,41,89,81,24
      ],
      "Aspen Diabetic Ed": [
        29,33,77,83,19,93,80,65,69,13,99,75,100,98,28,11,51,31,48,4,84,90,42,50
      ],
      "Aspen Endocrinology": [
        91,27,80,73,21,23,26,60,50,6,95,13,12,99,48,20,90,18,1,41,7,2,49,59
      ],
      "Aspen Internal Med": [
        79,43,86,22,35,40,100,23,29,66,99,85,7,19,60,95,32,20,6,51,24,50,88,94
      ],
      "Aspen Internal Med ID": [
        43,81,55,6,63,98,91,73,19,96,97,87,49,69,2,80,4,75,9,59,58,33,70,22
      ],
      "Aspen Lab": [
        33,85,59,75,25,78,79,87,97,90,94,28,49,93,15,8,81,92,65,77,54,76,62,69
      ],
      "Aspen MedRec": [
        6,39,11,54,56,89,32,58,41,4,62,13,31,43,1,82,46,76,79,27,19,38,88,25
      ],
      "Aspen Nephrology": [
        43,15,46,19,29,42,4,44,10,81,8,57,71,93,54,1,12,28,96,74,83,34,16,60
      ],
      "Aspen PedsEndo": [
        71,36,91,24,52,84,17,40,34,68,62,56,88,45,31,39,37,28,3,12,78,42,11,2
      ],
      "Aspen Pulmonology": [
        75,28,89,83,51,31,58,85,70,23,15,18,43,84,56,6,74,21,40,1,16,91,100,90
      ],
      "Aspen Rheumatology": [
        4,49,89,13,80,55,25,24,81,29,74,28,63,22,12,37,23,93,100,96,20,32,53,72
      ],
      "Aspen Weight Management": [
        83,21,4,10,85,56,99,26,60,78,42,18,91,41,84,7,63,35,81,30,97,29,67,90
      ],
      "Behavioral Health": [
        56,51,37,70,41,99,50,11,86,28,60,76,73,43,85,57,53,8,3,38,6,100,35,94
      ],
      "Belle Fourche Clinic": [
        63,99,85,24,26,11,68,30,49,87,62,79,47,25,45,43,96,55,67,100,3,74,16,40
      ],
      "Buffalo Clinic": [
        14,37,42,8,88,43,6,65,11,31,70,35,52,21,45,99,100,56,80,81,72,16,38,78
      ],
      "Cancer Care Institute": [
        88,63,31,22,92,24,60,52,45,7,39,72,81,61,28,93,27,69,57,29,51,38,11,44
      ],
      "Custer Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "ENT-Audiology": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Family Med Residency Clinic ": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Heart & Vascular Institute": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Hill City Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Hot Springs Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Hot Springs Clinic South": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Lead-Deadwood Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "MHOSH Specialty": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "New Castle Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Others": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Pain Management": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Rehab and Neurology": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Spearfish 10th St Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Spearfish Derm Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Spearfish Ortho Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Spearfish Queen City Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Sturgis Massa Berry Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Urgent Care - North": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Urgent Care - West": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB Dermatology": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB General Surgery": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB Main Office": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB MedRec": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB Neuro Surgery": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB Orthopedic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB Plastic Surgery": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB Podiatry": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "WHPB Urology": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Wall Clinic": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      "Wound Care": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ]
    }
  }
}

const options = {
  tooltip: {
    position: 'top'
  },
  legend: {
    show: false
  },
  grid: {
    left: "15%",
    right: "5%",
    bottom: "10%"
  },
  xAxis: {
    type: 'category',
    splitArea: {
      show: true
    },
    axisTick: {
      show: false
    },
    axisLine: {
      lineStyle: {
        color: "#e1e1e1"
      }
    },
    axisLabel: {
      color: "#000"
    }
  },
  yAxis: [{
    type: 'category',
    splitArea: {
      show: true
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      overflow: "truncate",
      ellipsis: "...",
      width: 100
    },
    axisLine: {
      lineStyle: {
        color: "#e1e1e1"
      }
    },
    axisLabel: {
      color: "#000"
    }
  }],
  dataZoom: [{
    yAxisIndex: 0,
    type: "slider",
    dataBackground: {
      areaStyle: {
        color: "#f0f0f0",
        opacity: 0.05
      }
    },
    start: 100,
    end: 75,
    zoomLock: true,
    fillerColor: "#f0f0f0",
    borderColor: "#f0f0f0",
    handleSize: 0,
    handleStyle: {
      color: "#e1e1e1",
      borderColor: "#e1e1e1"
    },
    moveHandleSize: 90,
    height: 400,
    width: 15,
    brushSelect: false,
    showDetail: false,
    brushStyle: {
      color: "#e1e1e1",
      width: 10,
    }
  }],
  visualMap: {
    type: "piecewise",
    orient: "horizontal",
    align: "left",
    top: 10,
    right: 10,
    // pieces: [
    //   { min: 60 },
    //   { min: 31, max: 60 },
    //   { min: 1, max: 30 },
    // ],
    splitNumber: 5,
    min: 0,
    max: 100,
    calculable: true,
    realtime: true,
    // inRange: {
    //   color: ["#313695", "#4575b4", "#74add1", "#abd9e9", "#e0f3f8", "#ffffbf", "#fee090", "#fdae61", "#f46d43", "#d73027", "#a50026"]
    // }
  },
  series: [
    {
      name: '',
      type: 'heatmap',
      label: {
        show: true
      },
      itemStyle: {
        borderColor: "#fff",
        borderRadius: [2, 2, 2, 2]
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0.6)'
        }
      }
    }
  ]
}



const formatData = (data) => {
  const {hour = [], location = {}} = data.data || {}
  let yAxixData = Object.keys(location).sort((a, b) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  }), xAxisData = hour;
  let graphData = [];
  yAxixData.forEach(m => {
    xAxisData.forEach((n, i) => {
      graphData.push([n, m, location[m][i] || '-']);
    })
  });

  return {xAxisData, yAxixData, graphData}
}


export default function LocationHourChart(props) {
  
  const HeatMap = useRef(null);
  const [heatmapOptions, updateHeatMapOptions] = useState(options);

  let { selectedFilters = {} } = props || {};
  let selectedDate = new Date(selectedFilters?.selectedDate || selectedFilters?.toDate);

  useEffect(() => {
    let {xAxisData = [], yAxixData = [], graphData = []} = formatData(data);
    HeatMap.current && HeatMap.current.getEchartsInstance().setOption({
      yAxis: {
        data: yAxixData
      },
      xAxis: {
        data: xAxisData
      },
      series: [
        {
          data: graphData,
        }
      ]
    });
  }, [props.loader[props.name]]);



  return (
    <div className="daily-report-chart">
      <div className="report-chart-header">
        <div className="report-chart-title">
          {"Hourly Statistics by Location"}
          <div className="chart-subtitle">
            {`This section shows hourly statistics by location for ${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`}
          </div>
        </div>
      </div>
      <div className="heat-map-container">
        {props.loader[props.name] ?
          <CircularProgress style={{ color: "grey", margin: "100px auto" }} /> :
          <EChartsReact style={{ height: "520px" }} option={heatmapOptions} ref={HeatMap} />
        }
      </div>
    </div>
  );
}
