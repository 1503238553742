import React from "react";
import "./Login.css";
import { connect } from 'react-redux';
//mport Logo_grp1 from "../../utils/images/Logo_grp1.svg";
import rd_logo from "../../utils/images/rd_logo.svg";
import rd_name from "../../utils/images/rd_name.svg";
import hti_logo from "../../utils/images/hti_logo.svg";
import * as c from "../../utils/constants/constants";
import * as f from "../../commonFunctions/commonFunctions";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {TextField,InputAdornment,IconButton} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import api from "../../utils/API/api";
import { getGnrlSettings } from "../../redux/generalSettings/actions/actions";

/* Static login screen configured with LDAP server. If SSO constant is made "false", this page will appear*/

class Login extends React.Component {
  state = {
    showPassword: false,
    userName: "",
    password: "",
    signInSuccess: '',
    public_ip : ''
    
  }
  componentDidMount = () => {
    localStorage.clear();
    document.title = c.PRODUCT_NAME +' - Login';
    api.get_ip().then((response) => {
    //  console.log('ip addresssssssss ',response.data.ip);
      this.setState({public_ip : response.data.ip});
  })
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleSubmit = () => {
    api.login(this.state.userName, this.state.password,this.state.public_ip)
      .then((response) => {
         localStorage.setItem(c.ACCESS_TOKEN, response.data.data[0].auth_token);
         localStorage.setItem(c.REFRESH_TOKEN, response.data.data[0].refresh_token);
         localStorage.setItem(c.USER_ROLE, f.getUserInfo(localStorage.getItem(c.ACCESS_TOKEN)).roles[0]);
         localStorage.setItem(c.USER_ID, f.getUserInfo(localStorage.getItem(c.ACCESS_TOKEN)).user_id);
        if (response.data.data[0].status === "Success") {
          this.props.getGnrlSettings(c.ROOT_URL+'/getDocuSettings');
          this.setState({ signInSuccess: true }, () => {
            this.handlePageChange(this.state.signInSuccess);
            });
        }
      })
      .catch((error) => {
        this.setState({ signInSuccess: false, userName: "", password: "" });
      });
     // localStorage.setItem('ui_comp',JSON.stringify(c.UI_COMP));
     // localStorage.setItem('ui_action',JSON.stringify(c.UI_ACTION));
  
  };
  handlePageChange = (msg) => {
    var _props = this.props;
    if (msg === true) {
      setTimeout(function () {
        _props.handlePageChange(c.PAGE_DASHBOARD);
        if(localStorage.getItem(c.USER_ROLE) === "Staff")
          _props.history.push('/docManagement');
        else
          _props.history.push('/overview');
      }, 1500);
    }
  };
  findKey = (e) => {
  
      if(e.key === 'Enter'){
         this.handleSubmit();
       }else
         return;
   }
 
  render() {
  
    return (
      <>
        <div className="login-page" onKeyPress = {(e) => this.findKey(e)}>
          <div className="topBox">
            <div>
              <img className="logoGrp" src={rd_logo} alt="rd_logo" />
              <div className="textDiv">
                 {/* <p className="docu">read</p>
                <p className="docu" style = {{fontWeight : '300'}}>abl.ai</p>  */}
                 <img  className="docu"  src={rd_name} alt="rd_name" /> 
                
              </div>
              </div>
          
          </div>
          <div className = 'bottom-box'>
          <div className="cardStyle">
            <div className="top-color"></div>

            <div className="user-input-username">
              <p className="text-style">Username</p>
             
             {/*<input type = 'text' className="text-box"/>*/}
             <TextField variant = 'outlined' 
           
             error = {this.state.signInSuccess === false && 'true'}
             margin = 'dense' 
             className = 'text-box' 
             value = {this.state.userName}
             onChange={(event) =>
              this.setState({ userName: event.target.value,signInSuccess : '' })
            }/>
            </div>
            <div className="user-input-pwd">
              <p className="text-style">Password</p>
              {/*<input type = 'password' className="text-box"/>*/}
              <TextField 
               error = {this.state.signInSuccess === false && 'true'}
              variant = 'outlined' 
              margin = 'dense' 
              className =  'text-box' 
              value = {this.state.password}
              onChange={(event) =>
                this.setState({ password: event.target.value,signInSuccess : '' })
              }
              type={this.state.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }} />
            </div>
            <div className="user-input-login" style = {{marginTop : '1.5em'}}>
              <div><button className="button-style" type="submit" onClick = {this.handleSubmit}>
                 <p className="button-text">Login</p>
               
              </button></div>
              <div>
              <p className = 'forgot-pwd'></p>
              </div>
            </div>
            {this.state.signInSuccess === false ?
            <div className = 'msg-div'>
              <ErrorIcon color = 'secondary'/>
              
            <p className = 'error-msg'>Invalid Credentials. Please try again.</p>
            
            </div>
            : this.state.signInSuccess === true ?
            <div className = 'msg-div'>
             <CheckCircleIcon style = {{color : 'green'}}/>
             <p className = 'success-msg'>Login Success.</p>
             </div>
             : null
            }
            
            <div className="horizontal-line"></div>

            <div className = 'logo-transparent'>
              <img src={hti_logo} alt="hti_logo" />
            </div>
          </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getGnrlSettings: (url) => dispatch(getGnrlSettings(url)),
   
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);