import React, { useEffect, useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import "./FetchID.css";
import EHRPopup from "./EHRPopup/EHRPopup";
import api from "../../../../utils/API/api";
import { forceLogout } from "../../../../commonFunctions/forceLogout";
import * as msg from "../../../../utils/constants/messages";
import * as c from "../../../../utils/constants/constants";
import * as f from "../../../../commonFunctions/commonFunctions";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

/*This component takes care of accordion that displays encounter/order details and link to open popup of EHR details
tabvalue is set as 0 if doc_level is Patient, 1 if doc_level is Encounter, 2 if doc_level is Order*/
const FetchID = (props) => {
  const [fetch_id, setFetchID] = useState(false);  //handles EHR popup open/close
  const [tabValue, settabValue] = useState(0); //to set tab value. patient:0, encounter:1, order:2
  const [tabLoader, settabLoader] = useState(false);  //to on/off spinner for each tab
  const [encData, setencData] = useState("");  //stores encounter data fetched from API
  const [orderData, setorderData] = useState(""); //stores order data fetched from API
  const [orderFilteredData, setorderFilteredData] = useState(""); //stores order data when filters applied
  const [encdispData, setencdispData] = useState({});  //stores encounter data to be displayed on main screen
  const [orddispData, setordDispData] = useState({}); //stores order data to be displayed on main screen
  const [patientDisData, setPatientDisData] = useState({}); //stores order data to be displayed on main screen
  const [ehrAccordion, setehrAccordion] = useState(true); //handles expand/collapse of EHR accordion on main screen
  const [orderCategoryList, setOrderCategoryList] = useState([]); // stores the order category list data

  useEffect(() => {
    if (!props.all_data.Entity_Details.ehrDetails) {
      setencdispData({});
      setordDispData({});
      setPatientDisData({});
    } else {
      if (
        props.doc_level === "Encounter" &&
        props.all_data.Entity_Details.ehrDetails
      ) {
        setencdispData(props.all_data.Entity_Details.ehrDetails.encounter);
      } else if (
        props.doc_level === "Order" &&
        props.all_data.Entity_Details.ehrDetails
      ) {
        setordDispData(props.all_data.Entity_Details.ehrDetails.order);
      } else if (
        props.doc_level === "Patient" &&
        props.all_data.Entity_Details.ehrDetails
      ) {
        setPatientDisData(props.all_data.Entity_Details.ehrDetails.patient || {});
      }
    }
  }, [props.doc_level, props.all_data.Entity_Details.ehrDetails]);

  /*function to open/close EHR popup, set tab value based on doc_level< and clr data stored in localstored if popup is closed*/
  const fetchID = (action, doc_level) => {
    if (action === "open") {
      setFetchID(true);
      settabValue(doc_level === "Encounter" ? 1 : doc_level === "Order" ? 2 : 0);
    } else {
      localStorage.removeItem(c.ENC_DATA);
      localStorage.removeItem(c.ORDER_DATA);
      setFetchID(false);
    }
  };
  /*sorts data by date in descing order*/
  const sortByDate = (data, type) => {
    var res = "";
    if (type === "order") {
      res = data.sort((a, b) => {
        let start = a.authoredOn && new Date(a.authoredOn), end = b.authoredOn && new Date(b.authoredOn);
        if (start < end) return 1;
        if (start > end) return -1;
        return 0;
      }
      );
      return res;
    } else if (type === "encounter") {
      res = data.sort((a, b) => {
        let start = a.period["start"] && new Date(a.period["start"]), end = b.period["start"] && new Date(b.period["start"]);
        if (start < end) return 1;
        if (start > end) return -1;
        return 0;
      }
      );
      return res;
    }
  };

  const handleOrderData = (rawData, filters) => {
    let data = JSON.parse(JSON.stringify(rawData))
    let { category, status, fromDate, toDate } = filters || {};
    if (category) data = data.filter(m => m.category?.toLowerCase() == category?.toLowerCase());
    if (fromDate) data = data.filter(m => m.authoredOn && new Date(m.authoredOn) > new Date(fromDate));
    if (toDate) data = data.filter(m => m.authoredOn && new Date(m.authoredOn) < new Date(toDate));
    setorderFilteredData(data)
  }
  /*API call to fetch patient encounters*/
  const patientEncounters = (encFilter) => {
    localStorage.setItem(c.ENC_DATA, JSON.stringify(encFilter));
    settabLoader(true);
    var query = {
      doc_id: localStorage.getItem("doc_id"),
      do_type_filter: true,
      start_date: encFilter.fromDate ? f.dateToUtc(encFilter.fromDate, "from") : "",
      end_date: encFilter.toDate ? f.dateToUtc(encFilter.toDate, "to") : ""
    };
    api
      .patient_encounters(query)
      .then((response) => {
        if (response.status === 200) {
          setencData(sortByDate(response.data.data, "encounter"));
          settabLoader(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        } else {
          settabLoader(false);
          window.alert(msg.api_error);
        }
      });
  };
  /*API call to fetch patient related orders*/
  const patientOrders = (orderFilter) => {
    localStorage.setItem(c.ORDER_DATA, JSON.stringify(orderFilter));
    settabLoader(true);
    var query = {
      doc_id: localStorage.getItem("doc_id"),
      status: orderFilter.status,
      do_status_filter: true,
      do_intent_filter: true,
      bearer_token: "",
    };
    api
      .patient_orders(query)
      .then((response) => {
        if (response.status === 200) {
          let sortedData = sortByDate(response.data.data, "order");
          handleOrderData(sortedData, orderFilter)
          settabLoader(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        } else {
          settabLoader(false);
          window.alert(msg.api_error);
        }
      });
  };

  // fetch order category list
  const getOrderCategoryList = () => {
    api.order_category()
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          let list = (response?.data?.data || []).reduce((acc, m) => {
            if (!acc.includes(m.display_name)) acc.push(m.display_name);
            return acc;
          }, []);
          list = list.sort((first, second) => {
            if (first > second) return 1;
            if (first < second) return -1;
            return 0;
          });
          setOrderCategoryList(list)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        } else window.alert(msg.api_error);
      });
  }
  const tabHandleChange = (event, newValue) => {
    settabValue(newValue);
  };
  /*structures EHR data and saves to backend*/
  const saveEHRData = (selected_data, type) => {

    let tempItemCheck = selected_data.some(item => !item.document_completion_status || item.document_completion_status == "");

    if (tempItemCheck && type === "order") {
      return props.onDialogAction("order", "open");
    }

    else {

      fetchID();
      var ehr = {
        "ehrDetails": {
          "order": {},
          "encounter": {}
        }
      };

      if (!props.all_data.Entity_Details.ehrDetails && selected_data.length === 0)
        return;
      else {
        //structuring the data and saving to backend. included the fields that are required to eb dislayed in main screen
        if (type === "encounter" && selected_data.length !== 0) {
          ehr.ehrDetails["encounter"] = convertArrayToObject(selected_data, "identifier");
          setencdispData(ehr.ehrDetails["encounter"]);
        } else if (type === "order" && selected_data.length !== 0) {
          ehr.ehrDetails["order"] = convertArrayToObject(selected_data, "identifier");
          setordDispData(ehr.ehrDetails["order"]);
        }
        props.saveEHRDetails(type, ehr);
      }
    }
  }

  /*converts objects of array to key value pairs*/
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };
  /*checks if key is present in atleats any one of the orders/encounters*/
  const isKeyPresent = (data, key) => {
    let res = data && Object.values(data).some((val) => val[key])
    return res;
  };
  return (
    <>
      <div className="fetch-button-section">
        <div className="ehr-accordion">
          <Accordion expanded={ehrAccordion}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="ehr-accordion-header" >
                <div
                  className="id-fetch-button"
                  onClick={() => !props.all_state.isDuplicateView && fetchID("open",props.doc_level)}
                >
                  <div>{props.doc_level === "Encounter" ? "Patient Encounters" : (props.doc_level === "Order" ? "Patient Related Orders" : (props.doc_level === "Patient" ? "Patient Info" : ""))}</div>
                  <LaunchIcon fontSize="small"
                    style={{ paddingLeft: "5px" }} />
                </div>
                {ehrAccordion ? <ExpandLessIcon onClick={() => setehrAccordion(!ehrAccordion)} />
                  : <ExpandMoreIcon onClick={() => setehrAccordion(!ehrAccordion)} />}
              </div>

            </AccordionSummary>
            <AccordionDetails>
              <div className="ehr-screen-display">
                {props.doc_level === "Encounter" && Object.entries(encdispData).length !== 0 ? (
                  <table>
                    <tr>
                      <th>CSN</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Encounter Name</th>
                      {isKeyPresent(encdispData, "onbase_confirmation") && isKeyPresent(encdispData, "onbase_id") && <th>Onbase Status</th>}
                      {isKeyPresent(encdispData, "epic_confirmation") && <th>EPIC Status</th>}
                    </tr>
                    {Object.values(encdispData).map((enc, i) => (
                      <tr key={i}>
                        <td>{enc.identifier}</td>
                        <td>{enc.period["start"] && f.formatDateEHR(enc.period["start"])}</td>
                        <td>{enc.period["end"] && f.formatDateEHR(enc.period["end"])}</td>
                        <td>{enc.type.toString()}</td>
                        {enc.onbase_confirmation && enc.onbase_id && <td>
                          <div className={enc.onbase_confirmation?.toLowerCase() === "success" ? "ehr-success" : "ehr-failure"}>
                            {enc.onbase_confirmation.toLowerCase() === "success" ? <CheckCircleOutlinedIcon fontSize="small" /> : <HighlightOffOutlinedIcon fontSize="small" />}
                            <div>{enc.onbase_id}</div>
                          </div>

                        </td>}
                        {enc.epic_confirmation && <td>
                          <div className={enc.epic_confirmation?.toLowerCase() === "success" ? "ehr-success" : "ehr-failure"}>
                            {enc.epic_confirmation.toLowerCase() === "success" ? <CheckCircleOutlinedIcon fontSize="small" /> : <HighlightOffOutlinedIcon fontSize="small" />}
                            <div>{enc.epic_confirmation}</div>
                          </div>

                        </td>}
                      </tr>
                    ))}
                  </table>
                ) : null}
                {props.doc_level === "Order" && Object.entries(orddispData).length !== 0 ? (
                  <table>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Order Name</th>
                      {isKeyPresent(orddispData, "onbase_confirmation") && isKeyPresent(orddispData, "onbase_id") && <th>Onbase Status</th>}
                      {isKeyPresent(orddispData, "epic_confirmation") && <th>EPIC Status</th>}
                    </tr>
                    {Object.values(orddispData).map((ord, i) => (
                      <tr key={i}>
                        <td>{ord.identifier}</td>
                        <td>{ord.authoredOn && f.formatDateEHR(ord.authoredOn)}</td>
                        <td>{ord.code}</td>
                        {ord.onbase_confirmation && <td>
                          <div className={ord.onbase_confirmation?.toLowerCase() === "success" ? "ehr-success" : "ehr-failure"}>
                            {ord.onbase_confirmation.toLowerCase() === "success" ? <CheckCircleOutlinedIcon fontSize="small" /> : <HighlightOffOutlinedIcon fontSize="small" />}
                            <div>{ord.onbase_id || ord.onbase_confirmation}</div>
                          </div>

                        </td>}
                        {ord.epic_confirmation && <td>
                          <div className={ord.epic_confirmation?.toLowerCase() === "success" ? "ehr-success" : "ehr-failure"}>
                            {ord.epic_confirmation.toLowerCase() === "success" ? <CheckCircleOutlinedIcon fontSize="small" /> : <HighlightOffOutlinedIcon fontSize="small" />}
                            <div>{ord.epic_confirmation}</div>
                          </div>

                        </td>}
                      </tr>
                    ))}
                  </table>
                ) : null}
                {props.doc_level === "Patient" && Object.entries(patientDisData).length !== 0 ? (
                  <table>
                    <tr>
                      <th>Name</th>
                      <th>DOB</th>
                      <th>MRN</th>
                      {patientDisData.onbase_confirmation && patientDisData.onbase_id && <th>Onbase Status</th>}
                      {patientDisData.epic_confirmation && <th>EPIC Status</th>}
                    </tr>
                    <tr>
                      <td>{patientDisData.official_name}</td>
                      <td>{patientDisData.DOB && f.formatDateEHR(patientDisData.DOB)}</td>
                      <td>{patientDisData.MRN}</td>
                      {patientDisData.onbase_confirmation && <td >
                        <div className={patientDisData.onbase_confirmation.toLowerCase() === "success" ? "ehr-success" : "ehr-failure"}>
                          {patientDisData.onbase_confirmation.toLowerCase() === "success" ? <CheckCircleOutlinedIcon fontSize="small" /> : <HighlightOffOutlinedIcon fontSize="small" />}
                          <div>{patientDisData.onbase_id || patientDisData.onbase_confirmation}</div>
                        </div>
                      </td>}
                      {patientDisData.epic_confirmation && <td >
                        <div className={patientDisData.epic_confirmation.toLowerCase() === "success" ? "ehr-success" : "ehr-failure"}>
                          {patientDisData.epic_confirmation.toLowerCase() === "success" ? <CheckCircleOutlinedIcon fontSize="small" /> : <HighlightOffOutlinedIcon fontSize="small" />}
                          <div>{patientDisData.epic_confirmation}</div>
                        </div>
                      </td>}

                    </tr>
                  </table>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

      </div>
      {fetch_id && (
        <EHRPopup
          fetch_id={fetch_id}
          fetchID={fetchID}
          doc_level={props.doc_level}
          all_data={props.all_data}
          handleChange={tabHandleChange}
          value={tabValue}
          tabLoader={tabLoader}
          encData={encData}
          patientEncounters={patientEncounters}
          saveEHRData={saveEHRData}
          patientOrders={patientOrders}
          orderData={orderFilteredData}
          orderCategoryList={orderCategoryList}
          getOrderCategoryList={getOrderCategoryList}          
        />
      )}
    </>
  );
};

export default FetchID;
