import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import * as c from "../../utils/constants/constants";
import * as dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getReportName = (props) => {
    return "Document: " + c.PRODUCT_NAME + " user management report."
}

const getCsvTitle = (props) => {
    return "readabl_usermanagement_report_" + dayjs().format("MM_DD_YYYY_hh_mm_A") + ".csv"
}


const UserExport = (props) => {
    const [dummy_headers, updateDummyHeaders] = useState([]);
    const [combined_json, updateCombinedJson] = useState([]);

    useEffect(() => {
        updateDummyHeaders([
            { label: getReportName(props), key: "s_no" }, 
            { label: "", key: "first_name" }, 
            { label: "", key: "last_name"},
            { label: "", key: "email"},
            { label: "", key: "role"},
            { label: "", key: "status"},
            { label: "", key: "last_login"},
            { label: "", key: "last_updated"},
        ]);

        const export_info = [
            { "s_no": "Export By:", "first_name": localStorage.getItem(c.USER_NAME)},
            { "s_no": "Export Date/Time:", "first_name": dayjs().format("MM/DD/YYYY, HH:mm:ss")},
        ];

        const empty_row = [
            { "s_no": "" }
        ];

        const report_end = [
            { "s_no": "<End of Report>", "first_name": "", }
        ];

        // console.log(locationCount)
        let userData = (props.data || []).map((m, i) => ({
            s_no: i + 1,
            first_name: m.first_name,
            last_name: m.last_name,
            email: m.email,
            role: m.role,
            status: m.status ? "Active" : "Inactive",
            last_login: m.last_logged_date && m.last_logged_time && dayjs(`${m.last_logged_date} ${m.last_logged_time}`).utc().format("MM/D/YYYY, hh:mm A") || "",
            last_updated: m.last_edited_date && dayjs(m.last_edited_date).utc().format("MM/D/YYYY, hh:mm A") || "",
        }))
        let combined_json = [];
        combined_json = export_info
            .concat(empty_row)
            .concat([{s_no: "Users List"}])
            .concat([
                {s_no: "S.no", first_name: "First Name", "last_name": "Last Name", "email": "Email", role: "Role", status: "Status", last_login: "Last Login", last_updated: "Last Edited"}
            ])
            .concat(userData)
            .concat(report_end);
        updateCombinedJson(combined_json);
    }, [props.data])

    return (
        <div className="export-report-link">
            <div className="button-as-link" onClick={props.generateExport}>Export</div>
            <CSVLink
                className="custom-date-range-export-link"
                headers={dummy_headers}
                filename={getCsvTitle(props)}
                data={combined_json}
                ref={props.csvLinkEl}
            />
        </div>
    )
}
export default UserExport;