import axios from 'axios';
import { saActions } from './actionCreators';
import {getToken} from "../../../commonFunctions/getToken";

export const getDocStuck =  ((url,query) => {
    return async(dispatch) => {
        dispatch(saActions.getDocStuckLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
       
        axios.post(url,query,options)
           .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(saActions.getDocStuckLoading(false));
                return response;
            })
            .then((response) => dispatch(saActions.getDocStuckSuccess(response.data)))
            .catch((error) => {
                dispatch(saActions.getDocStuckError(error.response.status))
                dispatch(saActions.getDocStuckLoading(false))
            });
    }
});
