
export const PATIENT_CREATE_ACTIONS = {};

PATIENT_CREATE_ACTIONS.TOGGLE_PATIENT_CREATE_POPUP = (({type, payload}) => {
    return {
        type,
        payload
    }
});
PATIENT_CREATE_ACTIONS.MAXIMISE_PATIENT_CREATE_POPUP = (({type, payload}) => {
    return {
        type,
        payload
    }
});
PATIENT_CREATE_ACTIONS.UPDATE_PATIENT_CREATE_DETAILS = (({type, payload}) => {
    return {
        type,
        payload
    }
});


