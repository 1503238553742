import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorIcon from "@material-ui/icons/Error";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import HelpIcon from "@material-ui/icons/Help";
import * as f from '../../../../commonFunctions/commonFunctions';
import {CustomTooltip} from "../../../../components/CustomTooltip/CustomTooltip";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';  
import ScheduleIcon from '@material-ui/icons/Schedule'; 
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ComputerIcon from '@mui/icons-material/Computer';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import * as c from "../../../../utils/constants/constants";
import { ReportProblem } from "@mui/icons-material";

class TableData extends React.Component {
  state = {
    data_id: "",
    open: "",
  };
  openCollapse = (id) => {
    this.setState({ open: true });
    this.setState({ data_id: id });
  };
  closeCollapse = () => {
    this.setState({ open: false });
  };
  getFlagsArray = (flag_list) => {
    var flag_disp = [];
    flag_list && flag_list.map((fg,i) => {
      if(fg.enabled)
        return flag_disp.push(fg.tag_display_name);
    })
    var new_list  = flag_disp.join(', ');
    return new_list;
  }
  isWaitingForEpicConfirmation = (data) => {
    let userUpdateTime = new Date(data.Last_Updated_Date + " " + data.Last_Updated_Time + " UTC");
    let modelUpdateTime = new Date(data.Last_Model_Updated_Date + " " + data.Last_Model_Updated_Time + " UTC");
    let currentDate = new Date();
    userUpdateTime.setMinutes(userUpdateTime.getMinutes() + 15);
    modelUpdateTime.setMinutes(modelUpdateTime.getMinutes() + 15);
    if((data.Status === c.status.uploading && userUpdateTime < currentDate ) || ((data.Status === c.status.uploaded || data.Status === c.status.failed)  && modelUpdateTime < currentDate)) return true;
    return false;
  }
  render() {
    return (
      <TableBody>
        {this.props.finalData &&
          this.props.finalData.map((mp, i) => (
            <TableRow
              key={i}
              className={
                mp.Active_Users.status === "active"
                  ? "table-row-blue"
                  : mp.Active_Users.status === "stuck"
                  ? "table-row-red"
                  : this.props.doc_id === mp.ID && this.props.right_plane
                  ? "table-row-color"
                  : "table-row"
              }
              onClick={() =>
                this.props.showRightPlane("open", mp.ID, mp.Format)
              }
            >
              <TableCell className="tableCellStyle" align="left">
                <div className="flex-inline">
                  <div
                    className={
                      (mp.Active_Users.status === "active" ||
                      mp.Active_Users.status === "stuck")
                        ? "file-lock-icon"
                        : "file-icon-hide"
                    }
                  >
                    <CustomTooltip
                      title={ "Opened by: "+ mp.Active_Users.user_details.map((user,i) => {
                        return user.first_name;
                      })}
                      placement="top-start"
                    >
                      <div className="file-lock-icon">
                        {mp.Active_Users.status === "active" ? (
                          <>
                          <ScheduleIcon className="red-icon" style = {{visibility : "hidden"}}/>
                          <SupervisorAccountIcon className="blue-icon" />
                          </>
                        ) : (
                          <>
                            <ScheduleIcon className="red-icon" />
                            <PersonIcon className="red-icon_1" />
                          </>
                        )}
                      </div>
                    </CustomTooltip>
                  </div>
                  <div>
                    {mp.Name.length > 20 ? (
                     
                        <div>
                           <CustomTooltip title={mp.Name} placement="top-start">
                          <a href={`/manualReview?doc_id=${mp.ID}`} className="doc-name-focus doc-decoration"  onClick={(e) => {
                          this.props.goToIntervention(mp.ID);
                          e.stopPropagation();
                        }}>
                            {mp.Name.length > 20
                              ? mp.Name.substr(0, 8) +
                                "..." +
                                mp.Name.substr(
                                  mp.Name.length - 10,
                                  mp.Name.length
                                )
                              : mp.Name}
                          </a>
                          </CustomTooltip>
                        </div>
                    
                    ) : (
                      <div>
                        <a href={`/manualReview?doc_id=${mp.ID}`} className="doc-name-focus doc-decoration"  onClick={(e) => {
                          this.props.goToIntervention(mp.ID);
                          e.stopPropagation();
                        }}>{mp.Name}</a>
                      </div>
                    )}
                      <div className="status-icon">
                             <p
                              style={{
                                margin: "0px",
                                fontSize: "0.75em",
                                color: "#a2a0a0",
                              }}
                            >
                              {mp.ID}
                            </p>
                            {this.getFlagsArray(mp.Tags) && <div className="vertical-mini-divider"></div>}
                            <CustomTooltip title={this.getFlagsArray(mp.Tags)} placement="top-start">
                            <p className="flag-array-ellipsis"
                             style={{
                              margin: "0px",
                              fontSize: "0.75em",
                              color: "#858282",
                            }}>
                             {this.getFlagsArray(mp.Tags)}
                             </p>
                             </CustomTooltip>
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell
                className="tableCellStyle"
                align={mp.Category === "" ? "center" : "left"}
              >
                <p style={{ margin: "0px" }}>{mp.Category}</p>
              </TableCell>
              <TableCell
                className="tableCellStyle"
                align={mp.Sub_Category === "" ? "center" : "left"}
              >
                <p style={{ margin: "0px" }}>{mp.Sub_Category}</p>
              </TableCell>
              <TableCell
                className="tableCellStyle"
                align={mp.Source === "" ? "center" : "left"}
              >
                <p style={{ margin: "0px" }}>{mp.location}</p>
              </TableCell>
              <TableCell
                className="tableCellStyle"
                align={mp.Patient_Name === "" ? "center" : "left"}
              >
                <div>
                  <div className="flex-inline">
                    <div
                      className={
                        mp.patient_match_in_EMR === false
                          ? "patient-icon-show"
                          : "patient-icon-hide"
                      }
                    >
                      <CustomTooltip
                        title="Patient not found in EMR"
                        placement="top-start"
                      >
                        <HelpIcon
                          style={{
                            color: "var(--hti-brick)",
                            fontSize: "1em",
                          }}
                        />
                      </CustomTooltip>
                    </div>
                    <div style={{ marginLeft: "3px" }}>
                      <div>{mp.Patient_Name}</div>
                      {mp.MRN && (
                        <div
                          style={{
                            fontSize: "0.75em",
                            color: "#a2a0a0",
                          }}
                        >
                          {mp.MRN}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TableCell>
              {/* <TableCell className="tableCellStyle" align="left">
                  <p style = {{margin : '0px'}}>{mp.Confidence_Level.toFixed(0) + "%"}</p>
                </TableCell> */}
              <TableCell className="tableCellStyle" align="left">
                <p style={{ margin: "0px" }}>
                  {f.utcToLocal(
                    mp.Received_Date + " " + mp.Received_Time + " UTC"
                  )}
                </p>
              </TableCell>
              <TableCell className={`tableCellStyle ${this.isWaitingForEpicConfirmation(mp) ? "add-report-icon" : "remove-report-icon"}`} align="left">
                <div
                  style={{ color: this.isWaitingForEpicConfirmation(mp) ? "#EB5757" : "#a2a0a0", display: "flex", alignItems: "center" }}>
                  {this.isWaitingForEpicConfirmation(mp) && mp.Staff_Name && mp.Last_Updated_Date && mp.Last_Updated_Time ? <ReportProblem style={{ fontSize: "1em", marginRight: "2px" }} /> : null}{mp.Staff_Name}
                </div>
                {mp.Staff_Name && mp.Last_Updated_Date && mp.Last_Updated_Time && (
                  <div
                    style={{
                      fontSize: "0.75em",
                      marginLeft: this.isWaitingForEpicConfirmation(mp) ? "15px" : "unset",
                      color: this.isWaitingForEpicConfirmation(mp) ? "#EB5757" : "#a2a0a0",
                    }}
                  >
                    {f.utcToLocal(mp.Last_Updated_Date + " " + mp.Last_Updated_Time + " UTC")}
                  </div>
                )}
              </TableCell>
              <TableCell className="tableCellStyle" align="left">
                <div className="icon-text" style = {{alignItems: "flex-start"}}>
                  {mp.Status === "Processed" ? (
                    <CheckIcon style={{ color: "#828282", fontSize: "1em" }} />
                  ) : mp.Status === c.status.intervention_rqd ? (
                    <VisibilityIcon
                      style={{ color: "#f58018", fontSize: "1em" }}
                    />
                  ) : mp.Status === c.status.queued ? (
                    <HistoryOutlinedIcon
                      style={{ color: "#828282", fontSize: "1em" }}
                    />
                  ) : mp.Status === c.status.in_progress ? (
                    <AutorenewOutlinedIcon
                      style={{ color: "#828282", fontSize: "1em" }}
                    />
                  ) : mp.Status ===  c.status.completed ? (
                    <DoneAllIcon
                      style={{ color: "#828282", fontSize: "1em" }}
                    />
                  ) : mp.Status === c.status.failed ? (
                    <ErrorIcon style={{ color: "#EB5757", fontSize: "1em" }} />
                  ) : mp.Status === c.status.uploading ? (
                    <CloudUploadIcon style={{ color: "#828282", fontSize: "1em" }} />
                  ): mp.Status === c.status.uploaded ? (
                    <ComputerIcon style={{ color: "#828282", fontSize: "1em" }} />
                  ): mp.Status === c.status.completing ? (
                    <TaskAltIcon style={{ color: "#828282", fontSize: "1em" }} />
                  ): null}
                  <div>
                  <p
                    className="doc-name-focus"
                    style={{
                      color:
                        mp.Status === c.status.failed
                          ? "#EB5757"
                          : mp.Status === c.status.intervention_rqd
                          ? "#F2994A"
                          : "#828282",
                      marginLeft: "3px",
                      fontSize: "0.8em",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => this.props.goToIntervention(mp.ID)}
                  >
                    {mp.Status}
                  </p>
                  {mp.Status === c.status.failed && 
                  <div  style={{
                      color: "#a2a0a0",
                      marginLeft: "3px",
                      fontSize: "0.8em"
                    }}>
                  {mp.Failure_Message}
                </div>}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    );
  }
}

export default TableData;
