import React,{useState} from "react";
import { Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import * as msg from "../../../utils/constants/messages";
import * as c from "../../../utils/constants/constants";
import { useDispatch } from "react-redux";
import { PATIENT_CREATE_ACTIONS_CONSTANTS } from "../../../redux/patientCreate/actions/actionTypes";
import * as dayjs from "dayjs";

/*this component will contain all the buttons required on doc details screen below the divider line*/
const ButtonHandler = (props) => {
    const dispatch = useDispatch();
    const [isUpload, setisUpload] = useState(false); //handles confirmation dialog for uplaod button
    const [validateUpload, setvalidateUpload] = useState(false); //handles mandatory field dialog for upload button
    const [isComplete, setisComplete] = useState(false); //handles confirmation dialog for complete button
    const [isSkipCmp, setisSkipCmp] = useState({flag:false, type: ''}); //handles confirmation dialog for 'mark as complete' button

    /*handles prompt for upload button. calls API when clicked OK, cancel operation when clicked cancel*/
    const onUploadDialog = (action) => {
        if(action === "ok")
            props.uploadToOnbase();
        setisUpload(false);
    }
    /*checks mandatory fields for upload action based on doc level*/
    const checkFieldsForUpload = (action) => {
        if(action === "close"){
            setvalidateUpload(false);
        }
        else{
        if(props.all_state.data.Entity_Details.classify.documentLevel === "Patient"){
            if(!props.all_state.data.Entity_Details.doc_description)
                setvalidateUpload(true);
            else
                setisUpload(true);
        }else if(props.all_state.data.Entity_Details.classify.documentLevel === "Encounter"){
            if(!props.all_state.data.Entity_Details.doc_description || !props.all_state.data.Entity_Details.ehrDetails || Object.keys(props.all_state.data.Entity_Details.ehrDetails.encounter).length === 0)
                setvalidateUpload(true);
            else
                setisUpload(true);
        }else if(props.all_state.data.Entity_Details.classify.documentLevel === "Order"){
            if(!props.all_state.data.Entity_Details.doc_description || !props.all_state.data.Entity_Details.ehrDetails || Object.keys(props.all_state.data.Entity_Details.ehrDetails.order).length === 0)
                setvalidateUpload(true);
            else 
                setisUpload(true);
        }
    }
  }
/*handles prompt for complete button. calls API when clicked OK, cancel operation when clicked cancel*/
  const onCompleteDialog = (action) => {
    if(action === "ok")
      props.completeDocument();
    setisComplete(false);
  }
  /*handles prompt for 'skip upload & complete' button. calls API when clicked OK, cancel operation when clicked cancel*/
  const onSkipCmpDialog = (action) => {
    if(action === "ok")
      props.skipUpload();
   // setisSkipCmp(false);
    setisSkipCmp(prevState => {
      return { ...prevState, flag: false }
    });
  }
  const handleSkipCmp = (flag, type) => {
    setisSkipCmp(prevState => {
      return { ...prevState, flag: flag, type: type }
    });
  }

  const enableMarkAsCompleteButton = () => {
    var { Entity_Details = {}} = props.all_state.data || {};
    const { patient = {}, encounter = {}, order = {} } = Entity_Details.ehrDetails || {};
    const doc_level = Entity_Details.classify.documentLevel;

    const data = doc_level == "Order" ? order : (doc_level == "Encounter" ? encounter : patient)
 
    if((props.all_state.data.Status !== c.status.failed && props.all_state.data.Status !== c.status.uploaded) || props.all_state.data.Error_Code === 5001 ) return true;

    if(doc_level == "Patient" && Object.keys(data).length) {
      if(props.all_state.data.Status == c.status.failed && patient.onbase_confirmation == "FAIL") return true;
      else if (props.all_state.data.Status == c.status.failed && patient.onbase_confirmation == "SUCCESS" && Entity_Details.is_epic_confirmation_received) return true;
    } else if (( doc_level == "Order" || doc_level == "Encounter" ) && Object.keys(data).length) {

      if(props.all_state.data.Status == c.status.failed && props.all_state.data?.Entity_Details?.enable_upload_button)  return true;

      let flag = Object.keys(data).every(m => data[m].onbase_confirmation == "FAIL");
      if(flag) return flag;

      flag = Object.keys(data).some(m => props.all_state.data.Status == c.status.failed && data[m].onbase_confirmation == "SUCCESS" && Entity_Details.is_epic_confirmation_received )
      return flag;
    }

  }
    return (
        <>
        <div className="button-section" style={{visibility: props.all_state.isDuplicateView ? "hidden": "visible"}}>
            {props.all_state.isSave !== true ?
                (!props.all_state.data.MRN  && props.all_state.data.Status !== c.status.completed ?
                    <div style={{display: "flex", "justifyContent": "flex-end"}}>
                            {Object.keys(props.all_state.data.Entity_Details.extraction).includes('patientName') &&
                                Object.keys(props.all_state.data.Entity_Details.extraction).includes('dateOfBirth') &&
                                <div style={{ padding: "0px 10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <>
                                        <button
                                            className="apply-button"
                                            style={{ width: "120px", marginRight: "0px" }}
                                            type="submit"
                                            onClick={() =>
                                            // props.handlePatientCreation("open")
                                            {
                                                const { data = {} } = props.all_state;
                                                let formattedName = data.Entity_Details?.formattedPatientName || "";
                                                let dob = data.Entity_Details?.extraction?.dateOfBirth?.value || "";
                                                let patientInfo = {
                                                    given_name: formattedName.split(", ")[1],
                                                    family_name: formattedName.split(", ")[0],
                                                    birth_date: dayjs(dob).format("YYYY-MM-DD"),
                                                    mobile_number: data.Entity_Details?.extraction?.phone_number?.value,
                                                    address: data.Entity_Details?.extraction?.address?.value || "",
                                                    country: "US",
                                                }
                                                dispatch(props.update_patient_create_info({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.UPDATE_PATIENT_CREATE_DETAILS, payload: { ...patientInfo } }));
                                                dispatch(props.patient_create({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.TOGGLE_PATIENT_CREATE_POPUP, payload: true }))
                                                dispatch(props.maximise_patient_create({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.MAXIMISE_PATIENT_CREATE_POPUP, payload: true }))
                                            }
                                            }
                                        >
                                            <p className="apply-button-text">Create Patient</p>
                                        </button>
                                        <button
                                            className="apply-button"
                                            style={{ width: "120px" }}
                                            type="submit"
                                            onClick={() => !props.all_state.isDuplicateView && props.saveAllData("pm")}>
                                            <p className="apply-button-text">Match Patient</p>
                                        </button>
                                    </>
                                </div>
                            }
                    <button
                        className = "apply-button"
                        style = {{width: "170px", marginRight: "0px"}}
                        type = "submit"
                        onClick = {() => !props.all_state.isDuplicateView && handleSkipCmp(true,"no_pm")}>
                        <p className="apply-button-text">Mark as Complete</p>
                    </button>
                </div>
                : (props.all_state.data.MRN && props.all_state.data.Status !== c.status.completed && props.all_state.data.Status !== c.status.uploading &&
                    props.all_state.data.Status !== c.status.completing && 
                    <div>
                        {(props.all_state.data.Status !== c.status.uploaded && props.all_state.data.Error_Code !== 5002 && props.all_state.data.Error_Code !== 5003 && props.all_state.data.Error_Code !== 5004) || (props.all_state.data.Status == c.status.failed && props.all_state.data?.Entity_Details?.enable_upload_button) ?
                            <button
                                    className="apply-button"
                                    style={{ width: "120px"}}
                                    type="submit"
                                    onClick={() =>
                                      !props.all_state.isDuplicateView && checkFieldsForUpload()}
                                >  <p className="apply-button-text">Upload</p>
                            </button> : null
                             }
                            	{ props.all_state.data.Status === c.status.uploaded && props.all_state.data?.Entity_Details?.is_epic_confirmation_received ?
                                    <button
                                    className = "apply-button"
                                    style = {{width: "120px", marginRight: "0px"}}
                                    type = "submit"
                                    onClick = {() => 
                                      !props.all_state.isDuplicateView && setisComplete(true)}
                                    >
                                    <p className="apply-button-text">Complete</p>
                                    </button>
                                    : null }
                                { enableMarkAsCompleteButton() ? <button
                                    className = "apply-button"
                                    style = {{width: "170px", marginRight: "0px"}}
                                    type = "submit"
                                    onClick = {() => 
                                      !props.all_state.isDuplicateView && handleSkipCmp(true, "pm")}
                                    >
                                    <p className="apply-button-text">Mark as Complete</p>
                                    </button> : null
                                  }         
                            	
                        </div>
                      )
                )
                :
                (<div
                    className={
                        props.all_state.isSave
                            ? "save-button"
                            : "save-button-hide"

                    }
                >
                    <button
                        className="apply-button"
                        style={{ width: "120px" }}
                        type="submit"
                        onClick={() => {
                            //this.setState({ add: false, edit: false });
                            !props.all_state.isDuplicateView && props.saveAllData("save");
                        }}
                    >
                        <p className="apply-button-text">Save Changes</p>
                    </button>
                    <button
                        className="reset-button"
                        style={{ width: "140px" }}
                        type="submit"
                        onClick={() => {
                            //this.setState({ add: false, edit: false });
                            !props.all_state.isDuplicateView && props.discardChanges();
                        }}
                    >
                        <p className="reset-button-text">Discard Changes</p>
                    </button>
                </div>
                )}
        </div>
         {/*===========mandatory fields dialog for uplaod button=================*/}
         <Dialog open={validateUpload}>
         {/* <DialogTitle>
            <CloseIcon   style={{ color: "#aa1572", cursor: "pointer",float: "right" }} onClick = {() => checkFieldsForUpload("close")}/>
            </DialogTitle>*/}
          <DialogContent>
                <div className="block-save" >
                <InfoIcon style={{ color: "var(--hti-magenta)" }} />
                <br />
                <div style = {{textAlign: "left"}}>
                    <div>{msg.upload_mandatory}</div>
                    <ul>
                    <li>Document Description</li>
                    {props.all_state.data.Entity_Details.classify.documentLevel === "Encounter" &&
                    <li>Encounter Selection</li>}
                    {props.all_state.data.Entity_Details.classify.documentLevel === "Order" &&
                    <li>Order Selection</li>}
                    </ul>
                </div>
                <button
                    className="apply-button"
                    style={{ width: "70px" }}
                    onClick = {() => !props.all_state.isDuplicateView && checkFieldsForUpload("close")}
                >
                    <p className="apply-button-text" >OK</p>
                </button>
                </div>
         </DialogContent>
        </Dialog>
          {/*===========confirmation dialog for upload button=================*/}               
         <Dialog open={isUpload}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.doc_upload}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  !props.all_state.isDuplicateView && onUploadDialog("ok");
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
              <button
                className="reset-button"
                style={{ width: "70px" }}
                onClick={() => !props.all_state.isDuplicateView && onUploadDialog("cancel")}
              >
                <p className="reset-button-text" >Cancel</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
          {/*===========confirmation dialog for complete button=================*/}               
          <Dialog open={isComplete}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.doc_cmp_prompt}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  !props.all_state.isDuplicateView && onCompleteDialog("ok");
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
              <button
                className="reset-button"
                style={{ width: "70px" }}
                onClick={() => !props.all_state.isDuplicateView && onCompleteDialog("cancel")}
              >
                <p className="reset-button-text" >Cancel</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
          {/*===========confirmation dialog for 'mar as complete' button=================*/}               
          <Dialog open={isSkipCmp.flag}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{isSkipCmp.type === "pm" ? msg.mark_cmp_with_upload : msg.mark_cmp_without_upload}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  !props.all_state.isDuplicateView && onSkipCmpDialog("ok");
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
              <button
                className="reset-button"
                style={{ width: "70px" }}
                onClick={() => !props.all_state.isDuplicateView && onSkipCmpDialog("cancel")}
              >
                <p className="reset-button-text" >Cancel</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>

       
        </>
    )
}

export default ButtonHandler;