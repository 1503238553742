import React from "react";
//import logo from "./logo.svg";
import "./App.css";
import "./common.css";
import Entry from './container/Entry';

class App extends React.Component {
  render() {
  
    return (
      <div className="App">
       
        <Entry className="full-height" />
      
     </div>
    );
  }
}

export default App;
