import jwt_decode from "jwt-decode";
//import db from '../firebase.config';
//import * as c from '../utils/constants/constants';

/* converts UTC to local time */
export const utcToLocal = (d, format) => {
  var regex = new RegExp("-", "g");
  var date = new Date(d.replace(regex, "/"));
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var hh = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if(sec < 10){
    sec = "0" + sec;
  }
  if (min < 10) min = "0" + min;
  var day = hh >= "12" ? "PM" : hh < "12" || hh == "00" ? "AM" : "";
  if (hh > 12) {
    hh = hh - 12;
    if (hh < 10) hh = "0" + hh;
  } else if (hh < 10) {
    //  hh = '0'+hh;
    hh = hh == "00" ? "12" : "0" + hh;
  }
  if (format === "full")
    return (d = mm + "/" + dd + "/" + yyyy + ", " + hh + ":" + min + " " + day);
  else if(format === "full_sec")
    return (d = mm + "/" + dd + "/" + yyyy + ", " + hh + ":" + min + ":"+ sec +" " + day);
  else if(format === "date_only")
    return (d = mm + "/" + dd + "/" + yyyy);
  else if(format === "MM-DD-YYYY")
    return (d = mm + "-" + dd + "-" + yyyy);
  else if(format === "date_month")
    return (d = mm + "/" + dd);
  else return (d = mm + "/" + dd + ", " + hh + ":" + min + " " + day);
};
/*===========converts local date to UTC================*/
export const dateToUtc = (d,dur) => {
  if(dur === 'from')
    d = d + " 00:00:00";
  else
    d = d + " 23:59:59";
  var regex = new RegExp("-", "g");
  var date = new Date(d.replace(regex, "/"));
 return parseInt(date.getUTCMonth()+1)  +"-" + date.getUTCDate() +"-"+date.getUTCFullYear() + " "+date.getUTCHours() + ":" +date.getUTCMinutes() + ":" +date.getUTCSeconds() ;
}
/*===converts UTC to local for metrics chart==============*/
export const utcToLocal_chart = (d,dur) => {
  var regex = new RegExp("-", "g");
  var date = new Date(d.replace(regex, "/"));
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  //var yyyy = date.getFullYear();
  var hh = date.getHours();
  var min = date.getMinutes();
  //var sec = date.getSeconds();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (min < 10) min = "0" + min;
  var day = hh >= "12" ? "PM" : hh < "12" ? "AM" : "";
  if (hh > 12) {
    hh = hh - 12;
    if (hh < 10) hh = "0" + hh;
  } else if (hh < 10) {
    hh = "0" + hh;
  }
 if(dur === 'day')
    return mm + "/" + dd ;
  else
  return (d =
    mm + "/" + dd +  "," + (hh === "00" ? "12" : hh) + ":" + min + " " + day);
};
/*formats date */
export const formatDateOnly = (d) => {
  var dd = d.getDate();
  var mm = d.getMonth() + 1;
  var yyyy = d.getFullYear();
  return mm + "-" + dd + "-" + yyyy;
};
/*=========get date range for specified duration=========*/
export const weekDate = (duration) => {
  var days = "";

  if (duration === "1week") days = 7;
  else if (duration === "2weeks") days = 14;
  else if (duration === "4weeks") days = 28;
  else if (duration === "SinceYesterday") days = 1;
  else days = 0;
  var date = new Date();
  var to_date = formatDateOnly(date);
  var from = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  var from_date = formatDateOnly(from);
  return dateToUtc(from_date,'from') + "," + dateToUtc(to_date,'to');
};
/*================provides current date in mm/dd/yyyy ===========*/
export const currDate = () => {
  var d = new Date();
  var mm = d.getMonth() + 1;
  var dd = d.getDate();
  var yy = d.getFullYear();
  return mm + "/" + dd + "/" + yy;
}
/*==============provides current time ===============*/
export const currTime = () => {
  var d = new Date();
  return d.toLocaleTimeString();
}
/*===============time difference ==============*/
export const timeDifference = (d1) => {
    d1 = new Date(d1);
    var d2 = new Date();
    /*time difference in seconds*/
    var n = Math.abs(d2-d1) / 1000;
    /*seconds to day,hour,minute,seconds*/
    var day = parseInt( n / (24 * 3600));
    day = day ? day+ (day > 1 ? " days " : " day " ) : "";
    n = n % (24 * 3600);
    var hour = parseInt(n / 3600);
    hour = hour ? hour +" hr " : "";
    n %= 3600;
    var minutes = n / 60;
    minutes = minutes ? minutes.toFixed() + " min " : "";
    n %= 60;
    var seconds = n;
    seconds = seconds ? seconds.toFixed() + " sec " : "";
    var res =  day + hour + minutes + seconds;
    return res;
}
/*=======formats and converts date to local zone value - used for encounters and orders===*/
export const formatDateEHR = (d) => {
  var date = d.split("T")[0];
  date = date.split("-")[1] + "/" + date.split("-")[2] + "/" + date.split("-")[0];
  var time = d.split("T")[1];
  return utcToLocal(
    (date ? date : "00/00/0000")+
      " " +
      (time ? time : "18:30:00")+
      " UTC",
    "date_only"
  )
  //return (date? date: "") + (time ? ","+time: "") ;
}
/*========converts the given string to titleCase=====*/
export const titleCase = (str,ch) => {
  var splitStr =
    str.indexOf(ch) === -1
      ? str.toLowerCase().split(" ")
      : str.toLowerCase().split(ch);
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};
/*==============converts seconds to Minutes and seconds===========*/
export const secToMin = (sec) => {
  sec = Math.round(sec);
  var minutes =  sec / 60;
  var seconds =  sec % 60;
  return minutes.toString().split(".")[0] + "," + seconds;
}
/*=============decode token=======NOT USED=================
export const accessToken = (token) => {
  if (token) var decoded = jwt_decode(token);
  //console.log('decoded ',decoded);
  unixToUTC(decoded.iat, decoded.exp);
  return decoded;
};*/
/*============unix to UTC========NOT USED===================
export const unixToUTC = (start, end) => {
  console.log("unixTimeStamp ", end);
  var start_date = new Date(start * 1000);
  var start_d = start_date.toUTCString();
  var unix_date = new Date(end * 1000);
  var utcString = unix_date.toUTCString();
  var date_now = new Date();
  var utc_now = date_now.getUTCMinutes();
  if (end > Date.now()) console.log("res ", true);
  else console.log("res ", false);
  return utcString;
};*/
/*======check expiry of access/refresh token========================*/
//let key = "-----BEGIN CERTIFICATE-----MIIC/DCCAeSgAwIBAgIIP04+LrctbcwwDQYJKoZIhvcNAQEFBQAwIDEeMBwGA1UEAxMVMTA2MjU0MzgxOTEyMjkwNDc2NTMzMCAXDTIwMTIxODExMDQzN1oYDzk5OTkxMjMxMjM1OTU5WjAgMR4wHAYDVQQDExUxMDYyNTQzODE5MTIyOTA0NzY1MzMwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDAFJ8CYGjpLD8hC0Sy1kcnfUx5B4hu8rpWLCxs6XY9sCLL8IVIt3bxj2Iu/hteWGBRjuoDpSgCOtk+REaC9fcUsFbmdwEz6fIFw5ig542yhlDsenb6x9Mpe049EfV7TrgxFAPXbOsdQdLOX5tHTFLrhpVkgOZbrW99gs9+P6bllpUM9yVtnkcHWqnkfyRvwIJRBIRYhW1xchwHivVRtRdIAEAWLU0hjJQqkx0RDJWxB+qMoeVJZXIWHE9v8uAyfTrmEaSIdUR4PBcXSRmdmGdWHUX8uyOupzmIVbP9Pnyccgl/nSANDJJaV/URPyiGOuwdhR2wdRQ9Wd8MVs1lkwAnAgMBAAGjODA2MAwGA1UdEwEB/wQCMAAwDgYDVR0PAQH/BAQDAgeAMBYGA1UdJQEB/wQMMAoGCCsGAQUFBwMCMA0GCSqGSIb3DQEBBQUAA4IBAQA/gVqMZ4V+U/QwzYV9rp84gRSqOQOwsdwadAwtYWIQhbTZ87k4PS8dNRz0GClpG/rxb7HZYKRP6WC/3gpNcFqeowyQzndkHqiW2WZQn7V8j3LFCp8L0XahnRnUI2WP5x4C0Wt7wsFNoxoASHb/LTBuv/Y3K8bnK7B8XIgEVLpFKrBx55ICPt5vp/YqW9FZkaY7+HFMNvQQ8e8Fuu4w/5WExu1G36zz70J8EBqZfrgHHn+KuIm9IC7e2gLbi6uFJTeknzBqF1Tv2TDf6wbWOUy9OOvoFQHScztawaQz+YVnjwC6RVpTGIVu3t8tgzvPXpmDZhD6B9YRT/F9q4Ez7mqe-----END CERTIFICATE-----";
export const token_expiry = (token) => {
  var isValid = "";
  if (token) {
    /*jwt.verify(token, key, function(err, decoded) {
    if(err) {
     console.log('expired');
     isValid = false;
    }
    else{
      console.log('alive');
      isValid = true;
    }
  });*/
    const { exp } = jwt_decode(token);
    const expirationTime = exp * 1000 - 300000;
    if (Date.now() >= expirationTime) isValid = false;
    else isValid = true;
  }
  return isValid;
};
/*=================check user role by decoding access token============*/
export const getUserInfo = (token) => {
  //console.log('token ',token);
  if (token) {
    const { user } = jwt_decode(token);
    return user;
  }
};
/*==================check expiry of signed url for images===NOT USED============*/
export const url_expiry = (url) => {
  var isValid = "";
  var date = new Date();
  var local_min_in_utc = date.getUTCMinutes();

  var exp = url.split("&")[2].split("=")[1].split("T")[1];
  //var hh = exp.substr(0,2);
  var mm = exp.substr(2, 2);
  var ss = exp.substr(4, 2);
  //var utc =  hh + ':' + mm + ':' + ss + ' UTC';
  console.log("utc min", mm, ss);
  console.log("local min ", local_min_in_utc);

  if (local_min_in_utc >= mm) isValid = false;
  else isValid = true;
  console.log(isValid);
  return isValid;
};

export const getTimeZone = () => {
  let offset = new Date().getTimezoneOffset();
  let o = Math.abs(offset);
  return "-07:00";
  // return ((offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2));
}

