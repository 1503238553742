import { GET_MANUAL_REVIEW_DATA_ACTIONS } from './actionTypes';

export const getManualReviewDataActions = {};
/*========================manaul review screen data=====================*/
getManualReviewDataActions.getManualReviewDataError = ((err) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_MANUAL_REVIEW_DATA_ERROR,
        hasError: err
    }
});

getManualReviewDataActions.getManualReviewDataLoading = ((bool) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_MANUAL_REVIEW_DATA_LOADING,
        isLoading: bool
    }
});

getManualReviewDataActions.getManualReviewDataSuccess = ((data) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_MANUAL_REVIEW_DATA_SUCCESS,
        data : data
    }
});

/*============================doc image signed URL==============================*/
getManualReviewDataActions.getImageUrlError = ((err) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_IMAGE_URL_ERROR,
        hasError: err
    }
});

getManualReviewDataActions.getImageUrlLoading = ((bool) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_IMAGE_URL_LOADING,
        isLoading: bool
    }
});

getManualReviewDataActions.getImageUrlSuccess = ((image_url) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_IMAGE_URL_SUCCESS,
        image_url : image_url
    }
});
/*===========================all categories===============================*/
getManualReviewDataActions.getCategoriesError = ((err) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORIES_ERROR,
        hasError: err
    }
});

getManualReviewDataActions.getCategoriesLoading = ((bool) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORIES_LOADING,
        isLoading: bool
    }
});

getManualReviewDataActions.getCategoriesSuccess = ((category_list) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORIES_SUCCESS,
        category_list : category_list
    }
});
/*===========================category mapping details===============================*/
getManualReviewDataActions.getCategoriesMapError = ((err) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORY_MAPPING_ERROR,
        hasError: err
    }
});

getManualReviewDataActions.getCategoriesMapLoading = ((bool) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORY_MAPPING_LOADING,
        isLoading: bool
    }
});

getManualReviewDataActions.getCategoriesMapSuccess = ((category_map) => {
    return {
        type: GET_MANUAL_REVIEW_DATA_ACTIONS.GET_CATEGORY_MAPPING_SUCCESS,
        category_map : category_map
    }
});