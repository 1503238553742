import React, { useEffect, useState } from "react";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import DocManagement from "../pages/DocManagement/DocManagement";
import UserManagement from "../pages/UserManagement/UserManagement";
import ManualReview from "../pages/ManualReview/ManualReview";
import AuditLog from "../pages/AuditLog/AuditLog";
import AdminConfig from '../pages/AdminConfig/AdminConfig';
import PdfView from "../Experiments/PdfView/Pdfview";
import SuperAdmin from "../pages/SuperAdmin/SuperAdmin";
import UserProfile from "../pages/UserProfile/UserProfile";
import SSORedirect from "../pages/SSORedirect/SSORedirect";
import SSOLanding from "../pages/SSOLanding/SSOLanding";
import CheckIdleTime from "../container/CheckIdleTime";
import InfoScreen from "../pages/InfoScreen/InfoScreen";
import Reports from "../pages/NewReports/Reports";
import DocInfo from "../pages/SuperAdmin/DocInfo/DocInfo";
import DocStuck from "../pages/SuperAdmin/DocStuck/DocStuck";
import DocStuckMH from "../pages/SuperAdminMH/DocStuckMH/DocStuckMH";

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import * as c from "../utils/constants/constants";
import PatientCreate from "../pages/PatientCreate/PatientCreate";

const patientPopupVisiblePages = [c.PAGE_URLS[c.PAGE_DASHBOARD], c.PAGE_URLS[c.PAGE_DOC_MGMT], 
c.PAGE_URLS[c.PAGE_USER_MGMT], c.PAGE_URLS[c.PAGE_MANUAL_REVIEW], 
c.PAGE_URLS[c.PAGE_AUDIT_LOG], c.PAGE_URLS[c.PAGE_USER_PROFILE], 
c.PAGE_URLS[c.PAGE_REPORTS]]

const Entry = (props) => {

  const location = useLocation(null);
  const [currentPage, updateCurrentPage] = useState("");
  const [currentLocation, updateCurrentLocation] = useState("")

  useEffect(() => {
    updateCurrentLocation(window.location?.pathname);
  }, [location])
  
  const handlePageChange = (pageName) => {
    updateCurrentPage(pageName)
    
  };
    
    return (
      
        <div className="page-main-container">
          {patientPopupVisiblePages.includes(currentLocation) ? <PatientCreate /> : null}
          <Switch>
          <Route
               exact
               path={c.PAGE_URLS[c.PAGE_LOGIN]}
               render={(props) => (
                 c.SSO === 'true' ?
                 <SSOLanding {...props}  handlePageChange={handlePageChange} />
                 :
                 <Login
                 {...props}  handlePageChange={handlePageChange}
               />
               )}
               />

             <Route
              exact
              path={c.PAGE_URLS[c.PAGE_INFO]}
              render={(props) => (
                <InfoScreen {...props} handlePageChange={handlePageChange} />
              )}
            />
            {/* <Route
              exact
              path={c.PAGE_URLS[c.PAGE_LOGIN]}
              render={(props) => (
                <SSOLanding {...props}  handlePageChange={handlePageChange} />
              )}
              />*/}
             <Route
              exact
              path={c.PAGE_URLS[c.SSO_REDIRECT]}
              render={(props) => (
                <SSORedirect{...props}   handlePageChange={handlePageChange} />
              )}
            />
            
             
             {localStorage.getItem(c.ACCESS_TOKEN) ? 
              <React.Fragment>
              <Route
                exact
                path={c.PAGE_URLS[c.PAGE_DASHBOARD] }
                render={(props) => {
                  return (
                  <Dashboard
                    {...props}
                    handlePageChange={handlePageChange}
                  />);
                }}
              />
           
              <Route
                exact
                path={c.PAGE_URLS[c.PAGE_DOC_MGMT]}
                render={(props) => (
                  <DocManagement
                    {...props}
                    handlePageChange={handlePageChange}
                  />
                )}
              />
             
              <Route
                exact
                path={c.PAGE_URLS[c.PAGE_USER_PROFILE]}
                render={(props) => (
                  <UserProfile
                    {...props}
                    handlePageChange={handlePageChange}
                    //modal={state.modal}
                  />
                )}
              />

           

              <Route
                exact
                path={c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}
                render={(props) => (
                  <ManualReview
                    {...props}
                    handlePageChange={handlePageChange}
                    //modal={state.modal}
                  />
                )}
              />
              <Route
              exact
              path={c.PAGE_URLS[c.ADMIN_CONFIG]}
              render={(props) => (
                <AdminConfig {...props} handlePageChange={handlePageChange} />
              )}
            />
             <Route
              exact
              path={c.PAGE_URLS[c.PDF_VIEW]}
              render={(props) => (
                <PdfView {...props} handlePageChange={handlePageChange} />
              )}
            />
            
              {localStorage.getItem(c.USER_ROLE) && localStorage.getItem(c.USER_ROLE) === 'Admin' &&
          /* routes under this block is applicable only if user role is Admin*/
                <>
                 <Route
                exact
                path={c.PAGE_URLS[c.PAGE_USER_MGMT]}
                render={(props) => (
                  <UserManagement
                    {...props}
                    handlePageChange={handlePageChange}
                  />
                )}
              />
            

               <Route
                exact
                path={c.PAGE_URLS[c.PAGE_AUDIT_LOG]}
                render={(props) => (
                  <AuditLog
                    {...props}
                    handlePageChange={handlePageChange}
                    //modal={state.modal}
                  />
                )}
              />
              <Route
                exact
                path={c.PAGE_URLS[c.PAGE_REPORTS]}
                render={(props) => (
                  <Reports
                    {...props}
                    handlePageChange={handlePageChange}
                  />
                )}
              />
            <Route
              exact
              path={c.PAGE_URLS[c.SUPER_ADMIN]}
              render={(props) => (
                <SuperAdmin {...props} handlePageChange={handlePageChange} />
              )}
            />
             <Route
              exact
              path={c.PAGE_URLS[c.PAGE_DOC_INFO]}
              render={(props) => (
                <DocInfo {...props} handlePageChange={handlePageChange} />
              )}
            />
             <Route
              exact
              path={c.PAGE_URLS[c.PAGE_DOC_STUCK]}
              render={(props) => (
                <DocStuck {...props} handlePageChange={handlePageChange} />
              )}
            />
             <Route
              exact
              path={c.PAGE_URLS[c.PAGE_DOC_STUCK_MH]}
              render={(props) => (
                <DocStuckMH {...props} handlePageChange={handlePageChange} />
              )}
            />
              </>
                }
                 {localStorage.getItem(c.SETTINGS) && <CheckIdleTime />}
            </React.Fragment> 
           
            :
            (c.SSO === 'true' ?
            <Redirect to={c.PAGE_URLS[c.SSO_REDIRECT]}
            render={(props) => (
              <SSORedirect {...props}   handlePageChange={handlePageChange} />
            )}
            />
            :
            <Redirect to={c.PAGE_URLS[c.PAGE_LOGIN]}
            render={(props) => (
              <Login {...props}   handlePageChange={handlePageChange} />
            )}
            />
            )
          
              }
             
          </Switch>
        </div>
    );
  }

export default Entry;
