import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@material-ui/core";

import LaunchIcon from "@material-ui/icons/Launch";
import CloseIcon from "@material-ui/icons/Close";
import "./FileUpload.css";
import api from "../../../utils/API/api";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { forceLogout } from "../../../commonFunctions/forceLogout";
import * as msg from "../../../utils/constants/messages";
import * as c from "../../../utils/constants/constants";

class FileUpload extends React.Component {
  state = {
    files: "",
    allFiles: [],
    open: false,
    upload_status: "",
    upload: false,
    location: "",
    loc_id: "",
    msg: "",
  };
  uploadFile = () => {
    if (this.state.allFiles == 0) {
      this.setState({
        msg: "Please add one or more files to upload",
        upload: false,
      });
    } else if (this.state.allFiles !== [] && !this.state.loc_id) {
      this.setState({ msg: "Please select a location", upload: false });
    } else {
      this.setState({ msg: "", upload: false });
      var comp =c.UI_COMP.document.toString();
      var action = c.UI_ACTION.Document_uploaded;
      var bodyFormData = new FormData();
      for (var i = 0; i < this.state.allFiles.length; i++) {
        bodyFormData.append("file[]", this.state.allFiles[i]);
      }
      bodyFormData.append("storage_location_id", this.state.loc_id);

      api
        .upload_file(bodyFormData, comp, action)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ upload_status: true });
            this.timer = setTimeout(() => {
              this.setState({
                open: false,
                upload_status: "",
                msg: "",
                location: "",
                loc_id: "",
              });
            }, 5000);
          }
        })
        .catch((error) => {
          // console.log('err ',error,error.response);
          this.setState({ upload_status: false });
          if (error.response.status === 401) {
           forceLogout();
          }else{
            window.alert(msg.api_error);
          }
        });
    }
  };
  clearFiles = () => {
    this.setState({ allFiles: [] });
  };
  removeFile = (i) => {
    this.state.allFiles.splice(i, 1);
    this.setState({ allFiles: this.state.allFiles });
  };
  handleFileUpload = (event) => {
    for (var i = 0; i < event.target.files.length; i++) {
      this.state.allFiles.push(event.target.files[i]);
    }
    this.setState({ allFiles: this.state.allFiles, upload: false, msg: "" });
  };
  handleChange = (event) => {
    this.props.user_locs &&
      this.props.user_locs.map(
        (loc, i) =>
          loc.is_auth &&
          loc.name === event.target.value &&
          this.setState({ loc_id: loc.id })
      );
    this.setState({
      [event.target.name]: event.target.value,
      upload: false,
      msg: "",
    });
  };
  render() {
    return (
      <div>
        <div className="text-button-upload">
          <CloudUploadIcon className="icon-action-edit" />
          <p
            style={{ margin: "0px 0px 0px 3px" }}
            onClick={() => {
              this.setState({ open: true, allFiles: [], upload: false });
            }}
          >
            Upload Document
          </p>
        </div>
        <Dialog open={this.state.open}>
          <DialogTitle className="title-bg">
            <div className="upload-title">
              <p style={{ margin: "0px", color: "white" }}>
                {" "}
                Select files to Upload
              </p>
              <CloseIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    open: false,
                    upload_status: "",
                    location: "",
                    msg: "",
                    loc_id: "",
                  });
                }}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="box-size">
              {/*===================drag and drop============
              <div >
              <label htmlFor="uploadFile">
                <input
                 style={{ display: "none" }}
                 className="drop-file-area"
                  type="file"
                
                  title=" "
                  multiple
                  id="uploadFile"
                onChange={(event) => this.handleFileUpload(event)}
                  onDragOver={(event) =>
                    event.target.parentNode.classList.add("fileDragOver")
                  }
                  onDragLeave={(event) =>
                    event.target.parentNode.classList.remove("fileDragOver")
                  }
                  onDrop={(event) =>
                    event.target.parentNode.classList.remove("fileDragOver")
                  }
                />
               <div>
               <p>Drag and drop files here ...</p>
               </div>
                </label>
                </div>*/}
              <FormControl
                style={{ width: "30%" }}
                disabled={this.state.upload && this.state.msg === ""}
              >
                <InputLabel htmlFor="age-native-simple">
                  Select location
                </InputLabel>
                <Select
                  native
                  value={this.state.location}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "location",
                    id: "age-native-simple",
                  }}
                >
                  <option value="" disabled />
                  {this.props.user_locs &&
                    this.props.user_locs.map(
                      (loc, i) =>
                        loc.is_auth && (
                          <option key={i} value={loc.name}>
                            {loc.name}
                          </option>
                        )
                    )}
                </Select>
              </FormControl>

              <div className="manual-select">
                <label htmlFor="file-names">
                  <input
                    style={{ display: "none" }}
                    id="file-names"
                    type="file"
                    multiple
                    onChange={(event) =>
                      this.state.upload && this.state.msg === ""
                        ? event.preventDefault()
                        : this.handleFileUpload(event)
                    }
                     onClick={(event)=> { 
                        event.target.value = null
                    }}
                  />
                  <div
                    onClick={(event) =>
                      this.state.upload && this.state.msg === ""
                        ? event.preventDefault()
                        : null
                    }
                    className={
                      this.state.upload && this.state.msg === ""
                        ? "add-file-disable"
                        : "title-icon"
                    }
                  >
                    <p className="title-text">Add File</p>{" "}
                    <LaunchIcon
                      fontSize="small"
                      style={{ paddingLeft: "5px", color: "grey" }}
                    />
                  </div>
                </label>
                <div>
                  <p>
                    {this.state.allFiles && this.state.allFiles.length}
                    <span>
                      {this.state.allFiles && this.state.allFiles.length === 1
                        ? " file selected"
                        : " files selected"}
                    </span>
                  </p>
                </div>
              </div>

              <div
                className={
                  this.state.allFiles.length > 6
                    ? "view-files-alter"
                    : this.state.allFiles.length > 0
                    ? "view-files"
                    : "view-files-hide"
                }
              >
                <div className="files-box">
                  {this.state.allFiles &&
                    this.state.allFiles.map((data, i) => (
                      <div className="icon-text">
                        {this.state.upload !== true && (
                          <CloseIcon
                            className="icon-action"
                            //fontSize="medium"
                            color="secondary"
                            onClick={() => this.removeFile(i)}
                          />
                        )}
                        <p key={i} className="file-name">
                          {data.name}
                          <span style={{ color: "#828282", fontSize: "0.9em" }}>
                            {"  (" + (data.size / 1024).toFixed(0) + "kb)"}
                          </span>
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              {this.state.msg && <p>{this.state.msg}</p>}
              <Divider />

              {this.state.upload === true && this.state.msg === "" ? (
                this.state.upload_status === true ? (
                  <div>
                    <div className="div-center">
                      <CheckCircleIcon style={{ color: "#27AE60" }} />
                      <p>Uploaded successfully.</p>
                    </div>
                    <button
                      style={{ marginLeft: "41%" }}
                      className="apply-button"
                      onClick={() => {
                        this.setState({
                          open: false,
                          upload_status: "",
                          location: "",
                          msg: "",
                          loc_id: "",
                        });
                        clearTimeout(this.timer);
                        this.timer = null;
                      }}
                    >
                      <p className="apply-button-text">Done</p>
                    </button>
                  </div>
                ) : this.state.upload_status === false ? (
                  <div className="div-center">
                    <ErrorIcon style={{ color: "#EB5757" }} />
                    <p>Upload failed.Please try again.</p>
                  </div>
                ) : (
                  <div className="div-center">
                    <CircularProgress size={15} className="loader-space" />
                    {this.state.location ? (
                      <p>
                        Uploading files to the Location{" "}
                        <span style={{ fontWeight: "500" }}>
                          {this.state.location}
                        </span>
                      </p>
                    ) : (
                      !this.state.msg && <p>Uploading...</p>
                    )}
                  </div>
                )
              ) : (
                <div className="div-center">
                  <button
                    className="apply-button"
                    type="submit"
                    onClick={() => {
                      this.uploadFile();
                      this.setState({ upload: true });
                    }}
                  >
                    <p className="apply-button-text">Upload</p>
                  </button>
                  <button
                    className="reset-button"
                    type="submit"
                    //   onClick = {() => this.clearFiles()}
                    onClick={() => {
                      this.setState({
                        open: false,
                        upload_status: "",
                        location: "",
                        msg: "",
                        loc_id: "",
                      });
                    }}
                  >
                    <p className="reset-button-text">Cancel</p>
                  </button>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default FileUpload;
