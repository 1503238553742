/*Common */
export const server_error = "Server error. Please try again";
export const no_data = "No data to display.";
/* Doc details screen */
export const file_lock_msg = "This document is being edited by another user or users.";
export const stat_tooltip = "Looks like you have made some changes to the document. Please save or discard them.";
export const api_error = "Error occured. Request failed.";
export const rate_limiter = "Too many requests. Please try after sometime.";
export const ext_info = "Information will be shown here once the classification and extraction is completed.";
export const data_save = "Data saved successfully.";
export const data_discard = "Your changes will be discarded.";
export const upload_mandatory = "Following fields are mandatory for this document to be uploaded.";
export const block_edits = " Any edits to the document is restricted!";
export const doc_upload = "This document will be uploaded to EHR";
export const doc_uploading = "This document is being uploaded to EHR." + block_edits;
export const doc_uploaded = "This document has been uploaded to EHR." + block_edits;
export const doc_move = "This document is being moved to completed status." + block_edits;
export const doc_cmp = "This document has been completed." + block_edits;
export const file_duplication_prompt = "This warning will no more appear on this document";
export const doc_cmp_prompt = "This document will be moved and will not be available in G drive.";
export const mark_cmp_with_upload = "This document will not be uploaded but marked as completed. Completed documents will not allow any edits.";
export const mark_cmp_without_upload = "This document does not have a patient match, Do you want to mark this as completed? Completed documents will not allow any edits.";
export const flag_notes_missing_message = "Flag notes was not filled. Do you want to proceed without flag notes.";
export const spam_validation_message = "Spam flag accept only valid fax numbers.";
export const spam_warning_message = "Selection of spam flag will disable all other flags.";
export const order_completion_status = "Please select order completion status."

/*Dashboard */
export const no_chart_data = "No data to display in the selected interval.";
