import React, { useState } from "react";
import "./ActiveData.css";
import { Divider } from "@mui/material";

const ActiveData = (props) => {
  const [show, setShow] = useState("category");
  const cat_line = ["rp-under-line", show === "category" ? "rp-under-line-show" : "rp-under-line-hide"];
  return (
    <>
    {/*======================SCREEN VIEW===================*/}
      <div className="rp-other-info">
        <div className="flex-style">
          <div style={{ width: "50%" }}>
            <div className="rp-text-active" onClick={() => setShow("category")}>
              Category
            </div>
           <div className={cat_line.join(' ')}></div>
          </div>
          <div style={{ width: "50%" }}>
            <div className="rp-text-active" onClick={() => setShow("location")}>
              Location
            </div>
            {show === "location" && <div className="rp-under-line"></div>}
          </div>
        </div>
        <Divider />
        <br />
        {show === "location" ? (
          <>
            <div>
              <div className="rp-flex-btwn">
                <div className="rp-title" style = {{padding : "0px"}}>Most Active</div>
                <div style={{color: "grey" }}>Doc Count</div>
              </div>
              {props.sorted_locs &&
                props.sorted_locs.map(
                  (loc, i) =>
                    i < 3 && (
                      <div className="rp-avg-page-row">
                        <div className="rp-left-text">{loc.Name}</div>
                        <div>{loc.Doc_Count.toLocaleString()}</div>
                      </div>
                    )
                )}
            </div>
            <br />
            <div>
              <div className="rp-flex-btwn">
                <div className="rp-title" style = {{padding : "0px"}}>Least Active</div>
                <div style={{color: "grey" }}>Doc Count</div>
              </div>
              {props.sorted_locs &&
                props.sorted_locs
                  .slice(0)
                  .reverse()
                  .map(
                    (loc, i) =>
                      i < 3 && (
                        <div className="rp-avg-page-row">
                          <div>{loc.Name}</div>
                          <div>{loc.Doc_Count.toLocaleString()}</div>
                        </div>
                      )
                  )}
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="rp-flex-btwn">
                <div className="rp-title" style = {{padding : "0px"}}>Most Active</div>
                <div style={{ color: "grey" }}>Doc Count</div>
              </div>
              {props.sorted_cats &&
                props.sorted_cats.map(
                  (ct, i) =>
                    i < 3 && (
                      <div className="rp-avg-page-row">
                        <div className="rp-left-text">{ct.Name}</div>
                        <div>{ct.Doc_Count.toLocaleString()}</div>
                      </div>
                    )
                )}
            </div>
            <br />
            <div>
              <div className="rp-flex-btwn">
                <div className="rp-title" style = {{padding : "0px"}}>Least Active</div>
                <div style={{color: "grey" }}>Doc Count</div>
              </div>
              {props.sorted_cats &&
                props.sorted_cats
                  .slice(0)
                  .reverse()
                  .map(
                    (ct, i) =>
                      i < 3 && (
                        <div className="rp-avg-page-row">
                          <div>{ct.Name}</div>
                          <div>{ct.Doc_Count.toLocaleString()}</div>
                        </div>
                      )
                  )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ActiveData;
