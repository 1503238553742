import axios from 'axios';
import { getDocsStatusActions, } from './actionCreators';
import {getToken} from "../../../commonFunctions/getToken";
//const auth_token = localStorage.getItem(c.ACCESS_TOKEN);
//console.log('token in actions.js  ',auth_token);
/*================================Doc status -flowchart===================================*/
export const getDocsStatus = ((url) => {
    return async(dispatch) => {
        dispatch(getDocsStatusActions.getDocsStatusLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getDocsStatusActions.getDocsStatusLoading(false));
                return response;
            })
            .then((response) => dispatch(getDocsStatusActions.getDocsStatusSuccess(response.data.data)))
            .catch((error) => dispatch(getDocsStatusActions.getDocsStatusError(error.response.status)));
    }
});
/*================================Recent docs===================================*/
export const getRecentDocs = ((url) => {
    return async(dispatch) => {
        dispatch(getDocsStatusActions.getRecentDocsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getDocsStatusActions.getRecentDocsLoading(false));
                return response;
            })
            .then((response) => {
               
                dispatch(getDocsStatusActions.getRecentDocsSuccess(response.data.data))
               
            })
            .catch((error) => dispatch(getDocsStatusActions.getRecentDocsError(error.response.status)));
    } 
});
/*================================Action items===================================*/
export const getActionItems = ((url) => {
    return async(dispatch) => {
        dispatch(getDocsStatusActions.getActionItemsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getDocsStatusActions.getActionItemsLoading(false));
                return response;
            })
            .then((response) => {
              
                dispatch(getDocsStatusActions.getActionItemsSuccess(response.data))
               
            })
            .catch((error) => dispatch(getDocsStatusActions.getActionItemsError(error.response.status)));
    } 
});
/*================================Logs===================================*/
export const getLogs = ((url) => {
    return async(dispatch) => {
        dispatch(getDocsStatusActions.getLogsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getDocsStatusActions.getLogsLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(getDocsStatusActions.getLogsSuccess(response.data))
                
            })
            .catch((error) => dispatch(getDocsStatusActions.getLogsError(error.response.status)));
    } 
});
/*================================Intelligence metrics===================================*/
export const getImetrics = ((url) => {
    return async (dispatch) => {
        dispatch(getDocsStatusActions.getImetricsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getDocsStatusActions.getImetricsLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(getDocsStatusActions.getImetricsSuccess(response.data.data))
                
            })
            .catch((error) => dispatch(getDocsStatusActions.getImetricsError(error.response.status)));
    } 
});
/*================================Performance metrics===================================*/
export const getPmetrics = ((url) => {
    return async (dispatch) => {
        dispatch(getDocsStatusActions.getPmetricsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getDocsStatusActions.getPmetricsLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(getDocsStatusActions.getPmetricsSuccess(response.data.data))
                
            })
            .catch((error) => dispatch(getDocsStatusActions.getPmetricsError(error.response.status)));
    } 
});