import axios from 'axios';
import { getManualReviewDataActions } from './actionCreators';
import {getToken} from "../../../commonFunctions/getToken";

export const getManualReviewData = ((url) => {
    return async(dispatch) => {
        dispatch(getManualReviewDataActions.getManualReviewDataLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getManualReviewDataActions.getManualReviewDataLoading(false));
                return response;
            })
            .then((response) => dispatch(getManualReviewDataActions.getManualReviewDataSuccess(response.data.data)))
            .catch((error) => dispatch(getManualReviewDataActions.getManualReviewDataError(error.response.status)));
               
        }
});
export const getImageUrl = ((url) => {
    return async(dispatch) => {
        dispatch(getManualReviewDataActions.getImageUrlLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getManualReviewDataActions.getImageUrlLoading(false));
                return response;
            })
            .then((response) => dispatch(getManualReviewDataActions.getImageUrlSuccess(response.data.data)))
            .catch((error) => dispatch(getManualReviewDataActions.getImageUrlError(error.response.status)));
    }
});

export const getCategories = ((url) => {
    return async(dispatch) => {
        dispatch(getManualReviewDataActions.getCategoriesLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getManualReviewDataActions.getCategoriesLoading(false));
                return response;
            })
            .then((response) => dispatch(getManualReviewDataActions.getCategoriesSuccess(response.data.data)))
            .catch((error) => dispatch(getManualReviewDataActions.getCategoriesError(error.response.status)));
    }
});

export const getCategoriesMap = ((url) => {
    return async(dispatch) => {
        dispatch(getManualReviewDataActions.getCategoriesMapLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(getManualReviewDataActions.getCategoriesMapLoading(false));
                return response;
            })
            .then((response) => dispatch(getManualReviewDataActions.getCategoriesMapSuccess(response.data.data)))
            .catch((error) => dispatch(getManualReviewDataActions.getCategoriesMapError(error.response.status)));
    }
});