import React, { Component } from "react";
import "./Filter.css";
import {
  Grid,
  TextField, Select, InputLabel, FormControl
} from "@mui/material";
import * as c from '../../../utils/constants/constants';

class Filter extends Component {
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    /*let error_msg = "";
    if(this.props.error_filter === 'none')
      error_msg = "Please select atleast one field.";
    else
      error_msg = 'Invalid selection.';*/
   
    return (
      <>
      
          <div style = {{width : '70%'}}>

            <Grid className = 'filter-fields'>
                <Grid style={{ paddingRight: "20px" }}>
                <TextField id="standard-basic" 
                variant="standard"
                name = 'user_name' 
                value = {this.props.audit_filter.user_name} 
                label="User name"
                onChange = {(event) => this.props.handleChange(event)} />
                </Grid>
               <Grid style={{ paddingRight: "20px" }}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Action
                </InputLabel>
                <Select
                  native
                  value={this.props.audit_filter.action}
                  variant="standard"
                  label="Action"
                  onChange={(event) => this.props.handleChange(event)}
                  inputProps={{
                    name: "action",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option value=""></option>
                  {Object.keys(c.UI_ACTION).map((action) => (
                    <option key={action} value={action}>
                      {action.replace(/(^|_)./g, str => " " + str.slice(-1).toUpperCase())}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>   
             <Grid style={{ paddingRight: "20px" }}>
                <form onChange={this.props.handleChange}>
                  <TextField
                    variant="standard"
                    style={{ width: "100%" }}
                    id="fromDate"
                    label="From"
                    type="date"
                    //defaultValue="2017-05-24"
                    value={this.props.audit_filter.fromDate}
                    name="fromDate"
                    //className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
              </Grid>
              <Grid style={{ paddingRight: "20px" }}>
                <form onChange={this.props.handleChange}>
                  <TextField
                    variant="standard"
                    style={{ width: "100%" }}
                    id="toDate"
                    label="To"
                    type="date"
                    //  defaultValue="2017-05-24"
                    value={this.props.audit_filter.toDate}
                    name="toDate"
                    //className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
                  </Grid>
                  {/*<Grid>
                    <input type = 'date' value = {this.props.fromDate} name = 'fromDate' onChange = {this.props.handleChange} />
                  </Grid>*/}
              <div className="buttons-filter">
                <button
                  className="apply-button"
                  type="submit"
                  onClick={() => this.props.handleSubmit()}
                >
                  <p className="apply-button-text">Apply</p>
                </button>
                <button
                  className="reset-button"
                  style = {{width : '120px'}}
                  type="submit"
                  onClick={() => {
                    this.props.cancelFilter();
                  }}
                >
                  <p className="reset-button-text"  >Reset all filters</p>
                </button>
              </div>
            </Grid>
          </div>
       
      </>
    );
  }
}

export default Filter;
