import React, { Component } from "react";
import { IconButton} from "@material-ui/core";
//import target1 from "../../../utils/images/target1.svg";
import * as c from "../../../utils/constants/constants";
import HistoryIcon from "@material-ui/icons/History";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import "./DocsStatusView.css";
//import CountUp from "react-countup";
import ComputerIcon from '@mui/icons-material/Computer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';

/* get the response from API call made in dashboard.js and display the data*/
class DocsStatusView extends Component {
  render() {
    return (
      <>
       {/* <div className="intelligence-metrics">
          <div style={{ paddingTop: "15px" }}>
            <p className="int-title title">Intelligence Metrics</p>
            <div className="metrics-align">
              <div className="flex-justify">
                {/*<div>
                  <div className="metrics-container flex-style">
                    <div>
                      <img
                        src={strike1}
                        alt="strike1"
                        className="icon-margin"
                      />
                    </div>
                    <div>
                      {this.props.int_metrics &&
                        this.props.int_metrics.map((data, i) => (
                          <p key={i} className= "number-style">
                            <CountUp
                              start={0}
                              end={data.average_time_in_sec}
                              style={{ fontSize: "1em" }}
                            />
                          </p>
                        ))}
                      <p className="mini-text">sec</p>
                    </div>
                  </div>
                  <div style={{ marginLeft: "2em" }}>
                    <p className="mini-text" style={{ paddingTop: "0.7em" }}>
                      Average
                    </p>
                    <p className="mini-text">Time to Process</p>
                  </div>
                        </div>

                <div>
                  <div className="flex-style">
                    <div>
                      <img
                        src={target1}
                        alt="target1"
                        className="icon-margin"
                      />
                    </div>
                    <div>
                      {this.props.int_metrics &&
                        this.props.int_metrics.map((data, i) => (
                          <p key={i} className="number-style">
                            <CountUp
                              start={0}
                              end={data.average_confidence}
                              style={{ fontSize: "1em" }}
                            />
                          </p>
                        ))}
                      <p className="mini-text">%</p>
                    </div>
                  </div>
                  <div style={{ marginLeft: "1.7em" }}>
                    <p className="mini-text">
                      Average
                    </p>
                    <p className="mini-text">Confidence Level</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
                        </div>*/}
        {/*==============================================================================*/}
        <div className="doc-status">
          <p className="title" style={{ paddingTop: "0.5em" }}>
            Document Status
          </p>
          {this.props.doc_status &&
            this.props.doc_status.map(
              (data, i) =>
                data.doc_type === "Documents Received" && (
                  <div>(Received {data.doc_count.toLocaleString()})</div>
                )
            )}
          <div className="custom-divider-chart"></div>
          {/*=================================flow chart===================================*/}

          <div className="chart-top">
            <div className="flex-justify-unique">
              <div className="flow-chart-part1">
                <p className="mini-text">{c.status.queued}</p>
                <div className="flex-justify">
                  <IconButton
                    style={{ backgroundColor: "#aa1572", zIndex: "1" }}
                    onClick={() =>
                      this.props.goToIntervention("mgmt", c.status.queued)
                    }
                  >
                    <HistoryIcon className="status-icon-action" />
                  </IconButton>
                </div>
                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.queued && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}
              </div>
              
            </div>
            <div className="flow-chart-part2">
            <div className="chart-bottom-status">
                <div className="bottom-status">
                  <IconButton
                    style={{ backgroundColor: "#711865", zIndex: "1" }}
                    onClick={() =>
                      this.props.goToIntervention("mgmt", c.status.in_progress )
                    }
                  >
                    <AutorenewIcon className="status-icon-action" />
                  </IconButton>

                  {this.props.doc_status &&
                    this.props.doc_status.map(
                      (data, i) =>
                        data.doc_type === c.status.in_progress && (
                          <p
                            key={i}
                            className={
                              data.doc_count.toString().length > 6
                                ? "mini-number-style"
                                : "number-style"
                            }
                          >
                            {data.doc_count.toLocaleString()}
                          </p>
                        )
                    )}

                  <p className="mini-text">{c.status.in_progress}</p>
                </div>
                <div className="bottom-status">
                <IconButton
                  style={{ backgroundColor: "#27AE60", zIndex: "1" }}
                  onClick={() =>
                    this.props.goToIntervention("mgmt", c.status.processed)
                  }
                >
                  <CheckIcon className="status-icon-action" />
                </IconButton>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.processed && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}
                <p className="mini-text">{c.status.processed}</p>
              </div>
                
              </div>
              
            </div>
            <div className="flow-chart-part3">
              {/* <div className="vertical-line" style = {{minHeight : '3.5vh'}}></div> */}
              <div className="curve-divider"></div>
              <div className="chart-bottom-status">
                <div className="bottom-status">
                  <IconButton
                    style={{ backgroundColor: "#F2994A", zIndex: "1" }}
                    onClick={() =>
                      this.props.goToIntervention(
                        "mgmt",
                        c.status.intervention_rqd
                      )
                    }
                  >
                    <VisibilityIcon className="status-icon-action" />
                  </IconButton>

                  {this.props.doc_status &&
                    this.props.doc_status.map(
                      (data, i) =>
                        data.doc_type === c.status.intervention_rqd && (
                          <p
                            key={i}
                            className={
                              data.doc_count.toString().length > 6
                                ? "mini-number-style"
                                : "number-style"
                            }
                          >
                            {data.doc_count.toLocaleString()}
                          </p>
                        )
                    )}

                  <p className="mini-text">{c.status.intervention_rqd}</p>
                </div>
                <div className="bottom-status">
                  <IconButton
                    style={{ backgroundColor: "#EB5757", zIndex: "1" }}
                    onClick={() =>
                      this.props.goToIntervention("mgmt", "Failed")
                    }
                  >
                    <ErrorOutlineIcon className="status-icon-action" />
                  </IconButton>

                  {this.props.doc_status &&
                    this.props.doc_status.map(
                      (data, i) =>
                        data.doc_type === "Failed" && (
                          <p
                            key={i}
                            className={
                              data.doc_count.toString().length > 6
                                ? "mini-number-style"
                                : "number-style"
                            }
                          >
                            {data.doc_count.toLocaleString()}
                          </p>
                        )
                    )}

                  <p className="mini-text">Failed</p>
                </div>

              </div>
              <div className="background-circle-connector"></div>
              <div className="chart-bottom-status">
                <div className="bottom-status left-circle">
                  <div className="bottom-status-background-circle left-circle">
                    <IconButton
                      style={{
                        backgroundColor: "#4F4F4F", zIndex: "1"
                      }}
                      onClick={() =>
                        this.props.goToIntervention("mgmt", c.status.uploading)
                      }
                    >
                      <CloudUploadIcon
                        className="status-icon-action"
                      />
                    </IconButton>
                  </div>

                  {this.props.doc_status &&
                    this.props.doc_status.map(
                      (data, i) =>
                        data.doc_type === c.status.uploading && (
                          <p
                            key={i}
                            className={
                              data.doc_count.toString().length > 6
                                ? "mini-number-style"
                                : "number-style"
                            }
                          >
                            {data.doc_count.toLocaleString()}
                          </p>
                        )
                    )}
                  <p className="mini-text">{c.status.uploading}</p>
                </div>
                <div className="bottom-status">
                  <div className="bottom-status-background-circle right-circle">
                    <IconButton
                      style={{
                        backgroundColor: "#9B51E0", zIndex: "1"
                      }}
                      onClick={() =>
                        this.props.goToIntervention("mgmt", c.status.uploaded)
                      }
                    >
                      <ComputerIcon
                        className="status-icon-action"
                      />
                    </IconButton>
                  </div>

                  {this.props.doc_status &&
                    this.props.doc_status.map(
                      (data, i) =>
                        data.doc_type === c.status.uploaded && (
                          <p
                            key={i}
                            className={
                              data.doc_count.toString().length > 6
                                ? "mini-number-style"
                                : "number-style"
                            }
                          >
                            {data.doc_count.toLocaleString()}
                          </p>
                        )
                    )}
                  <p className="mini-text">{c.status.uploaded}</p>
                </div>
              </div>
              <div className="background-circle-connector"></div>
              <div className="chart-bottom-status">
                <div className="bottom-status left-circle">
                  <div className="bottom-status-background-circle left-circle">
                    <IconButton
                      style={{
                        backgroundColor: "#b4abb2", zIndex: "1"
                      }}
                      onClick={() =>
                        this.props.goToIntervention("mgmt", c.status.completing)
                      }
                    >
                      <TaskAltIcon
                        className="status-icon-action"
                      />
                    </IconButton>
                  </div>

                  {this.props.doc_status &&
                    this.props.doc_status.map(
                      (data, i) =>
                        data.doc_type === c.status.completing && (
                          <p
                            key={i}
                            className={
                              data.doc_count.toString().length > 6
                                ? "mini-number-style"
                                : "number-style"
                            }
                          >
                            {data.doc_count.toLocaleString()}
                          </p>
                        )
                    )}
                  <p className="mini-text">{c.status.completing}</p>
                </div>
                <div className="bottom-status ">
                  <div className="bottom-status-background-circle right-circle">
                    <IconButton
                      style={{
                        backgroundColor: "#2D9CDB", zIndex: "1"
                      }}
                      onClick={() =>
                        this.props.goToIntervention("mgmt", c.status.completed)
                      }
                    >
                      <DoneAllIcon
                        className="status-icon-action"
                      />
                    </IconButton>
                  </div>

                  {this.props.doc_status &&
                    this.props.doc_status.map(
                      (data, i) =>
                        data.doc_type === c.status.completed && (
                          <p
                            key={i}
                            className={
                              data.doc_count.toString().length > 6
                                ? "mini-number-style"
                                : "number-style"
                            }
                          >
                            {data.doc_count.toLocaleString()}
                          </p>
                        )
                    )}
                  <p className="mini-text">{c.status.completed}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DocsStatusView;
