import { SA_ACTIONS } from "./actionTypes";

export const saActions = {};

saActions.getDocStuckLoading = ((bool) => {
    return {
        type: SA_ACTIONS.GET_DOC_STUCK_LOADING,
        isLoading: bool
    }
});

saActions.getDocStuckError = ((err) => {
    return {
        type: SA_ACTIONS.GET_DOC_STUCK_ERROR,
        hasError: err
    }
});

saActions.getDocStuckSuccess = ((docstuck) => {
    return {
        type: SA_ACTIONS.GET_DOC_STUCK_SUCCESS,
        docstuck: docstuck
    }
});


