import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {getDocStuck } from "../../../redux/superAdmin/actions/actions";
import api from "../../../utils/API/api";
import * as msg from "../../../utils/constants/messages";
import * as c from "../../../utils/constants/constants";
import { forceLogout } from "../../../commonFunctions/forceLogout";
import DocListTable from "./DocListTableMH/DocListTableMH";
import {
  Button,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import "./DocStuckMH.css";

/* This screen displays all documents stuck in-progress for more than 60 minutes. There is no  re-direction to this screen from anywhere.
users come to this screen by giving url/superAdminMH if authenticated.
this is for customer purpose and users with admin role can only access*/

const DocStuckMH = (props) => {
  const dispatch = useDispatch();
  const docs = useSelector((state) => state.docstuck)
  const [dur, setDur] = useState("60");

  useEffect(() => {
      fetchDocStuck(dur);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    if(docs.hasError){
      if(docs.hasError === 401)
       forceLogout();
      else
       window.alert(msg.api_error);
    }
  })
  const fetchDocStuck = (dur) => {
    let isnum = /^\d+$/.test(dur);
    if(!isnum){
      window.alert("Invalid input");
      setDur('');
    }
    else{
    var query = {
      in_progress_time: parseInt(dur),
    };
    dispatch(getDocStuck(c.ROOT_URL + "/getDocListInProgress?page_limit=0",query))
  }
  }
 
  const updateDocStatus = (doc_id,user_comment) => {
    var query = {
      Status: c.status.intervention_rqd,
      Comment : user_comment
    };
    api
    .change_doc_status(doc_id,query)
    .then((response) => {
      if (response.status === 200) {
        fetchDocStuck(dur);
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      } else {
        window.alert(msg.api_error);
      }
    });
  }
  const handleChange = (e) => {
    setDur(e.target.value);
  };
  return (
    <>
      <div style={{ margin: "20px" }}>
        <p className="page-title" style={{ textAlign: "left" }}>
          Documents stuck in progress for more than {dur} minutes
        </p>
        <br />
        <Divider />
        <br />
        {docs.isLoading ? (
          <CircularProgress style={{ color: "grey" }} />
        ) : (
          <>
            {/*<div className="title-sa-main" style = {{textAlign : "left",backgroundColor: "transparent"}}>Documents stuck in progress for last {this.state.dur} minutes</div>*/}

            <DocListTable 
            docs={docs}
            updateDocStatus = {updateDocStatus} 
            />
          </>
        )}
      </div>
    </>
  );
};

export default DocStuckMH;
