import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  FormControl,
  Select,
  TextField,
  Checkbox,
  Autocomplete
} from "@mui/material";
import DialogModel from "../../../../../../components/DialogModel/DialogModel";
import * as c from "../../../../../../utils/constants/constants";
import * as f from "../../../../../../commonFunctions/commonFunctions";
import "./Orders.css"

const Orders = (props) => {
  const [orderFilter, setorderFilter] = useState({ status: "", fromDate: '', toDate: "", category: "" });
  const [selectedOrder, setselectedOrder] = useState([]); //stores the selected orders
  const [selectedOrderIds, setSelectedOrderIds] = useState([]); //stores the selected order IDs (identifier). used to validate checkbox

  useEffect(() => {
    /*save data on load if orders is alreadty present for a document*/
    var order_ids = [];
    props.all_data.Entity_Details.ehrDetails && Object.keys(props.all_data.Entity_Details.ehrDetails.order).forEach((order) => {
      order_ids.push(order);
    }) 
    setSelectedOrderIds(order_ids);
    var selected_orders = [];
    props.all_data.Entity_Details.ehrDetails && Object.values(props.all_data.Entity_Details.ehrDetails.order).forEach((order) => {
      selected_orders.push(order);
    })
    setselectedOrder(selected_orders);
    //setselectedOrder(props.all_data.Entity_Details.ehrDetails ? props.all_data.Entity_Details.ehrDetails.order : []);
    if (JSON.parse(localStorage.getItem(c.ORDER_DATA))) {
      setorderFilter(JSON.parse(localStorage.getItem(c.ORDER_DATA)));
    } else {
      setorderFilter(prevState => {
        return { ...prevState, fromDate: setOrderDate().split("_")[0], toDate: setOrderDate().split("_")[1] }
      });
      props.getOrderCategoryList();
      props.patientOrders({ ...orderFilter, ...{ fromDate: setOrderDate().split("_")[0], toDate: setOrderDate().split("_")[1] } });
    }
  }, []);

  const handleDate = (date, e) => {
    if (date === "from") {
      setorderFilter(prevState => {
        return { ...prevState, fromDate: e.target.value }
      });
    }
    else
      setorderFilter(prevState => {
        return { ...prevState, toDate: e.target.value }
      });
  };

  const handleChange = (type, e) => {
    if (type) {
      setorderFilter(prevState => {
        return { ...prevState, [type]: e }
      });
    }
  };

  const setOrderDate = () => {
    let toDate = new Date();
    let fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 6);
    let from = `${fromDate.getFullYear()}-${(Number(fromDate.getMonth()) + 1) < 10 ? ("0" + (Number(fromDate.getMonth()) + 1)) : (Number(fromDate.getMonth()) + 1)}-${fromDate.getDate() < 10 ? ("0" + Number(fromDate.getDate())) : fromDate.getDate()}`;
    let to = `${toDate.getFullYear()}-${(Number(toDate.getMonth()) + 1) < 10 ? ("0" + (Number(toDate.getMonth()) + 1)) : (Number(toDate.getMonth()) + 1)}-${toDate.getDate() < 10 ? ("0" + Number(toDate.getDate())) : toDate.getDate()}`;
    return from + "_" + to;
  }
  const resetFilter = () => {
    setorderFilter(prevState => {
      return { ...prevState, status: "", fromDate: "", toDate: "", category: "" }
    });
    var order_filter = {
      status: "", fromDate: "", toDate: "", category: ""
    }
    props.patientOrders(order_filter);
  };
  const handleSubmit = () => {
    props.patientOrders(orderFilter);
  };
  const handleOrdSelection = (event, data) => {
    const new_selected_orders = [...selectedOrder];
    const new_selected_ids = [...selectedOrderIds];
    if (event.target.checked) {
      new_selected_ids.push(data.identifier);
      /*Taking only the keys that are required to be displayed in main screen*/
      var new_obj = {
        "identifier": data.identifier,
        "authoredOn": data.authoredOn,
        "code": data.code
      }
      new_selected_orders.push(new_obj);
    } else {
      new_selected_ids.splice(new_selected_ids.indexOf(data.identifier), 1);
      removeObjByKey(new_selected_orders, "identifier", data.identifier);
    }
    setselectedOrder(new_selected_orders);
    setSelectedOrderIds(new_selected_ids);
  }

  const handleCompletionStatus = (identifier, val) => {
    let orders = JSON.parse(JSON.stringify(selectedOrder || []));
    orders.forEach(m => {
      if (m.identifier == identifier) m.document_completion_status = val
    });
    setselectedOrder(orders);
  }

  const removeObjByKey = (arr, key, value) => {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(key)
        && arr[i][key] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }
  return (
    <>
      {props.tabLoader && props.value === 2 && (
        <CircularProgress size={30} className="popup-spinner" />
      )}

      <div className={props.tabLoader ? "block-area-common" : ""}>
        <div className="flex-style">
          <div>Orders for {props.all_data.Entity_Details.patientMatch.official_name}.</div>
          {props.doc_level === "Order" && selectedOrder.length > 0 && <div style={{ color: "#aa1572", marginLeft: "5px" }}> ID: {selectedOrderIds.join(", ")}</div>}
        </div>
        <br />
        {/*======Encounter filters=============*/}
        <div className="enc-filters orders-tab">
          <div className="enc-filter-fields">
            <div >
              <div>Status</div>
              <FormControl style={{ width: "120px", marginRight: "10px" }}>
                <Select
                  native
                  variant="standard"
                  value={orderFilter.status}
                  onChange={(e) => handleChange("status", e.target.value)}
                  inputProps={{
                    name: "status",
                    id: "age-native-simple",
                  }}
                >
                  <option value="" disabled />
                  {/* <option  value="Draft">Draft</option> */}
                  <option  value="Active">Active</option>
                  <option  value="Completed">Completed</option>
                  {/* <option  value="Revoked">Revoked</option> */}
                  <option  value="Unknown">Unknown</option>

                </Select>
              </FormControl>
            </div>
            <div >
              <div>Category</div>
              <Autocomplete
                options={props.orderCategoryList || []}
                name="category"
                value={orderFilter.category}
                onChange={(e, val) => handleChange("category", val)}
                sx={{ width: 200 }}
                filterOptions={(options, params) => {
                  return options.filter(m => m?.toLowerCase()?.includes(params.inputValue?.toLowerCase()))
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="" />
                )}
              />
            </div>
            <form onChange={(e) => handleDate("from", e)}>
              <div>From</div>
              <TextField
                variant="standard"
                style={{ width: "100%" }}
                id="fromDate"
                type="date"
                value={orderFilter.fromDate}
                name="fromDate"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <form onChange={(e) => handleDate("to", e)}>
              <div>To</div>
              <TextField
                variant="standard"
                style={{ width: "100%" }}
                id="toDate"
                type="date"
                value={orderFilter.toDate}
                name="toDate"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
          <div className="buttons-filter">
            <button className="apply-button" type="submit" onClick={handleSubmit}>
              <p className="apply-button-text">Apply</p>
            </button>
            <button
              className="reset-button"
              type="submit"
              onClick={resetFilter}
            >
              <p className="reset-button-text">Reset</p>
            </button>
          </div>
        </div>
        <br />
        {/*=========Order grid=============*/}
        <div className="enc-data-section" >
          <TableContainer component={Paper} className="enc-table">
            <Table stickyHeader aria-label="customized table">
              <TableHead className="thBorder">
                <TableRow>
                  <TableCell className="tableHeadStyle">Select</TableCell>
                  <TableCell className="tableHeadStyle">No.</TableCell>
                  <TableCell className="tableHeadStyle">Order Number</TableCell>
                  <TableCell className="tableHeadStyle">Date</TableCell>
                  <TableCell className="tableHeadStyle">Status</TableCell>
                  <TableCell className="tableHeadStyle">Intent</TableCell>
                  <TableCell className="tableHeadStyle">Category</TableCell>
                  <TableCell className="tableHeadStyle">Order Name</TableCell>
                  <TableCell className="tableHeadStyle">Encounter Type</TableCell>
                  <TableCell className="tableHeadStyle">Ordering Provider</TableCell>
                  <TableCell className="tableHeadStyle">Associated Diagnosis</TableCell>
                  <TableCell className="tableHeadStyle">Completion Status</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {props.orderData &&
                  props.orderData.map((data, i) => (
                    <TableRow
                      key={i}
                      className={props.doc_level === "Order" && selectedOrderIds.includes(data.identifier) ? "ehr-table-row-color" : "table-row"}
                    >
                      <TableCell className="tableCellStyle" align="left">
                        <Checkbox
                          size="small"
                          checked={props.doc_level === "Order" && selectedOrderIds.includes(data.identifier)}
                          onChange={(event) => handleOrdSelection(event, data)}
                          disabled={props.doc_level !== "Order"}
                        />
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {i + 1}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.identifier}
                      </TableCell>
                      <TableCell className="tableCellStyle" style={{ whiteSpace: "nowrap" }} align="left">
                        {data.authoredOn && f.formatDateEHR(data.authoredOn)}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {f.titleCase(data.status, "-")}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {f.titleCase(data.intent, "-")}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.category}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.code}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.encounter}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.requester}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.reasonCode.toString()}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        <FormControl style={{ width: "120px", marginRight: "10px" }}>
                          <Select
                            native
                            disabled={!selectedOrderIds.includes(data.identifier)}
                            variant="standard"
                            value={selectedOrder?.find(element => element.identifier === data.identifier)?.document_completion_status || data.document_completion_status || ""}
                            onChange={(e) => handleCompletionStatus(data.identifier, e.target.value)}
                            inputProps={{
                              name: "status",
                              id: "age-native-simple",
                            }}
                          >
                            <option value="" disabled />
                            <option value="P">Preliminary</option>
                            <option value="IP">In Progress</option>
                            <option value="F">Final</option>
                          </Select>
                        </FormControl>
                      </TableCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.orderData && props.orderData.length === 0 && <DialogModel />}
          </TableContainer>
        </div>
        {props.value === 2 && props.doc_level === "Order" && (
          <div className="div-center">
            <button
              className="apply-button"
              type="submit"
              onClick={() => props.saveEHRData(selectedOrder, "order")}
            >
              <p className="apply-button-text">Done</p>
            </button>
            <button
              className="reset-button"
              type="submit"
              onClick={() => props.fetchID("cancel")}
            >
              <p className="reset-button-text">Cancel</p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Orders;
