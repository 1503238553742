import React from "react";
import ReactApexCharts from "react-apexcharts";
import ErrorBoundary from "../../../../container/ErrorBoundary";
import './ApexChartNew.css';

class ApexChartNew extends React.Component {

  render() {
   
    return (
      <ErrorBoundary>
      <div className = 'apex-chart-style'>
        <ReactApexCharts
          options={this.props.options}
          series={this.props.series}
          //type= {this.props.series[0].data.length === 1 ? "bar" : "area"}
         type = "area"
          height= '265px'
        />
      </div>
      </ErrorBoundary>
      
    );
  }
}

export default ApexChartNew;
