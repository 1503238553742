import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
//import PDF from "../../utils/images/sample.pdf";
import pdf_jpg from "../../utils/images/pdf_jpg.jpeg";
import "./PdfView.css";
/*===========expiremented the highlight funcitonality on pdf. ignore=======*/

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//const url ="https://storage.googleapis.com/hti-dev1-pz/processing-files/Others__Lab%20Template%201-05__2021-01-0611%3A11%3A23075866.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=restapi%40docuroute.iam.gserviceaccount.com%2F20210106%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20210106T134034Z&X-Goog-Expires=300&X-Goog-SignedHeaders=host&X-Goog-Signature=1996ea28fc0046a07a12621adbebbe6144f7825162796148a463f9fe3b6a4ae26b59326617859a836152bc23af04b0bef65af9d7f10d03279efc8f77afe86c0210f476871e3a116a9d9ad50d9673d3f919a4ba60051c46e16dedbaa6070eab025045152168b4050908ab9dafc39e578f4bd1e97a7b85bea6fe526f0e976e30d275b884a3723a6dc492ffc10b8162e876cfe9cf052468b7b0452f397029d6be77037ecdf5da2881a5877c4f8062fb47802232481fb1d6717913284735cf515b6a5a9776a869c91fec10fa39eb3b30c51aaf4a9e00f5bf94be0064778d0f50684c49fd3fe19e156722494efe118b35bb4cbef175cfd8412c3fae872f28293af5d4";
class PdfView extends React.Component {
  componentDidMount = () => {
    var width = "";
    var height = "";
    var a1 = "";
    var a2 = "";
    var b1 = "";
    var c2 = "";
    var a_point = "";
    if (document.getElementById("docImage")) {
      width = document.getElementById("docImage").clientWidth;
      height = document.getElementById("docImage").clientHeight;
      //console.log("test image ", width, height);
      a_point = document.getElementById("highlight-a");
      a1 = width * 0.703;
      a2 = 1 * (width * 1.29) + width * 1.29 * 0.35;
      // a2 = height * 0.351;
      b1 = width * 0.81;

      c2 = 1 * (width * 1.29) + width * 1.29 * 0.366;
      //c2 = height * 0.363;
      a_point.style.top = a2 + "px";
      a_point.style.left = a1 + "px";
      a_point.style.width = b1 - a1 + "px";
      a_point.style.height = c2 - a2 + "px";
    }
    /*var canvas = document.getElementById('my-canvas');
    var img    = canvas.toDataURL("image/png");
   document.write('<img src="'+img+'"/>');
    document.getElementById('image').src = img;*/
  };
  render() {
    return (
      <>
        <div style={{ width: "47vw", height: "100vh" }}>
          <img
            id="docImage"
            style={{ width: "47vw" }}
            src={pdf_jpg}
            key={pdf_jpg}
            onContextMenu={(event) => this.findClick(event)}
            alt="doc image"
          /> 
           <div id="highlight-a" className="highlight-ocr"></div>
           
            {/* <Document file={PDF}>
                    <Page pageNumber={2} />
                </Document>     

       
        <img id = 'image' src = ''></img>
        <canvas id = 'my-canvas' className = 'canvas-class'></canvas> */}
        </div>
      </>
    );
  }
}

export default PdfView;
