import axios from 'axios';
import { docManagementActions } from './actionCreators';
import {getToken} from "../../../commonFunctions/getToken";
//import { getAllLocations_user } from '../../usermanagement/actions/actions';

export const getAllCategories = ((url) => {
    return async(dispatch) => {
        dispatch(docManagementActions.getCategoryCountLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(docManagementActions.getCategoryCountLoading(false));
                return response;
            })
            .then((response) => dispatch(docManagementActions.getCategoryCountSuccess(response.data.data)))
            .catch((error) => dispatch(docManagementActions.getCategoryCountError(error.response.status)));
    }
});

export const getDocsTableData = ((url) => {
    return async(dispatch) => {
        dispatch(docManagementActions.getDocsTableDataLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(docManagementActions.getDocsTableDataLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(docManagementActions.getDocsTableDataSuccess(response.data.data));
            })
            .catch((error) => dispatch(docManagementActions.getDocsTableDataError(error.response.status)));
    }
});

export const getPageLimit = ((url) => {
    return async (dispatch) => {
        dispatch(docManagementActions.getPageLimitLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(docManagementActions.getPageLimitLoading(false));
                return response;
            })
            .then((response) => dispatch(docManagementActions.getPageLimitSuccess(response.data.data)))
            .catch((error) => dispatch(docManagementActions.getPageLimitError(error.response.status)));
    }
});

export const getAllLocations = ((url) => {
    return async (dispatch) => {
        dispatch(docManagementActions.getAllLocationsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(docManagementActions.getAllLocationsLoading(false));
                return response;
            })
            .then((response) => dispatch(docManagementActions.getAllLocationsSuccess(response.data.data)))
            .catch((error) => dispatch(docManagementActions.getAllLocationsError(error.response.status)));
    }
});

export const getDocImageUrl = ((url) => {
    return async (dispatch) => {
        dispatch(docManagementActions.getDocImageUrlLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }

                dispatch(docManagementActions.getDocImageUrlLoading(false));
                return response;
            })
            .then((response) => dispatch(docManagementActions.getDocImageUrlSuccess(response.data.data)))
            .catch((error) => dispatch(docManagementActions.getDocImageUrlError(error.response.status)));
    }
});
/*not used*/
export const filterResults = ((url, query) => {
    
    return async(dispatch) => {
        dispatch(docManagementActions.filterResultsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.post(url, query, options).then((response) => {
            if(response.status !== 200) {
                throw Error(response.statusText);
            }
            dispatch(docManagementActions.filterResultsLoading(false));
            //dispatch(getDocsTableData(c.ROOT_URL+"/getDocsList?page_number=0"));
           //dispatch(getPageLimit(c.ROOT_URL+"/getDocsList?page_limit=0"));
            return response;
        })
        .then((response) => dispatch(docManagementActions.filterResultsSuccess(response.data.data)))
        .catch(() => dispatch(docManagementActions.filterResultsError(true)));
    }
});

export const getLocations_user_doc = ((url) => {
    return async(dispatch) => {
        dispatch(docManagementActions.getLocations_user_docLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url, options).then((response) => {
            if(response.status !== 200) {
                throw Error();
            }
            dispatch(docManagementActions.getLocations_user_docLoading(false));
            return response;
        })
        .then((response) => {
            
            dispatch(docManagementActions.getLocations_user_docSuccess(response.data.data))
        })
        .catch((error) => dispatch(docManagementActions.getLocations_user_docError(error.response.status)));
    }
});

export const getFlags = ((url) => {
    return async(dispatch) => {
        dispatch(docManagementActions.getFlagsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url, options).then((response) => {
            if(response.status !== 200) {
                throw Error();
            }
            dispatch(docManagementActions.getFlagsLoading(false));
            return response;
        })
        .then((response) => {
            
            dispatch(docManagementActions.getFlagsSuccess(response.data.data))
        })
        .catch((error) => dispatch(docManagementActions.getFlagsError(error.response.status)));
    }
});