import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPmetrics
} from "../../../redux/getdocsstatus/actions/actions";
import ApexChartNew from "./ApexChartNew/ApexChartNew";
import { Chip, } from "@material-ui/core";
import * as c from "../../../utils/constants/constants";
import * as msg from "../../../utils/constants/messages";
import "./MetricsChart.css";
import * as f from '../../../commonFunctions/commonFunctions';
import { forceLogout } from "../../../commonFunctions/forceLogout";


class MetricsChart extends Component {
  state = {
    chart_data : '',
    recent_doc: '',
    action_item : '',
    dur: "1 week",
    dur_data: ["Today", "Since Yesterday", "1 week", "2 weeks", "4 weeks"],
    series: [],
    options : {},
    recent_page_num : 0,
    action_page_num : 0
  };
 /* Be default, make API call for 1 week and load graph data*/
  componentDidMount() {
   
   this.props.getPmetrics(c.ROOT_URL + "/getPerformanceMetricsReport/date?from_date=" +f.weekDate('1week').split(',')[0]+"&to_date="+f.weekDate('1week').split(',')[1]);    
  }
  /* function to change duration and make API calls when clicked on chip buttons*/
  changeDuration = (duration) => {
    this.setState({dur : duration});
    var dur = duration.replace(' ','');
    if(dur.indexOf('week') !== -1)
      this.props.getPmetrics(c.ROOT_URL + "/getPerformanceMetricsReport/date?from_date=" +f.weekDate(dur).split(',')[0]+"&to_date="+f.weekDate(dur).split(',')[1]);
    else 
    this.props.getPmetrics(c.ROOT_URL + "/getPerformanceMetricsReport/hour?from_date=" +f.weekDate(dur).split(',')[0]+"&to_date="+f.weekDate(dur).split(',')[1]);
  };
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
   
  static getDerivedStateFromProps =(nextProps,prevState)=>{
    if(nextProps.perform_metrics.perform_metrics !== prevState.chart_data){
      var new_series = [];
      var new_axis = [];
      nextProps.perform_metrics.perform_metrics  && nextProps.perform_metrics.perform_metrics.forEach((data) => {
        data.name !== 'x_axis' ?
        new_series.push(data)
      
        :(data.data.forEach((dt) => {
            dt = dt.split(' ');
            // new_axis.push(dt[0].split('-')[0]+'/'+dt[0].split('-')[1]+(dt[1] ? ','+dt[1] : ''));
            new_axis.push(f.utcToLocal_chart(dt[0] + ' ' + (dt[1] ? dt[1] + ':00 UTC' : '00:00:00'),dt[1] ? 'hour' : 'day'));
          })
        )
      })
    return {
      options : 
      {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
         
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '15%',
            endingShape: 'rounded'
          },
        },
        colors: ["#2D9CDB","#f2994a","#27AE60"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
      
        yaxis: {
          type: "",
        //  categories: ["10k", "20k", "30k", "40k", "50k", "60k"],
        },
        xaxis: {
         type: "category",
          categories: new_axis,
         // tickPlacement : 'between',
          
          labels: {
            show :new_axis.length === 0 ? false : true,
            rotate : 0,
            rotateAlways : false,
            trim : true,
            offsetX : 0,
            offsetY : 0,
            fontSize : '5px'
           
          },
          title :{
            text : undefined,
            style :{
              fontSize : '5px'
            }
          }
        //  convertedCatToNumeric: true,
        },
        markers: {
          //size: new_axis.length === 1 ? 4 : 0,
          size : 4,
          colors: ["#2D9CDB","#f2994a","#27AE60"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        },
        tooltip: {
          enabled: true,
          intersect: false,
          followCursor: false,
  
          inverseOrder: false,
        
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      series : new_series

    };
    }
  }
 
  render() {
    return (
      <>
        <div className="flex-style">
          <div style={{ padding: "15px" }}>
            <p className="perf-title title">Performance Metrics</p>
          </div>
          <div className="chip-container flex-style" style={{ marginLeft: "10px" }}>
            {this.state.dur_data && this.state.dur_data.map((data, i) => (
              <Chip key = {i}
                label={
                  <p
                    className={
                      data === this.state.dur ? "active-chip" : "inactive-chip"
                    }
                  >
                    {data}
                  </p>
                }
                variant="outlined"
                onClick={() => this.changeDuration(data)}
                className={
                  data === this.state.dur ? "chip-active" : "chip-inactive"
                }
                size="small"
              />
              /*<div className = {data === this.state.dur ? 'custom-active-chip' :'custom-chip' } onClick={() => this.changeDuration(data)}>
                    <p className = {data === this.state.dur ? 'active-chip' : 'inactive-chip'} >{data}</p>
                  </div>*/
            ))}
          </div>
        </div>
        {this.state.series && this.state.series[0] && this.state.series[0].data.length === 0 &&
        <p className = 'chart-info-msg'>{msg.no_chart_data}</p>
        }
        
        <div className="chart-style">
         {this.props.perform_metrics.perform_metrics &&
         <ApexChartNew 
         //chart_data = {this.state.chart_data}
         series = {this.state.series}
         options = {this.state.options}
         />
         }
        </div>
       
      </>
    );
  }
}

const mapStateToProps = (state) => {
  if(state.perform_metrics.hasError === 401){
   forceLogout();
  }else if(state.perform_metrics.hasError)
    window.alert(msg.api_error);
  return {
    perform_metrics : state.perform_metrics
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPmetrics: (url) => dispatch(getPmetrics(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricsChart);
