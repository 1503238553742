import React from "react";
import {
  Typography,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

class TableHeader extends React.Component{
    render(){
        return(
            <TableHead className="thBorder">
        <TableRow>
          {this.props.tableHeadData.map((data, i) => (
            <TableCell key = {i}
              className="tableHeadStyle"
          
              align= "left"
              onClick={() => this.props.sort(data.name)}
            >
              <div className="sortSection">
                <Typography className="textTheme">{data.name}</Typography>
               
                <div
                  className={
                    data.name === this.props.audit_filter.sort_key  ? "sort-icons" : "sort-icons-hide"
                  }
                >
                  {data.name === this.props.audit_filter.sort_key && this.props.audit_filter.sort_order === true ? (
                    <ArrowUpwardIcon
                      fontSize="small"
                      style={{ color: "grey" }}
                    />
                  ) : data.name === this.props.audit_filter.sort_key && this.props.audit_filter.sort_order === false ?
                   ( <ArrowDownwardIcon
                      style={{ color: "grey" }}
                      fontSize="small"
                    />
                   ): null
                  }
                  </div>

              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
        );
    }
}

export default TableHeader;