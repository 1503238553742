import React from "react";
import {
  Typography,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import "./TableHeader.css";

class TableHeader extends React.Component {
  
  render() {
    return (
      <TableHead className="thBorder">
        <TableRow>
          {this.props.tableHeadData.map((data, i) => (
            <TableCell key = {i}
              className="tableHeadStyle"
          
              align={data.name === 'Status' ? "center" : "left"}
              onClick={() => this.props.sort(data.name)}
            >
              <div className="sortSection">
                <Typography className="textTheme">{data.name}</Typography>
               
                <div
                  className={
                    data.icon === true && data.name !== 'Action' ? "sort-icons" : "sort-icons-hide"
                  }
                >
                  {data.order === true ? (
                    <ArrowUpwardIcon
                      fontSize="small"
                      style={{ color: "grey" }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{ color: "grey" }}
                      fontSize="small"
                    />
                  )}
                  </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;
