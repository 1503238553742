import React from "react";
import "./Locations.css";
import {FormGroup, Checkbox,FormControlLabel } from "@material-ui/core";

class Locations extends React.Component {
  expandCollapse = () => {
    if (this.props.searchVal?.openLocation === true && this.props.searchVal?.openCategory === false) {
      return '74vh';
    } 
    else if (this.props.searchVal?.openLocation === true && this.props.searchVal?.openCategory === true) {
      return '50vh';   
    }
  }
  render() {
    let _searchData = this.props.searchVal;
    let all_locs = (_searchData?.search_loc === true && _searchData?.locationSearch.length > 2) ? this.props.loc_search_result : _searchData?.search_loc === false ? this.props.doc_loc : this.props.doc_loc;
    return (
      <div className="loc-section">
        <div id="doc-loc-box" style={{height: this.expandCollapse()}}>
          {all_locs && all_locs.map((data, i) => (
            <div key = {i} style = {{display : 'flex' , alignItems : 'center'}}>
            <div className = {data.name === 'Select All' ? 'margin-first' : 'margin-all'}>
              <FormGroup>
                <FormControlLabel
                  name={data.name}
                  control={
                    <Checkbox
                      size = "small"
                      checked = {data.isChecked}
                      //color="primary"
                     className = 'check-box-color'
                      onChange = {(event) => this.props.handleLocations(event,i, 'location', all_locs)}
                    />
                  }
                  label={<p className = 'loc-name'>{data.name}</p>}
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
           
            </div>
          ))}
        </div>

      </div>
    );
  }
}

export default Locations;
