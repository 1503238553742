import { USER_MGMT_ACTIONS } from "./actionTypes";

export const userManagementActions = {};

userManagementActions.getUsersTableDataError = ((err) => {
    return {
        type: USER_MGMT_ACTIONS.GET_USERS_ERROR,
        hasError: err
    }
});

userManagementActions.getUsersTableDataLoading = ((bool) => {
    return {
        type: USER_MGMT_ACTIONS.GET_USERS_LOADING,
        isLoading: bool
    }
});

userManagementActions.getUsersTableDataSuccess = ((usertabledata) => {
    return {
        type: USER_MGMT_ACTIONS.GET_USERS_SUCCESS,
        usertabledata: usertabledata,
       
    }
});
userManagementActions.getAllUsersError = ((err) => {
    return {
        type: USER_MGMT_ACTIONS.GET_ALL_USERS_ERROR,
        hasError: err
    }
});

userManagementActions.getAllUsersLoading = ((bool) => {
    return {
        type: USER_MGMT_ACTIONS.GET_ALL_USERS_LOADING,
        isLoading: bool
    }
});

userManagementActions.getAllUsersSuccess = ((allusers) => {
    return {
        type: USER_MGMT_ACTIONS.GET_ALL_USERS_SUCCESS,
        allusers: allusers.data,
       
    }
});

userManagementActions.addUserError = ((err) => {
    return {
        type: USER_MGMT_ACTIONS.ADD_USER_ERROR,
        hasError: err
    }
});

userManagementActions.addUserLoading = ((bool) => {
    return {
        type: USER_MGMT_ACTIONS.ADD_USER_LOADING,
        isLoading: bool
    }
});

userManagementActions.addUserSuccess = ((adduserresponse) => {
    return {
        type: USER_MGMT_ACTIONS.ADD_USER_SUCCESS,
        adduserresponse: adduserresponse
    }
});

userManagementActions.editUserError = ((err) => {
    return {
        type: USER_MGMT_ACTIONS.EDIT_USER_ERROR,
        hasError: err
    }
});

userManagementActions.editUserLoading = ((bool) => {
    return {
        type: USER_MGMT_ACTIONS.EDIT_USER_LOADING,
        isLoading: bool
    }
});

userManagementActions.editUserSuccess = ((userdata) => {
    return {
        type: USER_MGMT_ACTIONS.EDIT_USER_SUCCESS,
        userdata: userdata
    }
});

userManagementActions.getAllLocationsError = ((err) => {
    return {
        type: USER_MGMT_ACTIONS.GET_ALL_LOCATIONS_ERROR,
        hasError: err
    }
});

userManagementActions.getAllLocationsLoading = ((bool) => {
    return {
        type: USER_MGMT_ACTIONS.GET_ALL_LOCATIONS_LOADING,
        isLoading: bool
    }
});

userManagementActions.getAllLocationsSuccess = ((alllocations_user) => {
    return {
        type: USER_MGMT_ACTIONS.GET_ALL_LOCATIONS_SUCCESS,
        alllocations_user: alllocations_user
    }
});


