import { DOC_MGMT_ACTIONS } from "./actionTypes";

export const docManagementActions = {};

docManagementActions.getCategoryCountError = ((err) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_CATEGORY_COUNT_ERROR,
        hasError: err
    }
});

docManagementActions.getCategoryCountLoading = ((bool) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_CATEGORY_COUNT_LOADING,
        loading: bool
    }
});

docManagementActions.getCategoryCountSuccess = ((categoryitems) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_CATEGORY_COUNT_SUCCESS,
        categoryitems: categoryitems
    }
});

docManagementActions.getDocsTableDataError = ((err) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_DOCS_TABLE_DATA_ERROR,
        hasError: err
    }
});

docManagementActions.getDocsTableDataLoading = ((bool) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_DOCS_TABLE_DATA_LOADING,
        loading: bool
    }
});

docManagementActions.getDocsTableDataSuccess = ((tabledata) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_DOCS_TABLE_DATA_SUCCESS,
        tabledata: tabledata
    }
});

docManagementActions.getPageLimitError = ((err) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_PAGE_LIMIT_ERROR,
        hasError: err
    }
});

docManagementActions.getPageLimitLoading = ((bool) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_PAGE_LIMIT_LOADING,
        loading: bool
    }
});

docManagementActions.getPageLimitSuccess = ((totalrecords) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_PAGE_LIMIT_SUCCESS,
        totalrecords: totalrecords
    }
});

docManagementActions.getAllLocationsError = ((err) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_ALL_LOCATIONS_DOC_ERROR,
        hasError: err
    }
});

docManagementActions.getAllLocationsLoading = ((bool) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_ALL_LOCATIONS_DOC_LOADING,
        loading: bool
    }
});

docManagementActions.getAllLocationsSuccess = ((alllocations) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_ALL_LOCATIONS_DOC_SUCCESS,
        alllocations: alllocations
    }
});


docManagementActions.getDocImageUrlSuccess = ((doc_image_url) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_IMAGE_URL_SUCCESS,
        doc_image_url : doc_image_url
    }
});
docManagementActions.getDocImageUrlLoading = ((bool) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_IMAGE_URL_LOADING,
        loading: bool
    }
});
docManagementActions.getDocImageUrlError = ((err) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_IMAGE_URL_ERROR,
        hasError: err
    }
});


docManagementActions.getLocations_user_docSuccess = ((locations_user) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_LOCATIONS_USER_SUCCESS,
        locations_user : locations_user
    }
});
docManagementActions.getLocations_user_docLoading = ((bool) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_LOCATIONS_USER_LOADING,
        loading: bool
    }
});
docManagementActions.getLocations_user_docError = ((err) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_LOCATIONS_USER_ERROR,
        hasError: err
    }
});
/*============filter resulst===not used===========================*/

docManagementActions.filterResultsSuccess = ((results) => {
    return {
        type: DOC_MGMT_ACTIONS.POST_FILTER_SUCCESS,
        results : results
    }
});
docManagementActions.filterResultsLoading = ((results) => {
    return {
        type: DOC_MGMT_ACTIONS.POST_FILTER_LOADING,
        results : results
    }
});
docManagementActions.filterResultsError = ((results) => {
    return {
        type: DOC_MGMT_ACTIONS.POST_FILTER_ERROR,
        results : results
    }
});
/*================flags list================================*/

docManagementActions.getFlagsSuccess = ((flags) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_FLAGS_SUCCESS,
        flags : flags
    }
});
docManagementActions.getFlagsLoading = ((bool) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_FLAGS_LOADING,
        loading : bool
    }
});
docManagementActions.getFlagsError = ((err) => {
    return {
        type: DOC_MGMT_ACTIONS.GET_FLAGS_ERROR,
        hasError : err
    }
});
