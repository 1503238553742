import { CSVLink } from "react-csv";
import React from "react";
import * as c from "../../../utils/constants/constants";
import * as f from "../../../commonFunctions/commonFunctions";

/* To show generated person and date, metrics, avg doc per location and avg doc per category data in excel/csv format
All data has to be fetched in seperate endpoint and hence present in seperate json. 
react-csv package accepts single json as headers and single json for data as inputs.
To satisfy this, we have kept the headers json as empty and combined all data into one json to be passed as data input.
combined_json containes all data inclucing headers, whatever we see in excel in present in combined_json variable.
react-csv accepts json in same format with equal no.of keys across data. To satisfy this condition, we have modified the data we get from backend by adding additional keys.
Added S.no for category and location.
*/

const MonthlyReportExport = (props) => {

    const dummy_headers = [
        { label: "Document: "+ c.PRODUCT_NAME +" monthly usage report for "+c.MONTH_ARR[JSON.parse(localStorage.getItem(c.RP_MONTH)).month]+", "+JSON.parse(localStorage.getItem(c.RP_MONTH)).year+".", key: "s_no" },
        { label: "", key: "Name" },
        { label: "", key: "Doc_Count" },
        { label: "", key: "Average_Pages" },
      ];
      
    const export_info = [
        {s_no: "Export By:", Name: localStorage.getItem(c.USER_NAME), Doc_Count: "", Average_Pages: "" },
        {s_no: "Export Date/Time:", Name: f.currDate()+ ', ' + f.currTime(), Doc_Count: "", Average_Pages: "" },
    ]
    const metrics_headers = [
        {s_no: "", Name: "", Doc_Count: "", Average_Pages: "" },
        {s_no: "Documents Received", Name: "Total Pages Processed", Doc_Count: "Average Pages Processed", Average_Pages: "Average Processing Time" }
    ]
    const loc_headers = [
        {s_no: "No.", Name: "Location Name", Doc_Count: "Doc Count", Average_Pages: "Avg Pages" }
    ]
    const cat_headers = [
        {s_no: "No.", Name: "Category Name", Doc_Count: "Doc Count", Average_Pages: "Avg Pages" }
    ]
    const report_end = [
        {s_no: "", Name: "", Doc_Count: "", Average_Pages: "" },
        {s_no: "<End of Report>", Name: "", Doc_Count: "", Average_Pages: "" }
    ]
    let metrics_data = '';
    if(props?.rp_metrics[0]){
    metrics_data = [
        {s_no:  (props.rp_metrics[0].Documents_Received ? props.rp_metrics[0].Documents_Received : "0") + " docs", 
        Name: (props.rp_metrics[0].Total_Pages_Processed ? props.rp_metrics[0].Total_Pages_Processed : "0") + " pages",
        Doc_Count: (props.rp_metrics[0].Average_Pages_Processed ? props.rp_metrics[0].Average_Pages_Processed : "0") + " pages per doc",
        Average_Pages: props.rp_metrics[0] && f.secToMin(
            props.rp_metrics[0].Average_Processing_Time
          ).split(",")[0] + " min " +  f.secToMin(
            props.rp_metrics[0].Average_Processing_Time
          ).split(",")[1] + " sec" 
        },
        {s_no: "", Name: "", Doc_Count: "", Average_Pages: "" }]
    }
   var new_cat =  props?.avg_pg_category && JSON.parse(JSON.stringify(props?.avg_pg_category)) || [];
   var new_loc = props?.avg_pg_loc && JSON.parse(JSON.stringify(props?.avg_pg_loc)) || [];
   new_cat && new_cat.map((dt,i) => {
        dt.s_no = i+1;
    })
    new_loc && new_loc.map((dt,i) => {
        dt.s_no = i+1;
    })
    let combined_json = [];
    combined_json = export_info.concat(metrics_headers)
                    .concat(metrics_data)
                    .concat([{s_no: "Documents count by Location"}])
                    .concat(loc_headers)
                    .concat(new_loc)
                    .concat([{s_no: ""}])
                    .concat([{s_no: "Documents count by Category"}])
                    .concat(cat_headers)
                    .concat(new_cat)
                    .concat(report_end);
    var name_formatted = (JSON.parse(localStorage.getItem(c.RP_MONTH)).year)+"-"+((JSON.parse(localStorage.getItem(c.RP_MONTH)).month+1) < 10 ? ("0"+(JSON.parse(localStorage.getItem(c.RP_MONTH)).month+1)) : (JSON.parse(localStorage.getItem(c.RP_MONTH)).month+1));
return(
        <div className="export-report-link">
        <CSVLink 
            className="export-report-link"
            data={combined_json} 
            headers={dummy_headers}
            filename={"readabl-monthlyusagereport-"+name_formatted+".csv"}>
        Export
        </CSVLink>
        </div>
)
}
export default MonthlyReportExport;