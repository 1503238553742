import { GET_DOCS_ACTIONS } from './actionTypes';

export const getDocsStatusActions = {};
/*=========================doc status==================================*/
getDocsStatusActions.getDocsStatusError = ((err) => {
    return {
        type: GET_DOCS_ACTIONS.GET_DOCS_STATUS_ERROR,
        hasError: err
    }
});

getDocsStatusActions.getDocsStatusLoading = ((bool) => {
    return {
        type: GET_DOCS_ACTIONS.GET_DOCS_STATUS_LOADING,
        isLoading: bool
    }
});

getDocsStatusActions.getDocsStatusSuccess = ((items) => {
    return {
        type: GET_DOCS_ACTIONS.GET_DOCS_STATUS_SUCCESS,
        items : items
    }
});
/*================================recent docs==========================================*/
getDocsStatusActions.getRecentDocsError = ((err) => {
    return {
        type: GET_DOCS_ACTIONS.GET_RECENT_DOCS_ERROR,
        hasError: err
    }
});

getDocsStatusActions.getRecentDocsLoading = ((bool) => {
    return {
        type: GET_DOCS_ACTIONS.GET_RECENT_DOCS_LOADING,
        isLoading: bool
    }
});

getDocsStatusActions.getRecentDocsSuccess = ((recentdocs) => {
    return {
        type: GET_DOCS_ACTIONS.GET_RECENT_DOCS_SUCCESS,
        recentdocs : recentdocs
    }
});

/*==================================sction items=====================================*/
getDocsStatusActions.getActionItemsError = ((err) => {
    return {
        type: GET_DOCS_ACTIONS.GET_ACTION_ITEMS_ERROR,
        hasError: err
    }
});

getDocsStatusActions.getActionItemsLoading = ((bool) => {
    return {
        type: GET_DOCS_ACTIONS.GET_ACTION_ITEMS_LOADING,
        isLoading: bool
    }
});

getDocsStatusActions.getActionItemsSuccess = ((actionitems) => {
    return {
        type: GET_DOCS_ACTIONS.GET_ACTION_ITEMS_SUCCESS,
        actionitems : actionitems
    }
});
/*==================================logs===================================*/

getDocsStatusActions.getLogsError = ((err) => {
    return {
        type: GET_DOCS_ACTIONS.GET_LOGS_ERROR,
        hasError: err
    }
});

getDocsStatusActions.getLogsLoading = ((bool) => {
    return {
        type: GET_DOCS_ACTIONS.GET_LOGS_LOADING,
        isLoading: bool
    }
});

getDocsStatusActions.getLogsSuccess = ((logs) => {
    return {
        type: GET_DOCS_ACTIONS.GET_LOGS_SUCCESS,
        logs : logs
    }
});

/*================================Intelligence metrics===================================*/

getDocsStatusActions.getImetricsError = ((err) => {
    return {
        type: GET_DOCS_ACTIONS.GET_IMETRICS_ERROR,
        hasError: err
    }
});

getDocsStatusActions.getImetricsLoading = ((bool) => {
    return {
        type: GET_DOCS_ACTIONS.GET_IMETRICS_LOADING,
        isLoading: bool
    }
});

getDocsStatusActions.getImetricsSuccess = ((int_metrics) => {
    return {
        type: GET_DOCS_ACTIONS.GET_IMETRICS_SUCCESS,
        int_metrics : int_metrics
    }
});

/*================================Performance metrics===================================*/

getDocsStatusActions.getPmetricsError = ((err) => {
    return {
        type: GET_DOCS_ACTIONS.GET_PMETRICS_ERROR,
        hasError: err
    }
});

getDocsStatusActions.getPmetricsLoading = ((bool) => {
    return {
        type: GET_DOCS_ACTIONS.GET_PMETRICS_LOADING,
        isLoading: bool
    }
});

getDocsStatusActions.getPmetricsSuccess = ((perform_metrics) => {
    return {
        type: GET_DOCS_ACTIONS.GET_PMETRICS_SUCCESS,
        perform_metrics : perform_metrics
    }
});
