import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import * as c from "../../../utils/constants/constants";
import * as dayjs from "dayjs";

const getReportName = (props) => {
    let { selectedFilters = {} } = props || {};
    return "Document: " + c.PRODUCT_NAME + " location-hourly usage report " + dayjs(selectedFilters.selectedDate).format("MM/DD/YYYY") + "."
}

const getCsvTitle = (props) => {
    let { selectedFilters = {}, name } = props || {};
    return "readabl_locationhourlyusagereport_" + dayjs(selectedFilters.selectedDate).format("MM/DD/YYYY") + ".csv"
}

const getLocationHourlyCounts = (rawData) => {
    const { categories = [], data = {}} = rawData || {};
    let headers = {column_0: "S.no", column_1: "Time", column_2: "Total"};
    let locations = Object.keys(data);
    locations.forEach((m, i) => {
        headers["column_" + (i + 3)] = m;
    });
    
    let finalData = categories.map((m, i) => {
        let finalObj = {
            column_0: i + 1,
            column_1: m,
        }

        let sum = 0;
        locations.forEach((n, j) => {
            finalObj["column_" + (j + 3)] = data[n][i];
            sum += data[n][i];
        });

        finalObj["column_2"] = sum;

        return finalObj;
    })

    return {
        headers: [(headers || {})],
        data: finalData || []
    }
}

const HourlyReportExport = (props) => {

    const [dummy_headers, updateDummyHeaders] = useState([]);
    const [combined_json, updateCombinedJson] = useState([]);

    useEffect(() => {
        let d_headers = [
            { label: getReportName(props), key: "column_0" },
            { label: '', key: "column_1" },
            { label: '', key: "column_2" },
        ]

        console.log(props)
        let length = Object.keys(props.data?.data || {}).length;

        for (let i = 0; i < length; i++) {
            d_headers.push({
                label: "",
                key: "column_" + (i + 3)
            })
        }
        updateDummyHeaders(d_headers);

        const export_info = [
            { column_0: "Export By:", column_1: localStorage.getItem(c.USER_NAME), column_2: "" },
            { column_0: "Export Date/Time:", column_1: dayjs().format("MM/DD/YYYY, HH:mm:ss"), column_2: "" },
        ];

        const empty_row = [
            { column_0: "" }
        ];

        const report_end = [
            { column_0: "<End of Report>", column_1: "", column_2: "" }
        ];

        const locationCount = getLocationHourlyCounts(props.data);
        console.log(locationCount)
        let combined_json = [];
        combined_json = export_info
            .concat(empty_row)
            .concat([ { column_0: "Documents count by Location - Hourly"}])
            .concat(locationCount.headers)
            .concat(locationCount.data)
            .concat(empty_row)
            .concat(report_end);
        updateCombinedJson(combined_json);
    }, [props.data]);

    return (
        <div className="export-report-link">
            <div className="button-as-link" onClick={props.generateHourlyReportExport}>Location - Hourly Export</div>
            <CSVLink
                className="custom-date-range-export-link"
                headers={dummy_headers}
                filename={getCsvTitle(props)}
                data={combined_json}
                ref={props.csvLinkEl}
            />
        </div>
    )
}
export default HourlyReportExport;