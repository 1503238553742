import React from "react";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "./SuperAdmin.css";
import * as c from "../../utils/constants/constants";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Link } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
/* This screen contains all info and extra info that is not shown inside the application. There is no re-direction to this screen from anywhere.
users come to this screen by giving url/superAdmin if authenticated. this screen is secured with password.
this is for internal purpose and users with admin role can only access*/

class SuperAdmin extends React.Component {
  state = {
    lock: true,
    secret_key: "",
    showPassword: false,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  /*function to lock/uncloak super admin screen*/
  unlockScreen = (key) => {
    if (key === c.SECRET_KEY) {
      this.setState({ lock: false });
      localStorage.setItem("admin_screen", "unlocked");
    } else {
      window.alert("Invalid key");
      localStorage.setItem("admin_screen", "locked");
    }
  };
 
  
  render() {
    return (
      <div className = "sa-lock-screen">
       {localStorage.getItem("admin_screen") && localStorage.getItem("admin_screen") === "unlocked"  ?  
       <div className = "sa-menu-list">
         <div className = "flex-style">
           <DescriptionIcon style = {{color : "#aa1572", marginRight : "5px"}}/>
           <Link className = "sa-each-menu" to='/docInfo'>View document details</Link>
           </div>
           <div className = "flex-style">
           <DescriptionIcon style = {{color : "#aa1572", marginRight : "5px"}}/>
           <Link className = "sa-each-menu" to='/docStuck'>View documents stuck in progress</Link>
           </div>
       </div> :

       <>
          <div>ENTER SECRET KEY</div>
          <div className = "status-icon">
            <TextField
              variant="outlined"
              autoComplete="off"
              margin="dense"
              className="text-box"
              //label="Enter secret key"
              style={{ marginRight: "20px" }}
              name="secret_key"
              value={this.state.secret_key}
              onChange={(e) => this.handleChange(e)}
              type={this.state.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              className = "mui-buttons-custom-filled"
              variant="contained"
              onClick={() => this.unlockScreen(this.state.secret_key)}
            >
              Go
            </Button>
          </div>
          </>}
        </div>
    
      
    )
}}

export default SuperAdmin;
