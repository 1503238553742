import axios from 'axios';
import { userManagementActions } from './actionCreators';
import {getToken} from "../../../commonFunctions/getToken";
import * as c from "../../../utils/constants/constants";

export const getUsersTableData = ((url, query) => {
    return async(dispatch) => {
        dispatch(userManagementActions.getUsersTableDataLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          },
        data: {
            role: "Admin",
            status: true
        }};
        axios({
            method: "post",
            url: url,
            data: query,
            headers: { Authorization: "Bearer " + token },
          }).then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }
                dispatch(userManagementActions.getUsersTableDataLoading(false));
                return response;
            })
            .then((response) => dispatch(userManagementActions.getUsersTableDataSuccess(response.data)))
            .catch((error) => dispatch(userManagementActions.getUsersTableDataError(error.response.status)));
    }
});
export const getAllUsers = ((url) => {
    return async(dispatch) => {
        dispatch(userManagementActions.getAllUsersLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }
                dispatch(userManagementActions.getAllUsersLoading(false));
                return response;
            })
            .then((response) => dispatch(userManagementActions.getAllUsersSuccess(response.data)))
            .catch((error) => dispatch(userManagementActions.getAllUsersError(error.response.status)));
    }
});
export const addUser = ((url, userdata,comp,action) => {
    
    return async(dispatch) => {
        dispatch(userManagementActions.addUserLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.post(url, userdata, options).then((response) => {
            if(response.status !== 200) {
                throw Error();
            }
            dispatch(userManagementActions.addUserLoading(false));
            dispatch(getUsersTableData(c.ROOT_URL+'/getUsersList?page_number=0&page_limit=8&comp='+comp+'&action='+action));
            return response;
        })
        .then((response) => dispatch(userManagementActions.addUserSuccess(response.data.data)))
        .catch((error) => dispatch(userManagementActions.addUserError(error.response.status)));
    }
});

export const editUser = ((url, userdata,comp,action) => {
    return async(dispatch) => {
        dispatch(userManagementActions.editUserLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.put(url, userdata, options).then((response) => {
            if(response.status !== 200) {
                throw Error();
            }
            dispatch(userManagementActions.editUserLoading(false));
            dispatch(getUsersTableData(c.ROOT_URL+'/getUsersList?page_number=0&page_limit=8&comp='+comp+'&action='+action));
            return response;
        })
        .then((response) => dispatch(userManagementActions.editUserSuccess(response.data.data)))
        .catch((error) => dispatch(userManagementActions.editUserError(error.response.status)));
    }
}); 

export const getAllLocations_user = ((url) => {
    return async(dispatch) => {
        dispatch(userManagementActions.getAllLocationsLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        axios.get(url, options).then((response) => {
            if(response.status !== 200) {
                throw Error();
            }
            dispatch(userManagementActions.getAllLocationsLoading(false));
            return response;
        })
        .then((response) => {
            
            dispatch(userManagementActions.getAllLocationsSuccess(response.data.data))
        })
        .catch((error) => dispatch(userManagementActions.getAllLocationsError(error.response.status)));
    }
});