import React from 'react';
import './index.css';
import MonthlyChart from './MonthlyChart/MonthlyChart';
import ActiveData from './ActiveData/ActiveData';
import AvgPages from './AvgPages/AvgPages';
import Metrics from './Metrics/Metrics';

class MonthlyReport extends React.Component {

    render() {
        return (
            <div className='monthly-report-container'>
                <div className='monthly-report-left-portion'>
                    <Metrics {...this.props} />
                    <ActiveData {...this.props}/>
                </div>
                <div className='monthly-report-right-portion'>
                    <MonthlyChart {...this.props}/>
                    <AvgPages {...this.props}/>
                </div>
            </div>
        )
    }
}
export default MonthlyReport;