import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  Tabs,
  Typography,
  Box,
} from "@mui/material";
import "./EHRPopup.css";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Encounters from "./Encounters/Encounters";
import Orders from "./Orders/Orders";
import InfoIcon from '@mui/icons-material/Info';

/*This component handles the EHR popup data display based on doc level.tab value: 0 for patient, value:1 for encounter
value:2 for order*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FetchIDPopup = (props) => {
  return (
    <>
      <Dialog className="EHR" open={props.fetch_id}>
        <DialogTitle className="title-bg">
          <div className="upload-title">
            <div className="ehr-popup-header">
              <div style={{ marginRight: "3em" }}>EHR DETAILS</div>
              <div>Doc ID: {props.all_data.ID}</div>
              <div className="ehr-popup-vertical-divider" ></div>
              <div>Patient MRN: {props.all_data.MRN}</div>
              <div className="ehr-popup-vertical-divider" ></div>
              <div>Sub-Category: {props.all_data.Entity_Details.classify.documentSubCategory}</div>
              <div className="ehr-popup-vertical-divider" ></div>
              <div>Document Level: {props.all_data.Entity_Details.classify.documentLevel}</div>
            </div>
            <CloseIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => props.fetchID("close")}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="ehr-popup-size">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={props.value}
                  onChange={props.handleChange}
                  variant="fullWidth"
                  aria-label="basic tabs example"
                >
                  <Tab label="Patient Info" value={0} {...a11yProps(0)} />
                  <Tab label="Encounters" value={1} {...a11yProps(1)} />
                  <Tab label="Orders" value={2} {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={props.value} index={0}>
                <div className="ehr-pt-details">
                  <div className="ehr-pt-each-detail">
                    <div className="ehr-pt-label">ID</div>
                    <div className="ehr-pt-value">{props.all_data.Entity_Details.patientMatch.ID}</div>
                  </div>
                  <div className="ehr-pt-each-detail">
                    <div className="ehr-pt-label">MRN</div>
                    <div className="ehr-pt-value">
                      {props.all_data.Entity_Details.patientMatch.MRN}
                    </div>
                  </div>
                  <div className="ehr-pt-each-detail">
                    <div className="ehr-pt-label">Name</div>
                    <div className="ehr-pt-value">
                      {props.all_data.Entity_Details.patientMatch.official_name}
                    </div>
                  </div>
                  <div className="ehr-pt-each-detail">
                    <div className="ehr-pt-label">DOB</div>
                    <div className="ehr-pt-value">
                      {props.all_data.Entity_Details.patientMatch.DOB}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={props.value} index={1}>
                <Encounters
                  all_data={props.all_data}
                  value={props.value}
                  doc_level={props.doc_level}
                  fetchID={props.fetchID}
                  patientEncounters={props.patientEncounters}
                  tabLoader={props.tabLoader}
                  encData={props.encData}
                  saveEHRData={props.saveEHRData}
                  encId={props.encId}
                />
              </TabPanel>
              <TabPanel value={props.value} index={2}>
                <Orders
                  all_data={props.all_data}
                  value={props.value}
                  doc_level={props.doc_level}
                  fetchID={props.fetchID}
                  patientOrders={props.patientOrders}
                  getOrderCategoryList={props.getOrderCategoryList}
                  tabLoader={props.tabLoader}
                  orderData={props.orderData}
                  orderCategoryList={props.orderCategoryList}
                  saveEHRData={props.saveEHRData}
                />
              </TabPanel>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FetchIDPopup;
