import React from "react";
import {
  Paper,
  Divider,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./AvgPages.css";

class AvgPages extends React.Component {
  state = {
    search_cat: "",
    search_loc: "",
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <>
      {/*=================SCREEN VIEW========================*/}
        <div className="rp-paper">
         <Paper
            elevation={3}
            className="paper-style"
            style={{ margin: "10px" }}
          >
            <div className="paper-title">
              <p className="rp-title">Average Pages by Category</p>
            </div>
            <Divider />
             <div className="rp-avg-page-row">
                <FormControl>
                  <TextField
                    variant="standard"
                    style={{ width: "175px" }}
                    id="input-with-icon-textfield"
                    placeholder="Search Category"
                    name="search_cat"
                    autoComplete="off"
                    value={this.state.search_cat}
                    onChange={(e) => {
                      this.handleChange(e);
                      this.props.findData(e.target.value, "category");
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {/*  <CloseIcon
                                className="icon-action"
                                //onClick={() => this.props.cancelFilter("search")}
                            />*/}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <div className="rp-avg-page-row-count">
                  <p className = "rp-avg-data" style = {{color : "grey"}}>Doc Count</p>
                  <p className = "rp-avg-data" style = {{color : "grey"}}>Avg Pages</p>
                </div>
              </div>
              <Divider />
              <div className="doc-view">
              {this.state.search_cat.length > 0  ?
                (this.props.search_result_cat && this.props.search_result_cat.map((data,i) => 
                    <div key = {i}>
                    <div className="rp-avg-page-row">
                      <div className = "rp-left-text">{data.Name}</div>
                      <div className="rp-avg-page-row-count">
                        <div className = "rp-avg-data">{data.Doc_Count.toLocaleString()}</div>
                        <div className = "rp-avg-data">{data.Average_Pages}</div>
                      </div>
                    </div>
                    <Divider />
                  </div>
            )):
            (this.props.avg_pg_category &&
                this.props.avg_pg_category.map((data, i) => (
                  <div key={i}>
                    <div className="rp-avg-page-row">
                      <div className = "rp-left-text">{data.Name}</div>
                      <div className="rp-avg-page-row-count">
                        <div className = "rp-avg-data">{data.Doc_Count.toLocaleString()}</div>
                        <div className = "rp-avg-data">{data.Average_Pages}</div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                ))
            )}
            </div>
          </Paper>
          <Paper
            elevation={3}
            className="paper-style"
            style={{ margin: "10px" }}
          >
            <div className="paper-title">
              <p className="rp-title">Average Pages by Location</p>
            </div>
            <Divider />
            
              <div className="rp-avg-page-row">
                <FormControl>
                  <TextField
                    variant="standard"
                    style={{ width: "175px" }}
                    id="input-with-icon-textfield"
                    placeholder="Search Location"
                    name="search_loc"
                    autoComplete="off"
                    value={this.state.search_loc}
                    onChange={(e) => {
                      this.handleChange(e);
                      this.props.findData(e.target.value, "location");
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {/*  <CloseIcon
                                className="icon-action"
                                //onClick={() => this.props.cancelFilter("search")}
                            />*/}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <div className="rp-avg-page-row-count">
                  <p className = "rp-avg-data" style = {{color : "grey"}}>Doc Count</p>
                  <p className = "rp-avg-data" style = {{color : "grey"}}>Avg Pages</p>
                </div>
              </div>
              <Divider />
              <div className="doc-view">
              {this.state.search_loc.length > 0 ?
                 (this.props.search_result_loc && this.props.search_result_loc.map((data,i) => 
                 <div key={i}>
                 <div className="rp-avg-page-row">
                   <div className = "rp-left-text">{data.Name}</div>
                   <div className="rp-avg-page-row-count">
                     <div className = "rp-avg-data">{data.Doc_Count.toLocaleString()}</div>
                     <div className = "rp-avg-data">{data.Average_Pages}</div>
                   </div>
                 </div>
                 <Divider />
               </div>
               )):
                (this.props.avg_pg_loc && this.props.avg_pg_loc.map((data, i) => (
                  <div key={i}>
                    <div className="rp-avg-page-row">
                      <div className = "rp-left-text">{data.Name}</div>
                      <div className="rp-avg-page-row-count">
                        <div className = "rp-avg-data">{data.Doc_Count.toLocaleString()}</div>
                        <div className = "rp-avg-data">{data.Average_Pages}</div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                ))
                )}
            </div>
          </Paper>
        </div>
        {/*=================PRINT VIEW========================*/}
       
        <div className = "rp-paper-print-cat">
              <div>
              <p className="rp-title">Average Pages by Category</p>
              <div className="flex-style">
                  <p  className = "rp-print-text" style = {{width : "40%",color : "grey"}}>Name</p>
                  <p className = "rp-print-text" style = {{width : "20%",color : "grey"}}>Doc Count</p>
                  <p className = "rp-print-text" style = {{width : "20%",color : "grey"}}>Avg Pages</p>
             </div>
              {this.props.avg_pg_category &&
                this.props.avg_pg_category.map((data, i) => (
                  <div className = "flex-style" key={i}>
                      <p  className = "rp-print-text" style = {{width : "40%"}}>{data.Name}</p>
                      <p className = "rp-print-text" style = {{width : "20%"}}> {data.Doc_Count.toLocaleString()}</p>
                      <p className = "rp-print-text" style = {{width : "20%"}}>{data.Average_Pages}</p>
                       
                  </div>
                ))
                }
              </div>
        </div>
        <div className = "fake-pg-brk"></div>
        <div className = "rp-paper-print-loc">
              <div>
              
                <p className="rp-title">Average Pages by Location</p>
                <div className="flex-style">
                  <p  className = "rp-print-text" style = {{width : "40%",color : "grey"}}>Name</p>
                  <p className = "rp-print-text" style = {{width : "20%",color : "grey"}}>Doc Count</p>   
                  <p className = "rp-print-text" style = {{width : "20%",color : "grey"}}>Avg Pages</p>
                </div>
               
              {this.props.avg_pg_loc &&
                this.props.avg_pg_loc.map((data, i) => (
                  <div className = "flex-style" key={i}>
                      <p  className = "rp-print-text" style = {{width : "40%"}}>{data.Name}</p>
                      <p className = "rp-print-text" style = {{width : "20%"}}> {data.Doc_Count.toLocaleString()}</p>
                      <p className = "rp-print-text" style = {{width : "20%"}}>{data.Average_Pages}</p>
                  </div>
                
                ))
                }
              </div>
        </div>
        
      </>
    );
  }
}

export default AvgPages;
