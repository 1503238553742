import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./AuditLog.css";
import Filter from "./Filter/Filter";
import SearchBar from "../../components/SearchBar/SearchBar";
import {TableContainer,Paper,Table,CircularProgress} from "@mui/material";
import TableData from './LogTable/TableData/TableData';
import TableHeader from './LogTable/TableHeader/TableHeader';
import TablePage from '../../components/TablePage/TablePage';
import * as c from '../../utils/constants/constants';
import * as p from '../../utils/parameter/parameter';
import * as msg from "../../utils/constants/messages";
//import { connect } from "react-redux";
//import {getAudit,auditResult} from "../../redux/auditLog/actions/actions";
import api from "../../utils/API/api";
import DialogModel from "../../components/DialogModel/DialogModel";
import * as f from "../../commonFunctions/commonFunctions";
import {forceLogout} from "../../commonFunctions/forceLogout";
import ExportAudit from "./ExportAudit";

class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    audit_log : [],
    audit_log_export: [],
    startRecordNum: 0,
    recordsPerPage: 15,
    suggest_box: "",
    is_search: false,
    tableHeadData: [
      { name: "User Name", order: true, icon: false },
      { name: "Component", order: true, icon: false },
      { name: "Document Name", order: true, icon: false },
      { name: "Document Old Name", order: true, icon: false },
      { name: "Action", order: true, icon: false },
      { name: "Updated on", order: false, icon: true },
      
    ],
    audit_log_length : 0,
    is_changed : false,
    error_filter : '',
    search_val : '',
    refresh_list : false, //overlay loader applies whenever any filter is applied on screen 
    isLoading : true, //loader applies on initial load of data
    /*this object contains all filter fields, entire object is retained and filter is passed to endpoint to fetch results*/
    audit_filter : {
      pageNum : 0,
      user_name: "",
      fromDate: "",
      toDate: "",
      sort_order: false,
      sort_key: "Updated on",
      search : "",
      action: ""
    }
  }
  this.csvLinkEl = React.createRef();
}
  componentDidMount = () => {
    document.title = c.PRODUCT_NAME+' - Audit';
    localStorage.removeItem(c.DOC_STATUS);
    var audit_filter = JSON.parse(localStorage.getItem("audit_filter"));
    //if filter data present in localStorage, use it to fetch results, else use sudit_filter defined in state to fetch results
    if(audit_filter){
      this.setState({audit_filter} , () => {
        this.fetchResults(audit_filter);
      })
      
    }else
    this.setState({ audit_filter: {...this.state.audit_filter,fromDate: this.setAuditDate().split("_")[0],
        toDate: this.setAuditDate().split("_")[1]} }, () => {
      this.fetchResults(this.state.audit_filter);
   });
    // this.fetchResults(this.state.audit_filter);
 }
 /*setting from date as curr date - 30 days and toDate as curr date*/
  setAuditDate = () => {
    let toDate = new Date();
    let fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 1);
    let from = `${fromDate.getFullYear()}-${(Number(fromDate.getMonth()) + 1) < 10 ? ("0" + (Number(fromDate.getMonth()) + 1)) : (Number(fromDate.getMonth()) + 1)}-${fromDate.getDate() < 10 ? ("0" + Number(fromDate.getDate())) : fromDate.getDate()}`;
    let to = `${toDate.getFullYear()}-${(Number(toDate.getMonth()) + 1) < 10 ? ("0" + (Number(toDate.getMonth()) + 1)) : (Number(toDate.getMonth()) + 1)}-${toDate.getDate() < 10 ? ("0" + Number(toDate.getDate())) : toDate.getDate()}`;
    return from + "_" + to;
  }
 /* componentDidUpdate = (prevProps) => {
    if(this.props.audit.hasError  === 401 ){
     forceLogout();
    }
    else if(this.props.audit.hasError){
      this.setState({refresh_list : false});
      window.alert(msg.api_error);
    }
    if(this.props.auditResult.auditResult )
    this.setState({ audit_log: this.props.auditResult.auditResult.data,audit_log_length:this.props.auditResult.auditResult.total_records });

     if(this.props.audit.audit && this.props.audit.audit !== prevProps.audit.audit)
        this.setState({audit_log : this.props.audit.audit.data,audit_log_length :  this.props.audit.audit.total_records});
   
  }*/
 /* getAuditData = (page) => {
    this.props.getAudit(c.ROOT_URL +"/getAuditLogs?page_limit=8&page_number="+page);
  }*/
  //calls whenever page num is changed, this updated pageNum state with new page and calls api 
  handleChangePage = (event, newPage) => {
    let recordsPerPage = this.state.recordsPerPage;
    this.setState({ startRecordNum: recordsPerPage * newPage });
    this.setState({ audit_filter: {...this.state.audit_filter,pageNum: newPage} }, () => {
       this.fetchResults(this.state.audit_filter);
    });
  };
  //calls whenever any filter field is changed
  handleChange = (event) => {
    this.setState({is_changed : true});
    const {name, value} = event.target;
    this.setState({ 
        audit_filter: {
            ...this.state.audit_filter,
            [name]: value
        } 
    });
    //debouncing logic for searchbar, call api only if difference in user input is 500 ms
    if(event.target.name === "search"){
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.findData("suggest");
      }, 500); 
    }
  };
  //calls when apply button is clicked. 
  handleSubmit = () => {
  if(this.state.is_changed){
    this.setState({ audit_filter: {...this.state.audit_filter, pageNum: 0} }, () => {
      this.fetchResults(this.state.audit_filter);
   });
  }
  };
 //calls when cancel button is clicked. 
  cancelFilter = (modal) => {
    localStorage.removeItem("audit_filter");
    //if cross icon in searchbar is cliked
    if(modal && modal === 'search')
    {
      this.setState({ audit_filter: {pageNum: 0,search: ""},suggest_box:[],is_search:false }, () => {
        this.fetchResults(this.state.audit_filter);
     });
     }
    else{
      this.setState(
        { audit_filter : {user_name : "",action: "",fromDate: this.setAuditDate().split("_")[0],toDate: this.setAuditDate().split("_")[1],pageNum: 0,search: "",sort_key: "Updated on",sort_order: false},
          is_changed : false,
          suggest_box : [],
      },
        () => {
          this.fetchResults(this.state.audit_filter);
        }
      );
    } 
  };
  //api call to backend
  fetchResults =  (audit_filter) => {
    //turn ON overlay loader
    this.setState({refresh_list : true});
    let from_date = audit_filter.fromDate ? f.dateToUtc(audit_filter.fromDate,'from') : "";
    let to_date = audit_filter.toDate ? f.dateToUtc(audit_filter.toDate,'to') : "";
    var query = {
      user_name: audit_filter.user_name && audit_filter.user_name,
      action_name: audit_filter.action && audit_filter.action.replaceAll('_', " "),
      from_date: from_date,
      to_date: to_date,
      search_string : audit_filter.search && audit_filter.search,
      sorting: {
        sort_column: audit_filter.sort_key && audit_filter.sort_key,
        is_asc: audit_filter.sort_order && audit_filter.sort_order,
      }
    }
    api.fetch_results_audit(query,audit_filter.pageNum,this.state.recordsPerPage).then((response) => {
      const data = response.data.data;
      if (response.status === 200) {
        const filteredData = data.map((dt) => {
          return {
            ...dt,
          };
        });
      localStorage.setItem("audit_filter",JSON.stringify(audit_filter));
      this.setState({ audit_log: filteredData,refresh_list: false,isLoading: false });
      if (response.data.total_records !== "")
              this.setState({ audit_log_length: parseInt(response.data.total_records) });
      }
    }).catch((error) => {
      if(error.response.status === 401){
       forceLogout();
      }
      else{
        this.setState({refresh_list : false});
        window.alert(msg.api_error);
      }
     
    });
  }
  findData = (modal, search_val) => {
    /*================alters table data based on searched text==========*/
    if (modal === "results") {
      this.setState({is_search: true,suggest_box : []});
        this.setState({ audit_filter: {...this.state.audit_filter,search : search_val, pageNum: 0} }, () => {
          this.fetchResults(this.state.audit_filter);
       });
    } else {
      /*=================displays suggestion box based on searched txt==============*/
        var suggest = [];
        let query = {
          search_string : this.state.audit_filter.search
        }
        if(this.state.audit_filter.search.length > 2){
        this.setState({is_search: false});
        api.search_suggest_results_audit(query).then((response) => {
          if(response.status === 200) {
            response.data.data && response.data.data.map((data,i) => {
              suggest.push(data);
            })
            this.setState({ suggest_box: suggest });
          }
        }).catch((error) => {
          if(error.response.status === 401){
            forceLogout();
          }else{
            this.setState({refresh_list : false});
             window.alert(msg.api_error);
          }
          });
        }else{
            suggest = [];
            this.setState({suggest_box : suggest,is_search: true});
          }
      }
  };
  //sorts table based table header 
  sort = (key) => {
    var sort_order = "";
    let table_head = [...this.state.tableHeadData];
    for (var i = 0; i < table_head.length; i++) {
      if (key.replace("_", " ") === table_head[i].name) {
        table_head[i].order = !table_head[i].order;
        sort_order = table_head[i].order;
        table_head[i].icon = true;
      } else table_head[i].icon = false;
    }
    this.setState({tableHeadData : table_head, audit_filter: {...this.state.audit_filter,sort_order: sort_order, sort_key:key,pageNum: 0} }, () => {
      this.fetchResults(this.state.audit_filter);
   });
  };
 //takes to doc details screen
  goToIntervention = ( doc_id) => {
    //making it false for the purpose of retaining filter from doc mgmt screen and doc details
    p.isFilter.isFilter = false;
    localStorage.setItem('is_filter',JSON.stringify(p.isFilter));
    localStorage.setItem(c.DOC_ID, doc_id );
    var _props = this.props;
    _props.handlePageChange(c.PAGE_MANUAL_REVIEW);
    _props.history.push(`${c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}?doc_id=${localStorage.getItem(c.DOC_ID)}`);
    localStorage.removeItem(c.PREV_ROUTE);
  };
   //API call for export as csv. Fetches entire data without pagination but with applied filters
   generateReport = () => {
    this.setState({refresh_list : true});
    let audit_filter = this.state.audit_filter;
    let from_date = audit_filter.fromDate ? f.dateToUtc(audit_filter.fromDate,'from') : "";
    let to_date = audit_filter.toDate ? f.dateToUtc(audit_filter.toDate,'to') : "";
    var query = {
      user_name: audit_filter.user_name && audit_filter.user_name,
      action_name: audit_filter.action && audit_filter.action.replaceAll('_', " "),
      from_date: from_date,
      to_date: to_date,
      search_string : audit_filter.search && audit_filter.search,
      sorting: {
        sort_column: audit_filter.sort_key && audit_filter.sort_key,
        is_asc: audit_filter.sort_order && audit_filter.sort_order,
      }
    }
    api.fetch_results_audit(query,'0','5000').then((response) => {
    const data = response.data.data;
      if (response.status === 200) {
      this.setState({ audit_log_export: data,refresh_list: false,isLoading: false },() => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });

      }
    }).catch((error) => {
      if(error.response.status === 401){
       forceLogout();
      }
      else{
        this.setState({refresh_list : false});
        window.alert(msg.api_error);
      }
     
    });
  }
  render() {
    return (
      <>
        <Navbar
          handlePageChange={this.handlePageChange}
          changeCurrentPage={(page) => this.setState({ currentPage: page })}
        />
          {this.state.isLoading ? 
          <CircularProgress  style={{ color: "grey", margin: "40vh auto" }} />
        :
        <>
         { this.state.refresh_list &&
          <CircularProgress size ={30} className = "common-spinner"
         />}
        <div className= {this.state.refresh_list ? "block-area-common" : ""}>
           <div className = "audit-log">
          <div className="titlebox audit-flex">
            <p className="page-title">Audit</p>
            <ExportAudit 
            audit_data  = {this.state.audit_log_export}
            audit_filter = {this.state.audit_filter}
            generateReport = {this.generateReport}
            csvLinkEl = {this.csvLinkEl}
            />
          </div>
          <div className = 'audit-flex'>
            <Filter
             handleChange={this.handleChange}
             handleSubmit={this.handleSubmit}
             cancelFilter={this.cancelFilter}
             audit_filter = {this.state.audit_filter}
              />
            <div>
            <SearchBar 
             //  search={this.state.search}
               handleChange={this.handleChange}
               findData={this.findData}
               suggest_box={this.state.suggest_box}
               cancelFilter={this.cancelFilter}
               is_search={this.state.is_search}
               search = {this.state.audit_filter.search}
          />
            </div>
          </div>

          <div>
          <TableContainer component={Paper} className = 'full-table'>
              <Table stickyHeader aria-label = 'customized table' >
                <TableHeader
                  sort={this.sort}
                  tableHeadData={this.state.tableHeadData}
                  audit_filter = {this.state.audit_filter}
                />
                <TableData
                  audit_data = {this.state.audit_log && this.state.audit_log}
                  goToIntervention = {this.goToIntervention}
                />
              </Table>
               {this.state.audit_log_length === 0 && <DialogModel /> }
            </TableContainer>
            <TablePage
               count={this.state.audit_log_length && this.state.audit_log_length}
               recordsPerPage={this.state.recordsPerPage}
               pageNum={this.state.audit_filter.pageNum}
               handleChangePage={this.handleChangePage}
               refresh_list={this.state.refresh_list}
            />
          </div>
          </div>
          </div>
        </>
          }
      </>
    );
  }
}
//used redux to call /getAuditLogs API on load. It is no more required. /getAuditLogsByFilter is being used to fetch data onload as well as when any filter is applied
/*const mapStateToProps = (state) => {
 return {
    audit: state.audit, 
    auditResult : state.auditResult
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
     getAudit: (url) => dispatch(getAudit(url)),
     auditResult: (url,query) => dispatch(auditResult(url,query))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);*/

export default AuditLog;

