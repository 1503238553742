import React from 'react';
import * as c from '../../utils/constants/constants';

/*This screen is called when user logs out or session inactivated or session expired or whenever tokens ahs to be blacklisted.
With the help of the constant c.LOGOUT stored in local storage, SSO APP url is called. SSO app takes care of blacklisting the tokens in firebase*/
class SSORedirect extends React.Component{
    componentDidMount = () => {
        var sso_app = c.SSO_APP;
       if(localStorage.getItem(c.SSO_ID)){
        if(localStorage.getItem(c.LOGOUT) === 'inactive') 
            window.location = sso_app+'?logout_inactive_t_id='+localStorage.getItem(c.SSO_ID);
        else if(localStorage.getItem(c.LOGOUT) === 'expired')
            window.location = sso_app+'?logout_expired_t_id='+localStorage.getItem(c.SSO_ID);
        else
            window.location = sso_app+'?logout_t_id='+localStorage.getItem(c.SSO_ID);
        }else
            window.location = sso_app;
        localStorage.clear();
      
    }
    render(){
        return(
            <>
        
            </>
        )
    }
}

export default SSORedirect;