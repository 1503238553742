import React, { useState } from "react";
import { useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogTitle,
  TextareaAutosize,
  Divider,
} from "@mui/material";
import DialogModal from "../../../../components/DialogModel/DialogModel";
import { useHistory } from "react-router-dom";
import * as c from "../../../../utils/constants/constants";
import * as f from '../../../../commonFunctions/commonFunctions';

const DocListTableMH = (props) => {
  const history = useHistory();
  const docs = useSelector((state) => state.docstuck.docstuck.data)
  const [open, setOpen] = useState(false);
  const [doc_id, setDoc_id] = useState("");
  const [user_comment, setUserComment] = useState("");

  const changeStatus = (id, key) => {
    setOpen(true);
    setDoc_id(id);
  };
  const closeDialog = () => {
    setOpen(false);
    setUserComment("");
  };
  const handleChange = (e) => {
    setUserComment(e.target.value);
  };
  const goToDocInfo = (id) => {
    history.push({
      pathname: c.PAGE_DOC_INFO,
      search: '?'+id 
    }); 
  }
  return (
    <>
      <div className="table-format">
        {docs && docs.length === 0 ?
        <DialogModal />
         :
         <>
         <div style = {{textAlign : "left"}}>Total no. of documents: {docs && docs.length}</div>
         <br />
        <table>
          <tr>
            <th>Doc Id</th>
            <th>Doc Name</th>
            <th>Received on</th>
            <th>Status</th>
            <th>Update</th>
          </tr>
          {docs && docs.length >0 &&
            docs.map((doc, i) => (
              <tr>
                <td><div className = "doc-name-focus" onClick = {() => goToDocInfo(doc.ID)}>{doc.ID}</div></td>
                <td>{doc.Name}</td>
                <td> {f.utcToLocal(
                    doc.Received_Date + " " + doc.Received_Time + " UTC",
                    "full_sec"
                  )}</td>
                 <td>
                   <div>
                     <div>{doc.Status}</div>
                     <div style = {{color : "grey"}}>{f.timeDifference(
                    doc.Received_Date + " " + doc.Received_Time + " UTC"
                  )}</div>
                    </div>
                  </td>
                <td>
                  <Button
                    variant="outlined"
                    className = "mui-buttons-custom"
                    onClick={() => changeStatus(doc.ID, i)}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))}
        </table>
        </>}
      </div>
      <Dialog open={open} onClose={() => closeDialog()}>
        <DialogTitle className="sa-dia-title">
          <div>Doc Id: {doc_id}</div>
        </DialogTitle>
        <Divider />
        <div className="sa-cmnt-dialog">
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Provide Comments here..."
            minRows={5}
            style={{ width: 500 }}
            name = "user_comment"
            value = {user_comment}
            onChange = {handleChange}
          />
          <div className="sa-action-section">
            <Button 
            className = {user_comment ? "mui-buttons-custom" : "mui-buttons-custom-disabled"}
            onClick = {() => props.updateDocStatus(doc_id,user_comment)}
            disabled = {user_comment ? false : true}
            variant="outlined">Change Status to Intervention Required</Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default DocListTableMH;
