import React from "react";
import './Reports.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MonthlyReport from "./MonthlyReport";
import DailyReport from "./DailyReport";
import Filter from "./Filter/Filter";
import Navbar from "../../components/Navbar/Navbar";
import api from "../../utils/API/api";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from '@mui/icons-material/Launch';
import MonthlyReportExport from "./Export/MonthlyExport";
import DailyReportExport from "./Export/DailyExport";
import ReactToPrint from "react-to-print";
import * as p from "../../utils/parameter/parameter";
import * as c from "../../utils/constants/constants";
import * as f from "../../commonFunctions/commonFunctions";
import rd_logo from "../../utils/images/rd_logo.svg";
import rd_name_nav from "../../utils/images/rd_name_nav.svg";
import Mh_logo from "../../utils/images/Mh_logo.png";
import * as dayjs from "dayjs";
import { Popover } from "@mui/material";
import HourlyReportExport from "./Export/HourlyExport";
import { CircularProgress} from "@mui/material";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.buttonClick = this.buttonClick.bind(this);
    this.state = {
      selectedUsageTab: "monthly",
      rp_metrics: [],
      loader: {
        hourlyLocationStatics: true,
        dailyStatics: true,
        locationStaticsByDate: true,
        rp_graph: true,
        avg_pg_category: true,
        avg_pg_loc: true,
        pageLoader: false
      },
    };

    this.CustomDateRangeExportElement = React.createRef();
    this.HourlyExportElement = React.createRef();
  }

  buttonClick(val) {
    this.setState({
      selectedUsageTab: val
    }, () => {
      let query_data;
      if (this.state.selectedUsageTab == "monthly") {
        let date = new Date();
        query_data = {
          year: this.state.year || date.getFullYear(),
          month: this.state.month || date.getMonth(),
        }
      } else {
        query_data = {
          from_date: dayjs().subtract(3, "month").format("MM-DD-YYYY"),
          to_date: dayjs().format("MM-DD-YYYY")
        }
      }
      this.getReportData(query_data)
    });

  }

  compare = (a, b) => {
    if (a.Doc_Count < b.Doc_Count) {
      return 1;
    }
    if (a.Doc_Count > b.Doc_Count) {
      return -1;
    }
    return 0;
  };

  getActiveCatData = (data) => {
    var sorted_cats = [];
    if (data) {
      var copy_cats = [...data];
      sorted_cats = copy_cats.sort(this.compare);
    }
    return sorted_cats;
  };
  getActiveLocData = (data) => {
    var sorted_locs = "";
    if (data) {
      var copy_locs = [...data];
      sorted_locs = copy_locs.sort(this.compare);
    }
    return sorted_locs;
  };

  findData = (search, modal) => {
    if (search.length > 0) {
      let copy =
        modal === "location"
          ? [...this.state.avg_pg_loc]
          : [...this.state.avg_pg_category];
      let searched = [];
      copy.forEach((data, i) => {
        if (data.Name.toString().toUpperCase().includes(search.toUpperCase()))
          searched.push(data);
      });
      if (modal === "location") this.setState({ search_result_loc: searched });
      else this.setState({ search_result_cat: searched });
    }
  };

  getMonthlyReportData(query_data) {
    let query = {
      month: query_data?.month || query_data?.month == 0
        ? parseInt(query_data?.month) + 1
        : parseInt(this.state.month) + 1,
      year: query_data?.year
        ? parseInt(query_data?.year)
        : parseInt(this.state.year),
    };
    this.setState({
      loader: {
        ...this.state.loader,
        avg_pg_category: true,
        avg_pg_loc: true,
        rp_graph: true
      }
    });

    var d = new Date();
    var n = d.getMonth();
    var max_yr = d.getFullYear();
    p.rp_month.month = query.month - 1;
    p.rp_month.year = query.year
    p.rp_month.current =
      n === p.rp_month.month && max_yr === p.rp_month.year ? true : false;
    localStorage.setItem(c.RP_MONTH, JSON.stringify(p.rp_month));

    let finalQuery = {
      from_date: dayjs(`${query.month}-01-${query.year}`).startOf("month").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"),
      to_date: dayjs(`${query.month}-01-${query.year}`).endOf("month").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"),
      time_zone: f.getTimeZone()
    }
    api.getRpMetrics(finalQuery)
      .then(res => {
        this.setState({
          rp_metrics: res.data?.data,
          loader: {
            ...this.state.loader,
          }
        })
      })
      .catch(err => {

      });
    api.avgPageByCategory(finalQuery)
      .then(res => {
        let { data } = res.data || {}
        console.log(data)
        this.setState({
          avg_pg_category: data,
          sorted_cats: this.getActiveCatData(data),
          loader: {
            ...this.state.loader,
            avg_pg_category: false,
          }
        })
      })
      .catch(err => {

      });
    api.avgPageByLocation(finalQuery)
      .then(res => {
        let { data } = res.data || {}
        console.log(data)
        this.setState({
          avg_pg_loc: data,
          sorted_locs: this.getActiveCatData(data),
          loader: {
            ...this.state.loader,
            avg_pg_loc: false
          }
        })
      })
      .catch(err => {

      });
    api.rpGraphData(finalQuery)
      .then(res => {
        let { data } = res.data || {};
        this.setState({
          rp_graph: data,
          loader: {
            ...this.state.loader,
            rp_graph: false
          }
        })
      })
      .catch(err => {

      });



  }

  formatDate(date, format) {
    date = new Date(date);
    if (format == "DD-MM-YYYY") return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`

  }

  getDailyReportData(query_data) {
    let from_date = query_data?.from_date;
    let to_date = query_data?.to_date;
    let query = {
      from_date: dayjs(from_date).startOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"),
      to_date: dayjs(to_date).endOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"),
      time_zone: f.getTimeZone(),
    }
    this.setState({
      selectedDateRange: {
        to_date: dayjs().format("MM-DD-YYYY"), 
        from_date: dayjs().subtract(15, 'day').format("MM-DD-YYYY")
      },
      loader: {
        ...this.state.loader,
        dailyStatics: true,
        locationStaticsByDate: true,
        hourlyLocationStatics: true
      },
    });
    api.dailyStatics({
      ...query
    })
      .then(res => {
        let { data = [] } = res.data || {};
        let date = (data.date || []).map(m => m);
        this.setState({
          selectedDate: dayjs(date[date.length - 1]).format("MM-DD-YYYY"),
          dailyStatics: { categories: date, data: data["document count"] },
          loader: {
            ...this.state.loader,
            dailyStatics: false
          }
        });
        this.getHourlyData({ from_date:  dayjs(date[date.length - 1]).format("MM-DD-YYYY")});
      })
    this.getLocationData({time_zone: f.getTimeZone(), to_date: dayjs().endOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"), from_date: dayjs().subtract(15, 'day').startOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss")});
    
  }

  formatLocationData(data) {
    let dates = [];
     let locationData = data.reduce((acc, m) => {
        if(!acc.includes(m.location)) {
      acc.push(m.location);
    }
       
       if(!dates.includes(m.date)) {
      dates.push(m.date);
    }
    return acc;
    }, []).reduce((acc, m) => {
      data.forEach(n => {
        if(n.location == m) {
          if(!acc[m]) acc[m] = {counts: []};
        acc[m].counts.push(n.count)
        }
      });
        
      return acc;
    }, {})
     return {
    
      location: locationData,
       dates: dates
  };
  }

  getLocationData(query) {
    this.setState({
      loader: {
        ...this.state.loader,
        locationStaticsByDate: true
      },
    });
    api.locationStaticsByDate(query)
      .then(res => {
        let { data = [] } = res.data || {};
        let finalData = this.formatLocationData(data);
        console.log(finalData)
        this.setState({
          locationStaticsByDate: {
            categories: finalData.dates,
            data: finalData.location
          },
          loader: {
            ...this.state.loader,
            locationStaticsByDate: false
          }
        });
      });
  }

  getHourlyData({ from_date, locations = [] }) {
    this.setState({
      loader: {
        ...this.state.loader,
        hourlyLocationStatics: true,
      }
    });
    api.hourlyLocationStatics({
      from_date: dayjs(from_date).startOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss")|| "",
      location_ids: locations,
      time_zone: f.getTimeZone()
    })
      .then(res => {
        let { hour = [], location = {} } = res.data?.data || {};
        let categories = hour.map((m, i) => (i == 0 ? `${m > 9 ? m : ("0" + m)}:00-${(m + 1) > 9 ? (m + 1) : ("0" + (m + 1))}:00` : `${m > 9 ? m : ("0" + m)}:00-${(m + 1) > 9 ? (m + 1) : ("0" + (m + 1))}:00`))
        this.setState({
          hourlyLocationStatics: { categories: categories, data: location },
          loader: {
            ...this.state.loader,
            hourlyLocationStatics: false
          }
        });
      })
  }

  handleChartClick(selected) {
    this.getHourlyData({ from_date: selected });
    this.setState({
      selectedDate: selected,
    });
  }
  handeDateChange(from_date, to_date) {
    this.getLocationData({time_zone: f.getTimeZone(), to_date: dayjs(to_date).endOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"), from_date: dayjs(from_date).startOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss")});
    this.setState({
      selectedDateRange: {
        from_date,
        to_date
      }
    });
  }

  getReportData(query_data) {
    this.setState({
      ...query_data
    })
    if (this.state.selectedUsageTab == "monthly") {
      this.getMonthlyReportData(query_data)
    } else {
      this.getDailyReportData(query_data)
    }
  }

  generateCustomDateRangeReport() {
    this.setState({
      loader: {
        ...this.state.loader,
        pageLoader: true
      }
    });
    let query = {
      from_date: dayjs(this.state.selectedDateRange.from_date).startOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"),
      to_date: dayjs(this.state.selectedDateRange.to_date).endOf("day").add(+7, "hour").format("MM-DD-YYYY HH:mm:ss"),
      time_zone: f.getTimeZone()
    }
    api
    .hourlyStatisticsByDate(query)
    .then((res) => {
      let {data} = res.data || {};
      let {dailyStatics, locationStaticsByDate} = this.state;
      console.log(dailyStatics)

      let startIndex = dailyStatics.categories.findIndex(m => m == this.state.selectedDateRange.from_date);
      let endIndex = dailyStatics.categories.findIndex(m => m == this.state.selectedDateRange.to_date);
      this.setState({
        customDateRangeExportData: {
          dailyStatics: {
            categories: dailyStatics.categories.slice(startIndex, endIndex + 1),
            data: dailyStatics.data.slice(startIndex, endIndex + 1),
          },
          locationStaticsByDate,
          hourlyStatisticsByDate: {
            categories: data.hour,
            data: data.dates
          }
        }
        
      }, () => {
        setTimeout(() => {
          this.CustomDateRangeExportElement.current.link.click();
          this.setState({
            loader: {
              ...this.state.loader,
              pageLoader: false
            },
            anchorEl: null
          });
        }, 1000);
      });
    })
  }
  generateHourlyReportExport() {
      this.setState({
        hourlyExportData: this.state.hourlyLocationStatics,
        loader: {
          ...this.state.loader,
          pageLoader: true
        },
      }, () => {
        setTimeout(() => {
          this.HourlyExportElement.current.link.click();
          this.setState({
            loader: {
              ...this.state.loader,
              pageLoader: false
            },
            anchorEl: null
          })
        }, 1000);
      });
  }


  componentDidMount() {
    let date = new Date();
    this.setState({
      year: date.getFullYear(), month: date.getMonth()
    })
    this.getMonthlyReportData({ year: date.getFullYear(), month: date.getMonth() }, "mount");
  }

  getPopoverContent() {
    return (
      <div className="export-popover-menu">
        <div className="export-popover-menu-item">
          <DailyReportExport
              selectedFilters={{ fromDate: this.state.selectedDateRange?.from_date, toDate: this.state.selectedDateRange?.to_date }}
              data={this.state.customDateRangeExportData}
              generateCustomDateRangeReport = {this.generateCustomDateRangeReport.bind(this)}
              csvLinkEl = {this.CustomDateRangeExportElement}
            /> 
        </div>
        <div className="export-popover-menu-item">
          <HourlyReportExport
              selectedFilters={{ selectedDate: this.state.selectedDate }}
              data={this.state.hourlyExportData}
              generateHourlyReportExport = {this.generateHourlyReportExport.bind(this)}
              csvLinkEl = {this.HourlyExportElement}
            /> 
        </div>
      </div>
    )
  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <Navbar
          handlePageChange={this.handlePageChange}
          changeCurrentPage={(page) => this.setState({ currentPage: page })}
        />
        <div className="report-container">
          {this.state.loader.pageLoader ? <div className="report-page-loader">
            <CircularProgress style={{ color: "grey", margin: "100px auto" }} />
          </div> : null}
          <div className="header-container">
            <div className="header-left-portion">

              <div className="radio-btn-group">
                <div className={`radio-btn-item ${this.state.selectedUsageTab == "monthly" ? "radio-btn-selected" : ""}`} onClick={e => this.buttonClick("monthly")}>Monthly</div>
                <div className={`radio-btn-item ${this.state.selectedUsageTab == "custom_range" ? "radio-btn-selected" : ""}`} onClick={e => this.buttonClick("custom_range")}>Custom Date Range</div>
                
              </div>
            </div>
              <div className="header-right-portion">
              <Filter selectedUsageTab={this.state.selectedUsageTab} getReportData={this.getReportData.bind(this)} />
              <div className="icon-text">
                <div className="rp-print-section">

                  {this.state.selectedUsageTab == "monthly" && this.state.rp_metrics?.length && this.state.avg_pg_category?.length && this.state.avg_pg_loc?.length ?
                    <>
                      <LaunchIcon style={{ marginRight: "5px" }} />
                      <MonthlyReportExport
                        avg_pg_category={this.state.avg_pg_category}
                        avg_pg_loc={this.state.avg_pg_loc}
                        rp_metrics={this.state.rp_metrics}
                      />
                    </> : (
                      this.state.selectedUsageTab == "custom_range" ? <>
                        <div style={{display: "flex", alignItems: "center", marginRight: "20px"}} onClick={e => {
                          this.setState({
                            anchorEl: e.currentTarget
                          })
                        }}>
                          <LaunchIcon style={{ marginRight: "5px" }} />
                            <div>Export</div>
                        </div>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={this.state.anchorEl}
                          onClose={e => {
                            this.setState({
                              anchorEl: null
                            })
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {this.getPopoverContent()}
                        </Popover>
                      </> : null)}
                </div>
                {/*=============PRINT VIEW===============*/}
                {this.state.selectedUsageTab == "monthly" ? <ReactToPrint
                  trigger={() => {
                    return (
                      <a href="#" style={{ textDecoration: "none" }}>
                        <div className="rp-print-section">
                          <PrintIcon style={{ marginRight: "5px" }} />
                          <div className="rp-print-button">Print</div>
                        </div>
                      </a>
                    );
                  }}
                  content={() => this.componentRef}
                /> : null}
              </div>
              </div>
          </div>
          <div ref={(el) => (this.componentRef = el)}>
            {/*=============PRINT VIEW===============*/}
            <div className="report-print-content">
              <div className="rp-print-header">
                <div className="flex-style">
                  <img
                    style={{ width: "30px", marginRight: "3px" }}
                    src={rd_logo}
                    alt="rd_logo"
                  />
                  <img
                    className="rd-nav"
                    style={{ width: "90px" }}
                    src={rd_name_nav}
                    alt="rd_name"
                  />
                </div>
                <img
                  className="rd-nav"
                  style={{ width: "100px" }}
                  src={Mh_logo}
                  alt="mh-logo"
                />
              </div>
              <div className="report-print-header-content">
                <div className="rp-flex">
                  <div className="flex-space">
                    <div className="titlebox">
                      <p className="page-title">Monthly Report</p>
                    </div>
                  </div>
                </div>
                <div className="rp-curr-month-disp">

                  {c.MONTH_ARR[JSON.parse(localStorage.getItem(c.RP_MONTH))?.month]}
                  , {JSON.parse(localStorage.getItem(c.RP_MONTH))?.year}

                  {JSON.parse(localStorage.getItem(c.RP_MONTH))?.current && (
                    <span style={{ marginLeft: "5px" }}>
                      (to date)
                    </span>
                  )}

                </div>
                <div className="rp-print-name">
                  Generated By: {localStorage.getItem(c.USER_NAME)}. Date/Time:{" "}
                  {f.currDate() + ', ' + f.currTime()}
                </div>
              </div>
            </div>

            {/*=============PRINT VIEW===============*/}
            {this.state.selectedUsageTab == "monthly" ?
              <MonthlyReport
                all_state={this.state}
                findData={this.findData}
                rp_graph={this.state.rp_graph}
                rp_metrics={this.state.rp_metrics}
                avg_pg_category={this.state.avg_pg_category}
                avg_pg_loc={this.state.avg_pg_loc}
                sorted_cats={this.state.sorted_cats}
                sorted_locs={this.state.sorted_locs}
                loader={this.state.loader}
                search_result_loc={this.state.search_result_loc}
                search_result_cat={this.state.search_result_cat}
              /> : <DailyReport
                dailyStatics={this.state.dailyStatics}
                hourlyLocationStatics={this.state.hourlyLocationStatics}
                locationStaticsByDate={this.state.locationStaticsByDate}
                loader={this.state.loader}
                selectedDate={this.state.selectedDate}
                selectedDateRange={this.state.selectedDateRange}
                handleChartClick={this.handleChartClick.bind(this)}
                handeDateChange={this.handeDateChange.bind(this)}
              />
            }
          </div>
        </div>
      </>
    )
  }
}
export default Reports;