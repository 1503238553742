import React from "react";
import { Paper, Divider,IconButton,CircularProgress } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorIcon from "@material-ui/icons/Error";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import * as f from "../../../commonFunctions/commonFunctions";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {CustomTooltip} from "../../../components/CustomTooltip/CustomTooltip";
import ComputerIcon from '@mui/icons-material/Computer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import * as c from "../../../utils/constants/constants";

/* get the response from API call made in dashboard.js and display the data*/
class RecentDocs extends React.Component {
 
  render() {
    return (
      <>
        <div className="flex-style-paper">
          <Paper elevation={3} className="paper-style">
            <div className="paper-title">
              <div
                className="flex-margin"
                style={{ margin: "0px", padding: "10px" ,alignItems : 'center'}}
              >
                <div className = "status-icon">
                <p className="actiontitle title" >
                  Action Items Assigned
                </p>
                { this.props.refresh_action_items &&
                <CircularProgress size ={15} className = "mini-spinner"
                 />}
               </div>
                <div className = {this.props.refresh_action_items ? "block-area-common" : ""}>
                  <IconButton
                    disabled={
                      this.props.action_page_num === 0
                        ? true
                        : false
                    }
                    onClick={() => this.props.changePage("prev", "action")}
                  >
                    <ChevronLeftIcon className="icon-action" />
                  </IconButton>
                  <IconButton
                    disabled={
                      ((this.props.action_item_length / 15) - Math.floor(this.props.action_item_length / 15)) !== 0 ?
                      (this.props.action_page_num ===
                        Math.floor(this.props.action_item_length / 15))
                        : 
                      (this.props.action_page_num  ===
                      (this.props.action_item_length / 15) -1)
                      
                        ? true
                        : false
                    }
                    onClick={() => this.props.changePage("next", "action")}
                  >
                    <ChevronRightIcon className="icon-action" />
                  </IconButton>
                </div>
              </div>
            </div>
            <Divider />
            <div className="doc-view">
              {this.props.action_item &&
                this.props.action_item.map((data, i) => (
                  <div key = {i}
                    onClick={() =>
                      this.props.goToIntervention("review", data.id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex-margin">
                    <div className="status-icon">
                        <VisibilityIcon
                          style={{ color: "#f58018", fontSize: "1em" }}
                        />
                        <p className="mini-hyperlink">
                          {data.status}
                        </p>
                      </div>
                     
                      <p className="doc-cat">{data.category}</p>
                    </div>
                    <div className="flex-margin">
                      {data.name.length > 25 ? (
                        <CustomTooltip title={data.name} placement="top-end">
                          <p className="doc-name-focus">
                            {data.name.length > 25
                              ? data.name.substr(0, 15) +
                                "..." +
                                data.name.substr(
                                  data.name.length - 7,
                                  data.name.length
                                )
                              : data.name}
                          </p>
                        </CustomTooltip>
                      ) : (
                        <p className="doc-name-focus">{data.name}</p>
                      )}
                      <div className="icon-text">
                        <p
                          className="doc-cat"
                          style={{ marginLeft: "5px", color: "black" }}
                        >
                          {f.utcToLocal(
                            data.created_date + " " + data.created_time + " UTC"
                          )}
                        </p>
                      </div>
                    </div>

                    <Divider />
                  </div>
                ))}
            </div>
          </Paper>
          <Paper elevation={3} className="paper-style">
            <div className="paper-title">
              <div
                className="flex-margin"
                style={{ margin: "0px", padding: "10px",alignItems : 'center' }}
              >
                <p
                  className="worktitle title"
                  
                >
                  Recent Work Items
                </p>
                <div style = {{visibility : 'hidden'}}>
                <IconButton
                    disabled={
                      this.props.action_page_num === 0
                        ? true
                        : false
                    }
                    onClick={() => this.props.changePage("prev", "action")}
                  >
                    <ChevronLeftIcon className="icon-action" />
                  </IconButton>
                  <IconButton
                    disabled={
                      this.props.action_page_num ===
                      Math.floor(this.props.action_item_length / 15)
                        ? true
                        : false
                    }
                    onClick={() => this.props.changePage("next", "action")}
                  >
                    <ChevronRightIcon className="icon-action" />
                  </IconButton>
                </div>
              </div>
            </div>
            <Divider />
            <div className="doc-view">
              {this.props.recent_docs &&
                this.props.recent_docs.map((data, i) => (
                  <div key = {i}
                    onClick={() =>
                      this.props.goToIntervention("review", data.id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex-margin">
                      <div className="status-icon">
                        {data.status === c.status.processed ? (
                          <CheckIcon
                            style={{ color: "#828282", fontSize: "1em" }}
                          />
                        ) : data.status === c.status.intervention_rqd ? (
                          <VisibilityIcon
                            style={{ color: "#f58018", fontSize: "1em" }}
                          />
                        ) : data.status === c.status.queued ? (
                          <HistoryOutlinedIcon
                            style={{ color: "#828282", fontSize: "1em" }}
                          />
                        ) : data.status === c.status.in_progress ? (
                          <AutorenewOutlinedIcon
                            style={{ color: "#828282", fontSize: "1em" }}
                          />
                        ) : data.status === c.status.completed ? (
                          <DoneAllIcon
                            style={{ color: "#828282", fontSize: "1em" }}
                          />
                        ) : data.status === c.status.failed ? (
                          <ErrorIcon
                            style={{ color: "#EB5757", fontSize: "1em" }}
                          />
                        ) : data.status === c.status.uploading ? (
                          <CloudUploadIcon
                            style={{ color: "#828282", fontSize: "1em" }}
                          />
                        ): data.status === c.status.uploaded ? (
                          <ComputerIcon
                            style={{ color: "#828282", fontSize: "1em" }}
                          />
                        ): data.status === c.status.completing ? (
                          <TaskAltIcon
                            style={{ color: "#828282", fontSize: "1em" }}
                          />
                        ): null}
                        <p className="mini-hyperlink">
                          {data.status}
                        </p>
                      </div>
                      <p className="doc-cat">{data.category}</p>
                    </div>
                    <div className="flex-margin">
                      {data.name.length > 25 ? (
                        <CustomTooltip title={data.name} placement="top-end">
                          <p className="doc-name-focus">
                            {data.name.length > 25
                              ? data.name.substr(0, 15) +
                                "..." +
                                data.name.substr(
                                  data.name.length - 7,
                                  data.name.length
                                )
                              : data.name}
                          </p>
                        </CustomTooltip>
                      ) : (
                        <p className="doc-name-focus">{data.name}</p>
                      )}
                      <div className="icon-text">
                        <p
                          className="doc-cat"
                          style={{ marginLeft: "5px", color: "black" }}
                        >
                          {f.utcToLocal(
                            data.created_date + " " + data.created_time + " UTC"
                          )}
                        </p>
                      </div>
                    </div>

                    <Divider />
                  </div>
                ))}
            </div>
          </Paper>
        </div>
      </>
    );
  }
}


export default RecentDocs;
