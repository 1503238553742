import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  CircularProgress,
  Checkbox
} from "@mui/material";
import "./Encounters.css";
import DialogModel from "../../../../../../components/DialogModel/DialogModel";
import * as c from "../../../../../../utils/constants/constants";
import * as f from "../../../../../../commonFunctions/commonFunctions";

const Encounters = (props) => {
  const [encFilter, setencFilter] = useState({ fromDate: '', toDate: ""});
  const [selectedEnc, setselectedEnc] = useState([]); //stores selected encounters

  useEffect(() => { 
    /*save data on load if encounters is alreadty present for a document*/
   // setselectedEnc(props.all_data.Entity_Details.ehrDetails ? props.all_data.Entity_Details.ehrDetails.encounter : {});
   var selected_enc = [];
     props.all_data.Entity_Details.ehrDetails && Object.values(props.all_data.Entity_Details.ehrDetails.encounter).forEach((enc) => {
       selected_enc.push(enc);
   })
   setselectedEnc(selected_enc);
   if(JSON.parse(localStorage.getItem(c.ENC_DATA))){  
        setencFilter(JSON.parse(localStorage.getItem(c.ENC_DATA)));
    }else{
      setencFilter(prevState => {
        return { ...prevState, fromDate: setEncDate().split("_")[0], toDate: setEncDate().split("_")[1] }
      });
      var enc_filter = {
        fromDate : setEncDate().split("_")[0],
        toDate : setEncDate().split("_")[1]
      }
       props.patientEncounters(enc_filter);
    }
  }, []);

  const handleDate = (date, e) => {
    if (date === "from"){
      setencFilter(prevState => {
        return { ...prevState, fromDate:e.target.value }
      });
    } 
    else
    setencFilter(prevState => {
      return { ...prevState, toDate:e.target.value }
    });
  };
  const resetFilter = () => {
    setencFilter(prevState => {
      return { ...prevState, fromDate:"", toDate:"" }
    });
    var enc_filter = {
      fromDate : "",
      toDate :  ""
    }
    props.patientEncounters(enc_filter);
  };
  const handleSubmit = () => {
    props.patientEncounters(encFilter);
  };
  const setEncID = (event,data) => {
      const new_selected_enc = [];
      if(event.target.checked){
        /*Taking only the keys that are required to be displayed in main screen*/
        var new_obj = {
          "identifier" : data.identifier,
          "period" : data.period,
          "type" : data.type
        }
        new_selected_enc.push(new_obj);
      }else
       new_selected_enc.splice(new_selected_enc.indexOf(data),1);
      setselectedEnc(new_selected_enc);
  };
  /*encounter date filter by default set to fetch past 90 days record. start date: curr date - 90. end date: curr date*/
  const setEncDate = () => {
    let toDate = new Date();
    let fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 3);
    let from = `${fromDate.getFullYear()}-${(Number(fromDate.getMonth()) + 1) < 10 ? ("0" + (Number(fromDate.getMonth()) + 1)) : (Number(fromDate.getMonth()) + 1)}-${fromDate.getDate() < 10 ? ("0" + Number(fromDate.getDate())) : fromDate.getDate()}`;
    let to = `${toDate.getFullYear()}-${(Number(toDate.getMonth()) + 1) < 10 ? ("0" + (Number(toDate.getMonth()) + 1)) : (Number(toDate.getMonth()) + 1)}-${toDate.getDate() < 10 ? ("0" + Number(toDate.getDate())) : toDate.getDate()}`;
    return from + "_" + to;
  }
  return (
    <>
     {props.tabLoader && props.value === 1 &&(
                  <CircularProgress size={30} className="popup-spinner" />
                )}
  
      <div className={props.tabLoader ? "block-area-common" : ""}>
        <div className= "flex-style">
        <div>Encounters for {props.all_data.Entity_Details.patientMatch.official_name}.</div>
        {props.doc_level === "Encounter" && selectedEnc[0] && <div style = {{color: "#aa1572"}}>CSN: {selectedEnc[0].identifier}</div>}
        </div>
      {/*======Encounter filters=============*/}
      <div className="enc-filters">
        <div>Encounter Start Date: </div>
        <div className="enc-filter-fields">
          <form onChange={(e) => handleDate("from", e)}>
            <TextField
              variant="standard"
              style={{ width: "100%" }}
              id="fromDate"
              label="From"
              type="date"
              value={encFilter.fromDate}
              name="fromDate"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <form onChange={(e) => handleDate("to", e)}>
            <TextField
              variant="standard"
              style={{ width: "100%" }}
              id="toDate"
              label="To"
              type="date"
              value={encFilter.toDate}
              name="toDate"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </div>
        <div className="buttons-filter">
          <button className="apply-button" type="submit" onClick={handleSubmit}>
            <p className="apply-button-text">Apply</p>
          </button>
          <button
            className="reset-button"
            type="submit"
            onClick={resetFilter}
          >
            <p className="reset-button-text">Reset</p>
          </button>
        </div>
      </div>
      <br />
      {/*=========Encounter grid=============*/}
     <div className = "enc-data-section">
   
      <TableContainer component={Paper} className="enc-table">
        <Table stickyHeader aria-label="customized table">
          <TableHead className="thBorder">
            <TableRow>
            <TableCell className="tableHeadStyle">Select</TableCell>
              <TableCell className="tableHeadStyle">No.</TableCell>
              <TableCell className="tableHeadStyle">CSN</TableCell>
              <TableCell className="tableHeadStyle">Encounter Start</TableCell>
              <TableCell className="tableHeadStyle">Encounter End</TableCell>
              <TableCell className="tableHeadStyle">Status</TableCell>
              <TableCell className="tableHeadStyle">Encounter Name</TableCell>
              <TableCell className="tableHeadStyle">Location</TableCell>
              <TableCell className="tableHeadStyle">Provider</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.encData && 
              props.encData.map((data, i) => (
                <TableRow
                  key = {i}
                  className={props.doc_level === "Encounter" && selectedEnc[0] && selectedEnc[0].identifier === data.identifier ? "ehr-table-row-color" : "table-row"}>
                   <TableCell className="tableCellStyle" align="left">
                      <Checkbox
                      size="small"
                      checked= {props.doc_level === "Encounter" && selectedEnc[0] && selectedEnc[0].identifier === data.identifier ? true :false}
                      onChange={(event) =>setEncID(event,data)}
                      disabled = {props.doc_level !== "Encounter"}
                    />
                  </TableCell>
                  <TableCell className="tableCellStyle" align="left">
                    {i + 1}
                  </TableCell>
                  <TableCell className="tableCellStyle" align="left">
                   {data.identifier}
                  </TableCell>
                  <TableCell className="tableCellStyle" style = {{whiteSpace: "nowrap"}} align="left">
                    {data.period["start"] && f.formatDateEHR(data.period["start"])}
                  </TableCell>
                  <TableCell className="tableCellStyle" style = {{whiteSpace: "nowrap"}} align="left">
                    {data.period["end"] && f.formatDateEHR(data.period["end"])}
                  </TableCell>
                  <TableCell className="tableCellStyle" align="left">
                    {f.titleCase(data.status,"-")}
                  </TableCell>
                  <TableCell className="tableCellStyle" align="left">
                    {data.type.toString()}
                  </TableCell>
                  <TableCell className="tableCellStyle" align="left">
                    {data.location}
                  </TableCell>
                  <TableCell className="tableCellStyle" align="left">
                    {data.participant[0].individual &&
                      data.participant[0].individual.display}
                  </TableCell>
                 
                </TableRow>
              ))}
          </TableBody>  
        </Table>  
        {props.encData && props.encData.length === 0 && <DialogModel/>}
       
      </TableContainer>
     
      </div>
    
      {props.value === 1 && props.doc_level === "Encounter" && (
                    <div className="div-center">
                      <button
                        className="apply-button"
                        type="submit"
                        onClick={() => props.saveEHRData(selectedEnc,"encounter")}
                      >
                        <p className="apply-button-text">Done</p>
                      </button>
                      <button
                        className="reset-button"
                        type="submit"
                        onClick={() => props.fetchID("cancel")}
                      >
                        <p className="reset-button-text">Cancel</p>
                      </button>
                    </div>
                  )}
      </div>
    </>
  );
};

export default Encounters;
