import React,{useState} from "react";
import "./Flags.css";
import FlagList from "../../../components/FlagList/FlagList";

const Flags = (props) => {
  const [showFlags, setshowFlags] = useState(false);
  const showFlagList = () => {
    setshowFlags(!showFlags);
  };
  const updateFlags = (event,i,modal) => {
    setshowFlags(false)
    if(modal === "save")
      props.handleFlags(event,i,modal);
  };
  return(
    //this iteration is  only for the purpose of showing ellipsis. 
  /*  var flag_disp = [];
    this.props.flag_list && this.props.flag_list.map((fg,i) => {
      if(fg.enabled)
        return flag_disp.push(fg.tag_display_name);
    })
    var new_list  = flag_disp.join(', ');*/

    <>
    {/*block cursor action of flag component if any card is edited and not saved*/}

    <div className="fg-section">
        {!props.all_state.isDuplicateView ? <FlagList

            flag_list={props.flag_list}
            updateFlags={updateFlags}
            handleFlags={props.handleFlags}
            showFlagList = {showFlagList}
            showFlags = {showFlags}
            flag_glph = {props.flag_glph}
          /> : <div className="flags-selection-title">Flags</div>}
      <div className="fg-wrap">
        {props.flag_list && props.flag_list.map(
          (flag, i) =>
            flag.enabled && (
              <div key={i} className=" fg-each-chip">
                {flag.tag_display_name}
                {/*<span className = "fg-comma">,</span>*/}
              </div>
            )
            )}
           {/* <CustomTooltip title={new_list} placement="top-start">
            <span className="fg-array-ellipsis">{new_list}</span>
          </CustomTooltip>*/}
      </div>
    </div>
  
  </>
  )
}
export default Flags;
