import React from "react";
import "./HeaderSection.css";
import * as f from "../../../commonFunctions/commonFunctions";
import * as msg from "../../../utils/constants/messages";
import * as c from "../../../utils/constants/constants";
import HelpIcon from '@mui/icons-material/Help';
import HistoryIcon from '@mui/icons-material/History';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person'; 
import ScheduleIcon from '@mui/icons-material/Schedule';
import { CustomTooltip } from "../../../components/CustomTooltip/CustomTooltip";
import DocNavigation from "./DocNavigation/DocNavigation";
import InfoIcon from '@mui/icons-material/Info';

/*This component contains all view-only information for a document. Fields present ablove the divider line*/
class HeaderSection extends React.Component {
  render() {
    const Params = new URLSearchParams(window.location.search);
    let duplicate = Params.get('duplicate');
    return (
      <>
      <div className="display-section">
       
          <div className="flex-margin-review">
            <p className="page-title">{Boolean(duplicate) ? "Duplicate Document Details Review" : "Document Details Review"}</p>
            <div className = "mr-flex-btwn">
              {Boolean(duplicate) != true && localStorage.getItem(c.PREV_ROUTE) && localStorage.getItem(c.PREV_ROUTE) === "docMgmt" &&
            <DocNavigation 
                getDocDetails = {this.props.getDocDetails}
                getDocImage ={this.props.getDocImage}
                fullLoader = {this.props.fullLoader}
                exitDoc = {this.props.exitDoc}
                all_state = {this.props.all_state}
              />}
            {Boolean(duplicate) != true ? <p className="link-style" onClick={() => this.props.gotToDocMgmt()}>
              Back to Document List
            </p> : null}
            </div>
          </div>
          <div className="header-section">
            <div className="flex-space">
              <div className = "flex-inline" style={{ textAlign: "left"}}>
                {this.props.all_state.data &&  this.props.all_state.data.Active_Users && (this.props.all_state.data.Active_Users.status === "active" || this.props.all_state.data.Active_Users.status === "stuck") &&
                <CustomTooltip title = {"Opened by: "+this.props.all_state.data.Active_Users.user_details.map((user,i) => {
                  return user.first_name;
                })} placement="top-start">
                <div className = 'file-lock-icon'>
                    {this.props.all_state.data && this.props.all_state.data.Active_Users && this.props.all_state.data.Active_Users.status === "active" ?
                    <SupervisorAccountIcon className = "blue-icon"/>
                    :
                    <>
                    <ScheduleIcon className = "red-icon"/>
                    <PersonIcon className = "red-icon_1"/>
                    </>
                    }

                </div>
                </CustomTooltip>}
                <div>
              
                 <div style={{wordBreak: "break-word"}} className = {this.props.all_state.data && this.props.all_state.data.Active_Users && (this.props.all_state.data.Active_Users.status === "active" ? "blue-icon" : 
                 this.props.all_state.data.Active_Users.status === "stuck" ? "red-icon_1" : "")}>
                   {this.props.all_state.data && this.props.all_state.data.Name}{" "}
                </div>

                <div className="grey-txt">
                  {this.props.all_state.data && this.props.all_state.data.ID}
                  {this.props.all_state.data &&
                    this.props.all_state.data.Staff_Name !== "" && (
                      <>
                        {" "}
                        (Last Edited :{" "}
                        {this.props.all_state.data &&
                          f.utcToLocal(
                            this.props.all_state.data.Last_Updated_Date +
                              " " +
                              this.props.all_state.data.Last_Updated_Time +
                              " UTC"
                          )}{" "}
                        by{" "}
                        {this.props.all_state.data &&
                          this.props.all_state.data.Staff_Name}
                        )
                      </>
                    )}
                </div>
                </div>
              </div>
            </div>
            <div className="header-info">
              <div className="left-section">
                <div className="each-header-data">
                  <p className="label-font">Location</p>
                  <p className="value-font">
                    {this.props.all_state.data &&
                      this.props.all_state.data.location}
                  </p>
                </div>
                <div className="each-header-data">
                  <p className="label-font">Status</p>
                  <p
                    className="value-font"
                    style={{
                      color:
                        this.props.all_state.data &&
                        this.props.all_state.data.Status === c.status.processed
                          ? "#27AE60"
                          : this.props.all_state.data &&
                            this.props.all_state.data.Status === c.status.queued
                          ? "#AA1572"
                          : this.props.all_state.data &&
                            this.props.all_state.data.Status ===
                            c.status.intervention_rqd
                          ? "#f2994a"
                          : this.props.all_state.data &&
                            this.props.all_state.data.Status === c.status.in_progress
                          ? "#5d1052"
                          : this.props.all_state.data &&
                            this.props.all_state.data.Status ===
                            c.status.completed
                          ? "#2D9CDB"
                          : this.props.all_state.data &&
                            this.props.all_state.data.Status === c.status.failed
                          ? "#EB5757"
                          :this.props.all_state.data &&
                          this.props.all_state.data.Status ===  c.status.uploading
                          ? "#4F4F4F"
                          :this.props.all_state.data &&
                          this.props.all_state.data.Status === c.status.uploaded
                          ? "#9B51E0"
                          :this.props.all_state.data &&
                          this.props.all_state.data.Status === c.status.completing
                          ? "#b4abb2"
                          : "#828282",
                    }}
                  >
                    {this.props.all_state.data &&
                      this.props.all_state.data.Status}
                  </p>
                </div>
                {this.props.all_state.data &&
                      this.props.all_state.data.patient_match_in_EMR !== true &&
                <div className="each-header-data">
                  <div className = "flex-inline-end">
                  {this.props.all_state.data && 
                      (this.props.all_state.data.patient_match_in_EMR ===
                      false ? (
                        <HelpIcon
                          fontSize="small"
                          style={{
                            color: "var(--hti-brick)",
                            marginRight: "3px",
                          }}
                        />
                      ) : this.props.all_state.data.patient_match_in_EMR ===
                        "Pending" ? (
                        <HistoryIcon
                          fontSize="small"
                          style={{
                            color: "var(--hti-brick)",
                            marginRight: "3px",
                          }}
                        />
                      ) : null)}
                      <div>
                   <p className="label-font" >Patient Match</p>
                   <p className="value-font" >
                      {this.props.all_state.data &&
                      this.props.all_state.data.patient_match_in_EMR ===
                          false
                        ? "Not found in EMR"
                        : this.props.all_state.data.patient_match_in_EMR}
                    </p>
                    </div>
                  </div>
                </div>}
                {this.props.all_state.data && this.props.all_state.data.patient_match_in_EMR === true && (
                  <>
                  <div className="each-header-data">
                    <p className="label-font">Patient MRN</p>
                    <p className="value-font">
                      {this.props.all_state.data.MRN}
                    </p>
                  </div>
                  <div style = {{marginTop: "10px"}}>
                  <div className="flex-style">
                     <p className="label-font-flex" style = {{marginLeft : "7px"}}>DOB:</p>
                     <p className="value-font-flex"> {this.props.all_state.data.matched_patient_dob}</p>
                  </div>
                  <div className="only-flex" style = {{marginTop : "5px",textAlign : "left"}}>
                     <p className="label-font-flex">Name:</p>
                     <p className="value-font-flex">
                      {this.props.all_state.data.matched_patient_name}
                     </p>
                </div>
                </div>
                </>
                )}
               
              </div>
          </div>
          </div>         
          </div>
          {this.props.all_state.data.Status === "Failed" && this.props.all_state.data.Failure_Message ?
          <div className="failure-msg">
          <InfoIcon size="small" style = {{marginRight: "5px"}}/>
          <div>{this.props.all_state.data.Failure_Message}.{msg.block_edits}</div>
          </div>
          :
           this.props.all_state.data.Status === "Uploading to EHR" ?
          <div className="doc-info-msg">
          <InfoIcon size="small" style = {{marginRight: "5px"}}/>
          <div>{msg.doc_uploading}</div>
          </div>
           :
           this.props.all_state.data.Status === "Uploaded to EHR" ?
          <div className="doc-info-msg">
          <InfoIcon size="small" style = {{marginRight: "5px"}}/>
          <div>{msg.doc_uploaded}</div>
          </div>
          :
          this.props.all_state.data.Status === "Marked for Completion" ?
          <div className="doc-info-msg">
          <InfoIcon size="small" style = {{marginRight: "5px"}}/>
          <div>{msg.doc_move}</div>
          </div>
          :
          this.props.all_state.data.Status === "Completed" ?
          <div className="doc-info-msg">
          <InfoIcon size="small" style = {{marginRight: "5px"}}/>
          <div>{msg.doc_cmp}</div>
          </div>
          : null
          }
          <div className={this.props.all_state.data &&  this.props.all_state.data.Active_Users && (this.props.all_state.data.Active_Users.status === "active" ? "border-blue": this.props.all_state.data.Active_Users.status === "stuck" ? "border-red" : "border-below")}></div>
          {this.props.all_state.data &&  this.props.all_state.data.Active_Users && (this.props.all_state.data.Active_Users.status === "active" || this.props.all_state.data.Active_Users.status === "stuck") && 
          <div className = {this.props.all_state.data &&  this.props.all_state.data.Active_Users && (this.props.all_state.data.Active_Users.status === "active" ? "blue-strip" : this.props.all_state.data.Active_Users.status === "stuck" ? "red-strip" : "")}
           >
             <div style= {{color : 'white'}}>{msg.file_lock_msg}</div>
           </div>
          }
      </>
    );
  }
}

export default HeaderSection;
