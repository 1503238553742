import React, { useEffect, useState } from "react";
import { TextField, InputLabel, Input, Select, MenuItem, FormControl, Autocomplete } from '@mui/material';
import "./PatientCreation.css";
import * as c from "../../../utils/constants/constants";
import { LANGUAGES, MARITAL_STATUS, GENDER, COUNTRY_LIST, US_STATES } from "./Json";
import * as dayjs from "dayjs";
import api from "../../../utils/API/api";
// import zipcodes from "./zipcodes";
let zipcodes = [];

const PractitionerDetails = (props) => {
    return (
        <div className="practitioner-details-form">
            <div className="form-sub-title">Practitioner Details</div>
            <div className="form-fields">
                <FormControl variant="standard" className="form-field-item" sx={{
                    width: "85%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="practitioner_display">Display</label>
                    <TextField
                        id="practitioner_display"
                        sx={{
                            width: "100%"
                        }}
                        placeholder="Type here"
                        variant="standard"
                        value={props.all_state?.patientCreationData?.practitioner_display || ""}
                        onChange={e => props.handleFieldChange("practitioner_display", e.target.value)}
                    />
                </FormControl>
                <FormControl variant="standard" className="form-field-item" sx={{
                    width: "85%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="practitioner_reference">Reference</label>
                    <TextField
                        id="practitioner_reference"
                        sx={{
                            width: "100%"
                        }}
                        placeholder="Type here"
                        variant="standard"
                        value={props.all_state?.patientCreationData?.practitioner_reference || ""}
                        onChange={e => props.handleFieldChange("practitioner_reference", e.target.value)}
                    />
                </FormControl>
            </div>
        </div>
    )
}

const PatientDetails = (props) => {
    const [error_details, update_error_details] = useState({});

    useEffect(() => {
        if (props.all_state?.patientCreationData) {
            let errorDetails = { ...error_details }
            errorDetails = {
                ...errorDetails,
                given_name: props.all_state?.patientCreationData?.given_name && props.all_state?.patientCreationData?.given_name?.trim()?.length > 0 && !(/^[a-zA-Z .]+$/.test(props.all_state?.patientCreationData?.given_name)),
                family_name: props.all_state?.patientCreationData?.family_name && props.all_state?.patientCreationData?.family_name?.trim()?.length > 0 && !(/^[a-zA-Z .]+$/.test(props.all_state?.patientCreationData?.family_name)),
                email: props.all_state?.patientCreationData?.email && props.all_state?.patientCreationData?.email?.trim()?.length > 0 && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(props.all_state?.patientCreationData?.email?.toLowerCase())),
                mobile_number: props.all_state?.patientCreationData?.mobile_number && props.all_state?.patientCreationData?.mobile_number?.trim()?.length > 0 && !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(props.all_state?.patientCreationData?.mobile_number)),
            }
            update_error_details(errorDetails);
            props.hasError(errorDetails);
        }
    }, [props.all_state.patientCreationData])
    return (
        <div className="patient-details-form">
            <div className="form-sub-title">Patient Details</div>
            <div className="form-fields">
                <FormControl variant="standard" className="form-field-item" sx={{
                    // width: "45%"
                    flex: "1 1 40%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="given_name">First name <span className="required-icon">*</span></label>
                    <TextField
                        id="given_name"
                        value={props.all_state?.patientCreationData?.given_name || ""}
                        sx={{
                            width: "100%"
                        }}
                        variant="standard"
                        error={error_details.given_name}
                        helperText={error_details.given_name ? "You have entered an invalid name" : ""}
                        onChange={e => props.handleFieldChange("given_name", e.target.value)}
                    />
                </FormControl>
                <FormControl variant="standard" className="form-field-item" sx={{
                    // width: "45%"
                    flex: "1 1 40%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="family_name">Last name <span className="required-icon">*</span></label>
                    <TextField
                        id="family_name"
                        sx={{
                            width: "100%"
                        }}
                        variant="standard"
                        error={error_details.family_name}
                        helperText={error_details.family_name ? "You have entered an invalid name" : ""}
                        value={props.all_state?.patientCreationData?.family_name || ""}
                        onChange={e => props.handleFieldChange("family_name", e.target.value)}
                    />
                </FormControl>
                <FormControl variant="standard" className="form-field-item" sx={{
                    // width: "45%"
                    flex: "1 1 40%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="birth_date">Date of Birth <span className="required-icon">*</span></label>
                    <TextField
                        id="birth_date"
                        type="date"
                        sx={{
                            width: "100%"
                        }}
                        variant="standard"
                        value={props.all_state?.patientCreationData?.birth_date && dayjs(props.all_state?.patientCreationData?.birth_date).format("YYYY-MM-DD") || ""}
                        onChange={e => props.handleFieldChange("birth_date", e.target.value)}
                    />
                </FormControl>
                <FormControl variant="standard" className="form-field-item" sx={{
                    // width: "45%"
                    flex: "1 1 40%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="gender">Gender <span className="required-icon">*</span></label>
                    <Select
                        className="form-input-select"
                        id="gender"
                        sx={{
                            width: "100%"
                        }}
                        placeholder="Select"
                        value={props.all_state?.patientCreationData?.gender || ""}
                        onChange={e => props.handleFieldChange("gender", e.target.value)}
                    >
                        {GENDER.map((m, i) => <MenuItem key={i} value={m.value}>{m.label}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl variant="standard" className="form-field-item" sx={{
                        width: "100%",
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="email">E-mail</label>
                        <TextField
                            id="email"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="someone@email.com"
                            variant="standard"
                            error={error_details.email}
                            helperText={error_details.email ? "You have entered an invalid email address" : ""}
                            value={props.all_state?.patientCreationData?.email || ""}
                            onChange={e => props.handleFieldChange("email", e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="standard" className="form-field-item" sx={{
                        width: "100%",
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="mobile_number">Phone Number</label>
                        <TextField
                            id="mobile_number"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="Type here"
                            variant="standard"
                            error={error_details.mobile_number}
                            helperText={error_details.mobile_number ? "You have entered an invalid phone number" : ""}
                            value={props.all_state?.patientCreationData?.mobile_number || ""}
                            onChange={e => props.handleFieldChange("mobile_number", e.target.value)}
                        />
                    </FormControl>
                {/* <FormControl variant="standard" className="form-field-item" sx={{
                    width: "40%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="marital_status">Marital Status</label>
                    <Select
                        className="form-input-select"
                        id="marital_status"
                        sx={{
                            width: "100%"
                        }}
                        value={props.all_state?.patientCreationData?.marital_status || ""}
                        onChange={e => props.handleFieldChange("marital_status", e.target.value)}
                    >
                        {MARITAL_STATUS.map((m, i) => <MenuItem key={i} value={m.value}>{m.label}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl variant="standard" className="form-field-item" sx={{
                    width: "40%"
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="language">Language</label>
                    <Autocomplete
                        options={Object.keys(LANGUAGES).map((m, i) => ({id: m,label: LANGUAGES[m].name}))}
                        name="language"
                        className="auto-complete-list"
                        value={Object.keys(LANGUAGES).reduce((acc, m) => {
                            if(m == props.all_state?.patientCreationData?.language) acc = LANGUAGES[m].name;
                            return acc
                        }, '')}
                        onChange={(e, val) => props.handleFieldChange("language", val.id)}
                        sx={{ width: "100%" }}
                        filterOptions={(options, params) => {
                            return options.filter(m => m.label?.toLowerCase()?.includes(params.inputValue?.toLowerCase()))
                        }}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label="" />
                        )}
                    />
                </FormControl> */}
            </div>
        </div>
    )
}
const ContactDetails = (props) => {
    const [error_details, update_error_details] = useState({});
    const [address_details, update_address_details] = useState({});
    const [stateLists, updateStateArray] = useState([]);
    const [stateDropdown, updateStateDropdown] = useState([]);

    useEffect(() => {
        if (props.all_state?.patientCreationData) {
            let errorDetails = { ...error_details }
            errorDetails = {
                ...errorDetails,
                city: props.all_state?.patientCreationData?.city && props.all_state?.patientCreationData?.city?.trim()?.length > 0 && !(/^[a-zA-Z ]+$/.test(props.all_state?.patientCreationData?.city)),
                // state: props.all_state?.patientCreationData?.state && props.all_state?.patientCreationData?.state?.trim()?.length > 0 && !(/^[a-zA-Z ]+$/.test(props.all_state?.patientCreationData?.state)),
            }
            update_error_details(errorDetails);
            props.hasError(errorDetails);
        }
    }, [props.all_state.patientCreationData]);

    useEffect(() => {
        if (props.all_state?.patientCreationData?.address?.length && !props.all_state?.patientCreationData?.hasOwnProperty("city") && !props.all_state?.patientCreationData?.hasOwnProperty("state") && !props.all_state?.patientCreationData?.hasOwnProperty("zip_code")) {
            parseAddress(props.all_state?.patientCreationData?.address);
        }
    }, [])

    const getCountryDefaultValue = (key) => {
        return COUNTRY_LIST.reduce((acc, m) => {
            if (m.code?.toLowerCase() == key?.toLowerCase()) acc = m.name;
            return acc
        }, '');

    }

    const parseAddress = (address, isZipcode) => {

        let address_lines = address?.split(",") || [];
        const regex = /\b\d{5}(?:-\d{4})?\b/g;
        const zipcodes = address?.match(regex);
        let address_line_1 = address?.split(",")[0]?.trim();
        let address_line_2 = address?.split(",")[1]?.trim();
        
        const zipcode = zipcodes?.length ? zipcodes[zipcodes?.length - 1] : "";
        let addressLineObj = {"zip_code": zipcode};

        if (!isZipcode) addressLineObj = {
            ...addressLineObj, 
            address_line_1: props.all_state?.patientCreationData?.address_line_1 || (props.all_state?.patientCreationData?.address_line_1 != "" && address_line_1), 
            address_line_2: props.all_state?.patientCreationData?.address_line_2 || (props.all_state?.patientCreationData?.address_line_2 != "" && address_line_2 ), 
        }

        // if (zipcodes?.length) {

        //     props.updateFullLoading(true);
        //     api.getPatientLocation({ zipcode: zipcode })
        //         .then(res => {
        //             if (res.status == 200) {
        //                 let addressObj = res?.data?.data || {};
        //                 address_lines = address_lines.filter(m => {
        //                     if (!m?.toLowerCase()?.includes(zipcode)) return true;
        //                     return false;
        //                 });
        //                 if(!isZipcode) {
        //                     addressLineObj.address_line_1 = address_lines.length ? address_lines[0]?.trim() : addressLineObj.address_line_1;
        //                     addressLineObj.address_line_2 = address_lines.length ? address_lines[1]?.trim() : addressLineObj.address_line_2;
        //                 }
                        
        //                 if (Object.keys(addressObj).length) {
        //                     props.handleFieldChange({
        //                         "city": addressObj.city,
        //                         "state": addressObj.state,
        //                         ...addressLineObj
        //                     });
        //                     update_address_details({
        //                         ...addressObj,
        //                         ...addressLineObj,
        //                     });
        //                 } else {
        //                     props.handleFieldChange(addressLineObj);
        //                     update_address_details(addressLineObj);
        //                     props.updateFullLoading(false);
        //                 }

        //                 props.updateFullLoading(false);
        //             }
        //         })
        // } else {
        //     props.handleFieldChange(addressLineObj);
        //     update_address_details(addressLineObj)
        // }

        props.handleFieldChange(addressLineObj);
        update_address_details(addressLineObj)

    }

    useEffect(() => {
        let states = US_STATES || [];
        updateStateArray(states.filter(state => state))
        updateStateDropdown(states.filter(state => state))
    }, [])

    

    const searchZipCode = (zip_code) => {
        if (zip_code?.length && !props.all_state?.patientCreationData?.city && !props.all_state?.patientCreationData?.state && address_details?.zip_code != zip_code) {
            parseAddress(" , " + zip_code, true);
        }
    }

    return (
        <div className="contact-details-form">
            <div className="form-sub-title">Contact Details</div>
            <div className="form-fields">
                {/* <div className="form-field-item-groups">
                    <FormControl variant="standard" className="form-field-item" sx={{
                        flex: 1,
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="email">E-mail</label>
                        <TextField
                            id="email"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="someone@email.com"
                            variant="standard"
                            error={error_details.email}
                            helperText={error_details.email ? "You have entered an invalid email address" : ""}
                            value={props.all_state?.patientCreationData?.email || ""}
                            onChange={e => props.handleFieldChange("email", e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="standard" className="form-field-item" sx={{
                        flex: 1,
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="mobile_number">Phone Number</label>
                        <TextField
                            id="mobile_number"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="Type here"
                            variant="standard"
                            error={error_details.mobile_number}
                            helperText={error_details.mobile_number ? "You have entered an invalid phone number" : ""}
                            value={props.all_state?.patientCreationData?.mobile_number || ""}
                            onChange={e => props.handleFieldChange("mobile_number", e.target.value)}
                        />
                    </FormControl>
                </div> */}
                <div className="form-field-item-groups">
                    <FormControl variant="standard" className="form-field-item" sx={{
                        width: "100%",
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="address_line_1">Address Line 1</label>
                        <TextField
                            id="address_line_1"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="Type here"
                            variant="standard"
                            value={props.all_state?.patientCreationData?.address_line_1 || (props.all_state?.patientCreationData?.address_line_1 != "" && address_details?.address_line_1) || ""}
                            onChange={e => props.handleFieldChange("address_line_1", e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="standard" className="form-field-item" sx={{
                        width: "100%",
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="address_line_2">Address Line 2</label>
                        <TextField
                            className="form-input-select"
                            id="address_line_2"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="Type here"
                            variant="standard"
                            value={props.all_state?.patientCreationData?.address_line_2 || (props.all_state?.patientCreationData?.address_line_2 != "" && address_details?.address_line_2) || ""}
                            onChange={e => props.handleFieldChange("address_line_2", e.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="form-field-item-groups">
                    <FormControl variant="standard" className="form-field-item" sx={{
                        // width: "45%",
                        flex: "1 1 40%"
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="city">City</label>
                        <TextField
                            className="form-input-select"
                            id="city"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="Type here"
                            variant="standard"
                            error={error_details.city}
                            helperText={error_details.city ? "You have entered an invalid city" : ""}
                            value={props.all_state?.patientCreationData?.city || (!props.all_state?.patientCreationData?.hasOwnProperty("city") && address_details?.city) || ""}
                            onChange={e => props.handleFieldChange("city", e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="standard" className="form-field-item" sx={{
                        // width: "45%",
                        flex: "1 1 40%"
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="state">State</label>
                        <Autocomplete
                            options={stateDropdown.sort()}
                            onInputChange={(event, value) => {
                                let states = JSON.parse(JSON.stringify(stateLists));
                                if (!states.includes(value)) states.unshift(value);
                                updateStateDropdown(states);
                            }
                            }
                            className="form-input-select"
                            id="state"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="Type here"
                            variant="standard"
                            error={error_details.state}
                            helperText={error_details.state ? "You have entered an invalid state name" : ""}
                            value={props.all_state?.patientCreationData?.state || (!props.all_state?.patientCreationData?.hasOwnProperty("state") && address_details?.state) || ""}
                            onChange={(e, val) => props.handleFieldChange("state", val || "")}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Type here" variant="standard" label="" />
                            )}
                        />
                    </FormControl>
                    {/* <FormControl variant="standard" className="form-field-item" sx={{
                    flex: 1,
                }}>
                    <label
                        className="form-field-label"
                        htmlFor="state">State</label>
                    <TextField
                        className="form-input-select"
                        id="state"
                        sx={{
                            width: "100%"
                        }}
                        placeholder="Type here"
                        variant="standard"
                        error={error_details.state}
                        helperText={error_details.state ? "You have entered an invalid state name" : ""}
                        value={props.all_state?.patientCreationData?.state || (!props.all_state?.patientCreationData?.hasOwnProperty("state") && address_details?.state) || ""}
                        onChange={e => props.handleFieldChange("state", e.target.value)}
                    />
                </FormControl> */}
                    <FormControl variant="standard" className="form-field-item" sx={{
                        // width: "45%",
                        flex: "1 1 40%"
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="postalCode">Zip Code</label>
                        <TextField
                            className="form-input-select"
                            id="zip_code"
                            sx={{
                                width: "100%"
                            }}
                            placeholder="Type here"
                            variant="standard"
                            error={error_details.zip_code}
                            helperText={error_details.zip_code ? "You have entered an invalid zip code" : ""}
                            value={props.all_state?.patientCreationData?.zip_code || (!props.all_state?.patientCreationData?.hasOwnProperty("zip_code") && address_details?.zip_code) || ""}
                            onChange={e => {
                                props.handleFieldChange("zip_code", e.target.value);
                            }}
                            onBlur={e => {
                                searchZipCode(e.target.value)
                            }}

                        />
                    </FormControl>
                    <FormControl variant="standard" className="form-field-item" sx={{
                        // width: "45%",
                        flex: "1 1 40%"
                    }}>
                        <label
                            className="form-field-label"
                            htmlFor="country">Country</label>
                        <Autocomplete
                            options={(COUNTRY_LIST || []).map((m, i) => ({ id: m.code, label: m.name }))}
                            name="country"
                            className="auto-complete-list"
                            value={getCountryDefaultValue(props.all_state?.patientCreationData?.country || "US")}
                            onChange={(e, val) => props.handleFieldChange("country", val?.id)}
                            sx={{ width: "100%" }}
                            filterOptions={(options, params) => {
                                return options.filter(m => m.label?.toLowerCase()?.includes(params.inputValue?.toLowerCase()))
                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Type here" variant="standard" label="" />
                            )}
                        />
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

const PatientCreation = (props) => {

    const [error_details, update_error_details] = useState({});


    const handleErrors = (obj) => {
        update_error_details({
            ...error_details,
            ...obj
        });

    }

    useEffect(() => {
        let flag = false;
        Object.keys(error_details).forEach(m => {
            if (error_details[m]) flag = true;
        });
        props.handleErrors(flag);
    }, [error_details])



    return (
        <div className="patient-creation-container">
            <div className="patient-creation-wrapper">
                <div className="patient-creation-left-portion">
                    <PatientDetails {...props} hasError={handleErrors} />
                    {/* <ContactDetails {...props} hasError={handleErrors} /> */}
                    {/* <PractitionerDetails {...props} hasError={handleErrors}/> */}
                </div>
                <div className="patient-creation-right-portion">
                    <ContactDetails {...props} hasError={handleErrors}/>
                </div>
            </div>
            {/* <div className="patient-creation-buttons">
                <div className="pt-btn btn-submit" style={{cursor: hasBtnEnable ? "pointer" : "not-allowed"}} onClick={e => hasBtnEnable && props.handlePatientCreation("save")}>Create Patient</div>
                <div className="pt-btn btn-cancel" onClick={e => props.all_state.hasPatientDetailsEdited ? props.discardChanges() : props.handlePatientCreation("close")}>Cancel</div>
            </div>
             */}
        </div>
    )
}

export default PatientCreation;