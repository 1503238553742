import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import * as params from "./params.json";
//AIzaSyAfRuE1nzseqXrze0bQdPo94bV2WP5-lq8 -local
//AIzaSyCMEcb_HO1KQfFLqVB9UHR5WUD4O8MF3go
const config = {
    apiKey: params.FB_API_KEY,
    authDomain: params.FB_AUTH_DOMAIN,
    databaseURL: params.FB_DB_URL,
    projectId: params.FB_PRJ_ID,
    storageBucket: params.FB_STORAGE_BUCKET,
    messagingSenderId: params.FB_MESSENGER_ID,
    appId: params.FB_APP_ID
};
firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.firestore();

