import {Tooltip } from "@material-ui/core";
import { withStyles} from '@material-ui/core/styles';


export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f1e8f0',
    color: '#333333',
    boxShadow: theme.shadows[2],
    fontSize: 13,
  },
}))(Tooltip);