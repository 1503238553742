import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {getDocStuck } from "../../../redux/superAdmin/actions/actions";
import api from "../../../utils/API/api";
import * as msg from "../../../utils/constants/messages";
import * as c from "../../../utils/constants/constants";
import { forceLogout } from "../../../commonFunctions/forceLogout";
import DocListTable from "./DocListTable/DocListTable";
import {
  Button,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import "./DocStuck.css";
/*Documents stuck in-progress for more than 15 minutes are displayed and users are given a provision to update the status*/

const DocStuck = (props) => {
  const dispatch = useDispatch();
  const docs = useSelector((state) => state.docstuck)
  const [dur, setDur] = useState("15");

  useEffect(() => {
    if(localStorage.getItem("admin_screen") !== "unlocked"){
       var _props = props;
      _props.handlePageChange(c.SUPER_ADMIN);
      _props.history.push(c.PAGE_URLS[c.SUPER_ADMIN]);
    }else
      fetchDocStuck(dur);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    if(docs.hasError){
      if(docs.hasError === 401)
       forceLogout();
      else
       window.alert(msg.api_error);
    }
  })
  const fetchDocStuck = (dur) => {
    let isnum = /^\d+$/.test(dur);
    if(!isnum){
      window.alert("Invalid input");
      setDur('');
    }
    else{
    var query = {
      in_progress_time: parseInt(dur),
    };
    dispatch(getDocStuck(c.ROOT_URL + "/getDocListInProgress?page_limit=0",query))
  }
  }
 
  const updateDocStatus = (doc_id,user_comment) => {
    var query = {
      Status: c.status.intervention_rqd,
      Comment : user_comment
    };
    api
    .change_doc_status(doc_id,query)
    .then((response) => {
      if (response.status === 200) {
        fetchDocStuck(dur);
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      } else {
        window.alert(msg.api_error);
      }
    });
  }
  const handleChange = (e) => {
    setDur(e.target.value);
  };
  return (
    <>
      <div style={{ margin: "20px" }}>
        <p className="page-title" style={{ textAlign: "left" }}>
          Documents stuck in progress for more than {dur} minutes
        </p>
        <br />
        <div style = {{textAlign : "left"}}>Enter time in minutes</div>
        <div className="icon-text">
          <TextField
            variant="outlined"
            margin="dense"
            className="text-box"
            //label="Time in minutes"
            style={{ marginRight: "20px" }}
            name="dur"
            value={dur}
            onChange={handleChange}
          />

          <Button variant="contained" className = "mui-buttons-custom-filled" onClick={() => fetchDocStuck(dur)}>
            Fetch
          </Button>
        </div>
        <br />
        <Divider />
        <br />
        {docs.isLoading ? (
          <CircularProgress style={{ color: "grey" }} />
        ) : (
          <>
            {/*<div className="title-sa-main" style = {{textAlign : "left",backgroundColor: "transparent"}}>Documents stuck in progress for last {this.state.dur} minutes</div>*/}

            <DocListTable 
            docs={docs}
            updateDocStatus = {updateDocStatus} 
            />
          </>
        )}
      </div>
    </>
  );
};

export default DocStuck;
