import React from "react";
import { Divider,IconButton,CircularProgress } from "@material-ui/core";
import "./Rightview.css";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorIcon from "@material-ui/icons/Error";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import * as f from '../../../commonFunctions/commonFunctions';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {CustomTooltip} from "../../../components/CustomTooltip/CustomTooltip";
import ComputerIcon from '@mui/icons-material/Computer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import * as c from "../../../utils/constants/constants";

/* get the response from API call made in dashboard.js and display the data*/
class Rightview extends React.PureComponent {
  render() {
    return (
      <>
        <div className = 'paper-title'>
        <div className = 'flex-margin' style = {{padding : '10px',margin: "0px",alignItems : 'center'}}>
         <div className = "status-icon">
          <p className="logstitle title">Logs</p>
          { this.props.refresh_logs &&
          <CircularProgress size ={15} className = "mini-spinner"
         />}
         </div>
          <div className = {this.props.refresh_logs ? "block-area-common" : ""}>
                  <IconButton
                    disabled={
                      this.props.logs_page_num === 0
                        ? true
                        : false
                    }
                    onClick={() => this.props.changePage("prev")}
                  >
                    <ChevronLeftIcon  className="icon-action" />
                  </IconButton>
                  <IconButton
                    disabled={
                      ((this.props.logs_data_length / 15) - Math.floor(this.props.logs_data_length / 15)) !== 0 ?
                      (this.props.logs_page_num ===
                        Math.floor(this.props.logs_data_length / 15))
                        : 
                      (this.props.logs_page_num  ===
                      (this.props.logs_data_length / 15) -1)

                        ? true
                        : false
                    }
                    onClick={() => this.props.changePage("next")}
                  >
                    <ChevronRightIcon  className="icon-action" />
                  </IconButton>
                </div>
                </div>
        </div>
      <div className="paper-style-logs">
        <div className="logs-view" >
          {this.props.log_data && 
            this.props.log_data.map((data, i) => (
              <div key = {i}
                onClick={() => this.props.goToIntervention("review", data.id)}
                style={{ cursor: "pointer" ,paddingTop : '10px'}}
                //className = 'log-bg'
              >
                <div className="flex-margin" style = {{marginTop : '0px'}}>
                  <div className="status-icon">
                    {data.status === c.status.processed ? (
                      <CheckIcon
                        style={{ color: "#828282", fontSize: "1em" ,marginRight : '3px'}}
                      />
                    ) : data.status === c.status.intervention_rqd ? (
                      <VisibilityIcon
                        style={{ color: "#f58018", fontSize: "1em",marginRight : '3px' }}
                      />
                    ) : data.status === c.status.queued ? (
                      <HistoryOutlinedIcon
                        style={{ color: "#828282", fontSize: "1em" ,marginRight : '3px'}}
                      />
                    ) : data.status === c.status.in_progress ? (
                      <AutorenewOutlinedIcon
                        style={{ color: "#828282", fontSize: "1em",marginRight : '3px' }}
                      />
                    ) : data.status === c.status.completed ? (
                      <DoneAllIcon
                        style={{ color: "#828282", fontSize: "1em" ,marginRight : '3px'}}
                      />
                    ) : data.status === c.status.failed ? (
                      <ErrorIcon
                        style={{ color: "#EB5757", fontSize: "1em" ,marginRight : '3px'}}
                      />
                    ) : data.status === c.status.uploading ? (
                      <CloudUploadIcon
                        style={{ color: "#828282", fontSize: "1em" ,marginRight : '3px'}}
                      />
                    ): data.status === c.status.uploaded ? (
                      <ComputerIcon
                        style={{ color: "#828282", fontSize: "1em" ,marginRight : '3px'}}
                      />
                    ):  data.status === c.status.completing ? (
                      <TaskAltIcon
                        style={{ color: "#828282", fontSize: "1em" ,marginRight : '3px'}}
                      />
                    ):(
                      ""
                    )}
                     {data.status.length > 20 ? 
                    <CustomTooltip title={data.status} placement="top-end">
                      <p className="mini-hyperlink" style={{marginLeft : "0px" }}>
                        {data.status.length > 20
                          ? data.status.substr(0, 12) +
                            "..." +
                            data.status.substr(
                              data.status.length - 5,
                              data.status.length
                            )
                          : data.status}
                      </p>
                    </CustomTooltip>
                   : 
                   <p className="mini-hyperlink" style={{marginLeft : "0px"}}>
                      {data.status}
                    </p>
                            }
                  
                  </div>
                  {data.status !== c.status.queued && data.status !== c.status.in_progress && data.status !== 'Extraction Completed' &&
                      <p className="doc-cat">{data.category}</p>
                  }
                </div>
                <div className="flex-margin">
                  {data.name.length > 20 ? (
                    <CustomTooltip title={data.name} placement="top-end">
                      <p className="doc-name-focus">
                        {data.name.length > 20
                          ? data.name.substr(0, 15) +
                            "..." +
                            data.name.substr(
                              data.name.length - 5,
                              data.name.length
                            )
                          : data.name}
                      </p>
                    </CustomTooltip>
                  ) : (
                    <p className="doc-name-focus">{data.name}</p>
                  )}
                  <div className = 'icon-text'>
                 
                  <p className = "doc-cat" style = {{marginLeft : '5px',color : 'black'}}>
                    {f.utcToLocal(data.created_date +' '+ data.created_time + ' UTC')}
                    </p>
                  </div>
                </div>

                <Divider />
              </div>
            ))
           
          }
        </div>
      </div>
      </>
    );
  }
}

export default Rightview;
