import { GNRL_SETTINGS_ACTION } from './actionTypes';

export const getGnrlSettingsActions = {};

getGnrlSettingsActions.getGnrlSettingsActionsError = ((bool) => {
    return {
        type: GNRL_SETTINGS_ACTION.GNRL_SETTINGS_ERROR,
        hasError: bool
    }
});

getGnrlSettingsActions.getGnrlSettingsActionsLoading = ((bool) => {
    return {
        type: GNRL_SETTINGS_ACTION.GNRL_SETTINGS_LOADING,
        hasError: bool
    }
});

getGnrlSettingsActions.getGnrlSettingsActionsSuccess = ((settings) => {
    return {
        type: GNRL_SETTINGS_ACTION.GNRL_SETTINGS_SUCCESS,
        settings : settings
    }
});
/*=============================================================================*/
getGnrlSettingsActions.passDocIDSuccess = ((doc_id) => {
    return {
        type: GNRL_SETTINGS_ACTION.PASS_DOC_ID,
        doc_id : doc_id
    }
});