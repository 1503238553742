import React from "react";
import * as c from "../../utils/constants/constants";
import * as msg from "../../utils/constants/messages";
import * as f from "../../commonFunctions/commonFunctions";
//import db from "../../firebase.config";
import { getGnrlSettings } from "../../redux/generalSettings/actions/actions";
import { connect } from "react-redux";
import * as fs from "../../firebase.config";
import * as params from "../../params.json";
import api from "../../utils/API/api";
import {CircularProgress} from '@material-ui/core';
import { forceLogout } from "../../commonFunctions/forceLogout";

 /* this is the first screen that users will see with spinner.
 when its spinning, tokens are retrieved from firebase and first API call is made*/
class SSOLanding extends React.Component {
   state = {
     db_data: "",
   };
 /* function to get tokens from firebase, given t_id as input*/
  fetchData = async (id) => {
     localStorage.setItem(c.SSO_ID, id);
     const response = fs.db.collection(params.FB_COLLECTION).doc(id.replace("%40", "@"));
     const data = await response.get();
     return data.data();
   };
   /*Get the tokens from firebase and store the needed info in local storage*/
  storeData = async (id) => {
     let tokens = await this.fetchData(id);
     let auth = tokens.auth_token;
     let refresh = tokens.refresh_token;
    // console.log("db resp ", auth, refresh);
     var _props = this.props;
     if (auth && refresh) {
       localStorage.setItem(c.ACCESS_TOKEN, auth);
       localStorage.setItem(c.REFRESH_TOKEN, refresh);
       localStorage.setItem(
         c.USER_ROLE,
         f.getUserInfo(localStorage.getItem(c.ACCESS_TOKEN)).roles[0]
       );
       localStorage.setItem(
         c.USER_ID,
         f.getUserInfo(localStorage.getItem(c.ACCESS_TOKEN)).user_id
       );
       this.getSettings();
     }else
       _props.history.push(c.PAGE_URLS[c.SSO_REDIRECT]);
     
   };
   /*function to make API call to get general settings data. On success of API call, store the settings in local storage and redirect the user*/
   getSettings = () => {
    var _props = this.props;
     api.general_settings()
    .then((response) => {
      if (response.status === 200) {
          localStorage.setItem(c.SETTINGS,JSON.stringify(response.data.data[0]));
         _props.handlePageChange(c.PAGE_DASHBOARD);
         if(localStorage.getItem(c.USER_ROLE) === "Staff")
           _props.history.push('/docManagement');
          else
           _props.history.push('/overview');
      }
    })
    .catch((error) => {
     if(error.response.status === 401) {
       forceLogout();
    }else
       window.alert(msg.api_error);
    });
   }
   /*SSO ID - t_id is taken from the URL. Firbase is authenticated with credentials stored in params.json*/
   componentDidMount = () => {
     var _props = this.props;
     let path = window.location.href.split("?");
    /* if tokens are present , if user is already authenticated, go ahead and call setting API*/
    if (
       localStorage.getItem(c.ACCESS_TOKEN) &&
       localStorage.getItem(c.REFRESH_TOKEN)
     ) {
       this.getSettings();
     } 
     /* if tokens are not present, authenticate firebase first and then pass the t_id to get tokens*/
     else if (path[1]) {
      /*checking if the present URL is coming from microsoft login - for security purpose*/
      if (document.referrer.toString().indexOf("login.microsoftonline.com") === -1) {
         setTimeout(() => {
          _props.history.push(c.PAGE_URLS[c.SSO_REDIRECT]);
       }, 2000);
      } else {
        let path1 = path[1].split("=");
        let path2 = path1[1];
        fs.auth.signInWithEmailAndPassword(params.FB_USER, params.FB_PWD)
          .then((userCredential) => {
            //var user = userCredential.user;
           // console.log('auth success ',user);
            this.storeData(path2);
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
           window.alert('Firebase authentication error.' + errorCode + ' '+errorMessage);
          
          });
       }
     } else 
        _props.history.push(c.PAGE_URLS[c.SSO_REDIRECT]);
     
   };
   /*This screen will only show a spinner */
   render() {
     return( 
     <>
       <CircularProgress style={{ color: "grey", margin: "40vh auto" }}/>
     </>
     )
   }
 }
 const mapStateToProps = (state) => {
   return {
     settings: state.settings,
   };
 };
 const mapDispatchToProps = (dispatch) => {
   return {
     getGnrlSettings: (url) => dispatch(getGnrlSettings(url)),
   };
 };
 
export default connect(mapStateToProps, mapDispatchToProps)(SSOLanding);