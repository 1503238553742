import React from "react";
import { Dialog, DialogTitle, Divider } from "@mui/material";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import InfoIcon from '@mui/icons-material/Info';
import "./Dialogs.css";

class Dialogs extends React.Component {
  render() {
    return (
      <>
        <Dialog open={this.props.showDialog == "error" }>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>Something went wrong. Unable to create patient.</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("ok", this.props.showDialog);
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
        <Dialog open={this.props.showDialog == "mandatoryFields" }>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>First name, Last name, Birth Date and Gender are mandatory.</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("ok", this.props.showDialog);
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
        <Dialog open={this.props.showDialog == "close" }>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>Patient Details are not saved. Are you sure want close?</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("ok", this.props.showDialog);
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("cancel", this.props.showDialog);
                }}
              >
                <p className="apply-button-text" >Cancel</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
        <Dialog
          open={this.props.showDialog == "success"}
          onClose={() => this.props.onDialogAction("ok", this.props.showDialog)}
        >
          <DialogTitle>
            <div className="icon-text">
              <DoneOutlineIcon style={{ color: "#27AE60" }} />
              <p style={{ marginLeft: "10px" }}>Patient created successfully</p>
            </div>
          </DialogTitle>
        </Dialog>
      </>
    );
  }
}

export default Dialogs;
