import React from 'react';
import CloseIcon from "@material-ui/icons/Close";
import {FormControl,TextField,InputAdornment} from'@material-ui/core';
import Search from "@material-ui/icons/Search";

class SearchBar extends React.Component {
  findKey = (e) => {
      if(e.key === 'Enter'){
        this.props.findData('results',this.props.search);
      }else
        return;
  }
    render(){
        return(
            <>
              <div>
                <div>
                  <FormControl>
                    <TextField
                      style={{ width: "300px" }}
                      variant="outlined"
                      margin="dense"
                      id="input-with-icon-textfield"
                      placeholder="Search"
                      name="search"
                      autoComplete="off"
                      onChange={(e) => {
                        this.props.handleChange(e);
                       // this.props.findData("suggest", this.props.search);
                      }}
                      onKeyPress = {(e) => this.findKey(e)}
                      value={this.props.search}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {this.props.search.length > 0 && this.props.is_search ? (
                              <CloseIcon
                                className="icon-action"
                                onClick={() => this.props.cancelFilter("search")}
                              />
                            ) : ''}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>
                {this.props.suggest_box.length > 0 && this.props.is_search !== true && (
                  <div className="suggest-box">
                    {this.props.suggest_box &&
                      this.props.suggest_box.map((data, i) => (
                        <p key = {i}
                          className="suggest-result"
                          onClick={() => this.props.findData("results", data)}
                         
                        >
                          {data}
                        </p>
                      ))}
                  </div>
                )}
              </div>
                </>
        );
    }
}

export default SearchBar;