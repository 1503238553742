import React, { PureComponent } from "react";
import {
  Grid,
  Table,
  TableContainer,
  Paper,
  CircularProgress
} from "@material-ui/core";
//import Filter from "../../components/Filter/Filter";
import Filter from "./Filter/Filter";
import LeftPlane from "./LeftPlane/LeftPlane";
import "./DocManagement.css";
import * as c from "../../utils/constants/constants";
import * as msg from "../../utils/constants/messages";
import { connect } from "react-redux";
import DialogModel from "../../components/DialogModel/DialogModel";
import TablePage from "../../components/TablePage/TablePage";
import TableHeader from "./DocTable/TableHeader/TableHeader";
import TableData from "./DocTable/TableData/TableData";
import RightPlane from "./RightPlane/RightPlane";
import api from "../../utils/API/api";
import SearchBar from "../../components/SearchBar/SearchBar";
import Navbar from "../../components/Navbar/Navbar";
import FileUpload from "./FileUpload/FileUpload";
import * as p from "../../utils/parameter/parameter";
import {
  getDocImageUrl,
  getLocations_user_doc,
  filterResults,
  getFlags
} from "../../redux/docmanagement/actions/actions";
import {getCategories} from "../../redux/manualreview/actions/actions";
import * as f from "../../commonFunctions/commonFunctions";
import { forceLogout } from "../../commonFunctions/forceLogout";


class DocManagement extends PureComponent {
  state = {
    status_arr : [
		{ name: "Queued", isChecked: false },
		{ name: "In Progress", isChecked: false },
		{ name: "Processed", isChecked: true },
		{ name: "Intervention Required", isChecked: true },
		{ name: "Failed", isChecked: false },
    { name: "Uploading to EHR", isChecked: false },
    { name: "Uploaded to EHR", isChecked: false },
    { name: "Marked for Completion", isChecked: false },
    { name: "Completed", isChecked: false }
],  
    is_changed : false,
    fromDate: "",
    toDate: "",
    data: [],
    search: "",
    startRecordNum: 0,
    recordsPerPage: 15,
    pageNum: 0,
    right_plane: "",
    doc_id: "",
    doc_format : '',
    suggest_box: [],
    is_search: false,
    doc_cat: [],
    doc_loc: [],
    tableHeadData: [
      { name: "Document Name", display_name: "Document Name", order: true, icon: false },
      { name: "Category", display_name: "Category", order: true, icon: false },
      { name: "Sub Category", display_name: "Sub Category", order: true, icon: false },
      { name: "Location", display_name: "Location", order: true, icon: false },
      { name: "Patient Name", display_name: "Patient Name", order: true, icon: false },
      { name: "Received on", display_name: "Received on", order: false, icon: true },
      { name: "Last Edited by", display_name: "Last Edited", order: true, icon: false },
      { name: "Status", display_name: "Status", order: true, icon: false },
    ],
    allRecords: "", //total records in a page from DB
    sort_order: false, //stores the order of sort true/false for selected column
    sort_key: "Received on", //stores the selected column header name for sorting
    loc_search_result: "",
    cat_search_result: "",
    image_url : '',
    isFilter : false,
    isLoading : true,
    user_locs : [], //locations assigned to a user
    refresh_list : false,
    apply_filter : false,
    flag_data : [],
    old_flag_data : [],
    flag_glph : false,  // maintains glph icon for flag component
    last_saved_flags : []
  };

/* Function to call on mount and every time when any filters on screen is applied*/
  fetchResults = (page_num,page_limit,all,onLoad) => {
    /* If page is refreshed or filters are resetted, argument all is set to "All" and the same is sent for categories and location to API*/
    /* to show modified symbol - orange dot in flag component*/
    if(JSON.stringify(this.state.last_saved_flags) === JSON.stringify(this.state.flag_data))
       this.setState({flag_glph : false});
    this.setState({refresh_list : true});
    let selected_category = [];
    let selected_location = [];
    let selected_status = [];
    let selected_flags = [];
    this.state.doc_cat && this.state.doc_cat !== "All" && [...this.state.doc_cat].forEach((cat) => {
        cat.isChecked === true && cat.name !== 'Select All' &&
        selected_category.push(cat.name);
    })
    this.state.doc_loc && this.state.doc_loc !== "All" && [...this.state.doc_loc].forEach((loc) => {
      loc.isChecked === true && loc.name !== 'Select All' &&
      selected_location.push(loc.name);
    })
    this.state.status_arr && this.state.status_arr.forEach((st) => {
      st.isChecked === true && st.name !== 'Select All' &&
      selected_status.push(st.name);
  })
  this.state.last_saved_flags && this.state.last_saved_flags.forEach((fg) => {
    fg.enabled && fg.tag_display_name !== "Not Flagged" && selected_flags.push(fg.tag_display_name);
   })
    if(onLoad !== true)
      this.setState({isFilter : true}); 
    let from_date = this.state.apply_filter && this.state.fromDate ? f.dateToUtc(this.state.fromDate,'from') : "";
    let to_date = this.state.apply_filter && this.state.toDate ? f.dateToUtc(this.state.toDate,'to') : "";
    var query = {
      selected_category:  all === 'All' ? 'All' : selected_category,
      selected_status: selected_status,
      selected_locations: all === 'All' ? 'All' :  selected_location,
      selected_tags: all === "All" ? "All" : selected_flags,
      include_tag_less_docs : all === "All" ? true : this.state.last_saved_flags[0].enabled,
      from_date:  from_date,
      to_date: to_date,
      search_string: this.state.search,
      sorting: {
        sort_column: this.state.sort_key,
        is_asc: this.state.sort_order,
      }
    
    };
    api.fetch_results(query, page_num,page_limit).then((response) => {
      const data = response.data.data;
      if (response.status === 200) {
        const filteredData = data.map((dt) => {
          return {
            ...dt,
          };
        });
          if (response.data.total_records !== "")
              this.setState({ allRecords: response.data.total_records });
          this.setState({ data: filteredData,isLoading : false,refresh_list : false});
        
        //localStorage.removeItem(c.DOC_STATUS);
        /* stores all filter data in local storage on success of the API call*/
        p.filter.status = this.state.status_arr;
        p.filter.fromDate = this.state.apply_filter ? this.state.fromDate : "";
        p.filter.toDate = this.state.apply_filter ? this.state.toDate : "";
        p.filter.doc_cat = all === 'All' ? 'All' : this.state.doc_cat;
        p.filter.doc_loc = all === 'All' ? 'All' : this.state.doc_loc;
        p.filter.flag_data = all === "All" ? "All" : this.state.last_saved_flags;
        p.filter.old_flag_data = this.state.old_flag_data;
        p.filter.search = this.state.search;
        p.filter.sort_key = this.state.sort_key;
        p.filter.sort_order = this.state.sort_order;
        p.filter.pageNum = this.state.pageNum;
        p.isFilter.isFilter = this.state.isFilter;
        localStorage.setItem(c.FILTER,JSON.stringify(p.filter));
        localStorage.setItem('is_filter',JSON.stringify(p.isFilter));
      }
   
      
    }).catch((error) => {
      if(error.response.status === 401){
        forceLogout();
      }else{
        this.setState({refresh_list : false});
        if(error.response.status === 429)
          window.alert(msg.rate_limiter);
        else
          window.alert(msg.api_error);
      }
      });
    
  };

componentDidMount = async() => {
    document.title = c.PRODUCT_NAME +' - Doc management';
   /* if(localStorage.getItem(c.FROM_DETAILS) !== 'true'){
      
        await this.props.getCategories(c.ROOT_URL+"/getDocumentCategoryList");
    }
    await this.props.getLocations_user_doc(c.ROOT_URL+"/getLocationsForUser/"+localStorage.getItem(c.USER_ID));*/
    var onLoad = true;
    var filter_data = JSON.parse(localStorage.getItem(c.FILTER));
    /*if arrived from doc details by clicking back to doc list, take filters from local storage and make API call*/
    if(localStorage.getItem(c.FROM_DETAILS) === 'true'){
      this.setState({apply_filter : true});
      onLoad = false;
      for (var i = 0; i < this.state.tableHeadData.length; i++) {
        if (filter_data.sort_key === this.state.tableHeadData[i].name) {
          this.state.tableHeadData[i].order = filter_data.sort_order;
          this.state.tableHeadData[i].icon = true;
        } else this.state.tableHeadData[i].icon = false;
      }
      this.setState({status_arr : filter_data.status, fromDate : filter_data.fromDate, toDate : filter_data.toDate, 
        doc_cat : filter_data.doc_cat,doc_loc : filter_data.doc_loc,flag_data: filter_data.flag_data, last_saved_flags : filter_data.flag_data, old_flag_data : filter_data.old_flag_data, search : filter_data.search, 
        sort_key : filter_data.sort_key, sort_order : filter_data.sort_order,pageNum : filter_data.pageNum},() => {
          this.fetchResults(filter_data.pageNum,'','',onLoad);
        });
    
    }
    /* if not arrived from doc list, make API calls to get filter data, and then make API call to load doc list table with filters as input given to API*/
    else{
      await Promise.all([this.props.getFlags(c.ROOT_URL+"/getTagsList"), this.props.getCategories(c.ROOT_URL+"/getDocumentCategoryList"), this.props.getLocations_user_doc(c.ROOT_URL+"/getLocationsForUser/"+localStorage.getItem(c.USER_ID))])
      .then(() => {
        if(this.props.category_list.category_list){
          let doc_c = [{ name: "Select All", isChecked: true }];
                this.props.category_list.category_list.forEach((category) => {
                  doc_c.push({
                    name: category.category_display_name,
                    isChecked: true
                  });
                })
            this.setState({
              doc_cat: doc_c
            });
        }
        if(this.props.locations_user.locations_user){
            let doc_l = [{ name: "Select All", isChecked: true }];
            this.props.locations_user.locations_user.forEach((location) => {
              if(location.is_auth === true){
                doc_l.push({
                  name: location.name,
                  isChecked: true
                });}
              })
          this.setState({
            doc_loc: doc_l
          });
        }
        if(this.props.flags.flags){
          let flag_list = [{enabled : true, tag_display_name: "Not Flagged"}];
          let final_list = flag_list.concat(this.props.flags.flags);
          this.setState({flag_data : final_list, old_flag_data : final_list, last_saved_flags : final_list});
        }
        if(localStorage.getItem(c.DOC_STATUS)){
          //onLoad = false;
          let status_tmp = [...this.state.status_arr];
           status_tmp.map((st,i) => {
            st.name === localStorage.getItem(c.DOC_STATUS)  ?
            st.isChecked = true : st.isChecked = false ;
           })
          this.setState({status_arr : status_tmp});
        }
        this.fetchResults("0",'','All',onLoad);
      }).catch((error) => {
         // console.log("ERROR ",error);
      })
      
    }
   
      if(this.props.doc_image_url.doc_image_url){
        this.setState({ image_url: this.props.doc_image_url.doc_image_url });
      }
      if(this.props.filterResults.filterResults){
        this.setState({data : this.props.filterResults.filterResults});
      }
      if(this.props.locations_user.locations_user){
        this.setState({user_locs : this.props.locations_user.locations_user});
      }
     
  }
  componentDidUpdate = (prevProps) => {
    if(this.props.category_list.hasError  === 401|| this.props.locations_user.hasError  === 401 || this.props.doc_image_url.hasError  === 401 || this.props.flags.hasError  === 401){
     forceLogout();
    }else if(this.props.category_list.hasError  || this.props.locations_user.hasError || this.props.doc_image_url.hasError || this.props.flags.hasError){
      this.setState({refresh_list : false,isLoading : false});
      window.alert(msg.api_error);
    }
    if( localStorage.getItem(c.FROM_DETAILS) !== 'true' &&(this.props.category_list.category_list && this.props.category_list.category_list !== prevProps.category_list.category_list)){
     let doc_c = [{ name: "Select All", isChecked: true }];
               this.props.category_list.category_list.forEach((category) => {
                doc_c.push({
                  name: category.category_display_name,
                  isChecked: true 
                });
              })
           this.setState({
             doc_cat: doc_c
           });
       }
     
    
    if(localStorage.getItem(c.FROM_DETAILS) !== 'true' &&(this.props.locations_user.locations_user && this.props.locations_user.locations_user !== prevProps.locations_user.locations_user)){ 
      let doc_l = [{ name: "Select All", isChecked: true }];
        this.props.locations_user.locations_user.forEach((location) => {
          if(location.is_auth === true){
            doc_l.push({
              name: location.name,
              isChecked: true
            });}
          })
      this.setState({
        doc_loc: doc_l
      });
    }
    if(this.props.doc_image_url.doc_image_url && this.props.doc_image_url.doc_image_url !== prevProps.doc_image_url.doc_image_url)
     this.setState({ image_url: this.props.doc_image_url.doc_image_url });
    if(this.props.filterResults.filterResults && this.props.filterResults.filterResults !== prevProps.filterResults.filterResults){
      this.setState({data : this.props.filterResults.filterResults,isLoading : false});
    }
    if(this.props.locations_user.locations_user && this.props.locations_user.locations_user !== prevProps.locations_user.locations_user)
      this.setState({user_locs : this.props.locations_user.locations_user});
    if(this.props.flags.flags && this.props.flags.flags !== prevProps.flags.flags){
      let flag_list = [{enabled : true, tag_display_name: "Not Flagged"}];
      let final_list = flag_list.concat(this.props.flags.flags);
      this.setState({flag_data : final_list,old_flag_data : final_list, last_saved_flags : final_list});
    }
     
    if(this.state.isFilter !== true){
             p.filter.doc_cat = this.state.doc_cat;
             p.filter.doc_loc = this.state.doc_loc;
      }      
  }
  componentWillUnmount = () => {
   // localStorage.removeItem(c.DOC_STATUS);
    p.from.details = 'false';
    p.from.list = 'false';
    localStorage.setItem(c.FROM_DETAILS,'false');
    clearInterval(this.timer);
    this.timer = null;
  }
  /* Function to handle pagination for doc list table*/
  handleChangePage = (event, newPage) => {
   // this.setState({ right_plane: false });
   this.setState({refresh_list : true});
    let recordsPerPage = this.state.recordsPerPage;

    this.setState({ startRecordNum: recordsPerPage * newPage });
    this.setState({ pageNum: newPage }, () => {
      this.fetchResults(this.state.pageNum);
    });
  };
/* Function to hanlde input change for filter fields*/
  handleChange = (event) => {
    this.setState({is_changed : true});
    this.setState({ [event.target.name]: event.target.value });
    if(event.target.name === "fromDate" || event.target.name === "toDate")
      this.setState({apply_filter: false});
    /* if input given to search field, debouncing logic is applied so that, API call to get suggestion is made only if no input is given by user for 500 sec*/
    if(event.target.name === "search"){
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.findData("suggest");
      }, 500); 
    }
  };
  findData = (modal, search_val) => {
    /*================alters table data based on searched text==========*/
    if (modal === "results") {
      this.setState({ right_plane: false,suggest_box : [],is_search: true });
      this.setState({ search : search_val, pageNum: 0 }, () => {
        this.fetchResults(this.state.pageNum);
      });
    } else {
      /*=================displays suggestion box based on searched txt==============*/
     
        var suggest = [];
        let query = {
          search_string : this.state.search
        }
        if(this.state.search.length > 2){
        this.setState({is_search: false});
        api.search_suggest_results(query).then((response) => {
          if(response.status === 200) {
            response.data.data && response.data.data.map((data,i) => {
              suggest.push(data);
            })
          this.setState({ suggest_box: suggest });
          }
        }).catch((error) => {
          if(error.response.status === 401){
           forceLogout();
          }else{
               this.setState({refresh_list : false});
               window.alert(msg.api_error);
          }
          });
        }else{
            suggest = [];
            this.setState({suggest_box : suggest,is_search: true});
          }
        
      }
  };
  /*=====for validating multiple filter========*/
  handleSubmit = () => {
    this.setState({ right_plane: false,apply_filter : true});
    if(this.state.is_changed){
    this.setState({ pageNum: 0 }, () => {
      this.fetchResults(this.state.pageNum);
    });
  }
  };
/* Function calls whenever clicked reset all filters button*/
  cancelFilter = (modal) => {
    var filter_data = JSON.parse(localStorage.getItem(c.FILTER));
    /* if search field is cleared by clicking on cross icon*/
    if(modal && modal === 'search')
    {
      this.setState({ search: "",suggest_box:[],is_search: false });
      this.setState({pageNum: 0 }, () => {
        this.fetchResults(this.state.pageNum);
      });
    }
    /*if reset button is clicked*/
    else{
      localStorage.removeItem(c.DOC_STATUS);
      localStorage.removeItem(c.FROM_DETAILS);
      this.state.doc_cat.forEach((cat) => {
       cat.isChecked = true 
      })
      this.state.doc_loc.forEach((loc) => {
        loc.isChecked = true;
      })
      this.state.status_arr.forEach((status) => {
        status.name !== "Processed" && status.name !== "Intervention Required" ? status.isChecked = false : status.isChecked = true;
      })
      this.setState(
        {
          is_changed : false,
          status: [
          "Processed",
          "Intervention Required"],
          fromDate: "",
          toDate: "",
          pageNum: 0,
          search : "",
          suggest_box : [],
          isFilter : false,
          flag_data : filter_data.old_flag_data.length > 0 ? filter_data.old_flag_data : this.state.old_flag_data,
          last_saved_flags : filter_data.old_flag_data.length > 0 ? filter_data.old_flag_data : this.state.old_flag_data
        },
        () => {
          this.fetchResults(this.state.pageNum,'','All',true);
        }
      );
    } 
  
  };
  /* function to sort the doc list table based on the icons present on column headers*/
  sort = (key) => {
    this.setState({ right_plane: false });
    var sort_order = "";
    let table_head = [...this.state.tableHeadData];
    for (var i = 0; i < table_head.length; i++) {
      if (key.replace("_", " ") === table_head[i].name) {
        table_head[i].order = !table_head[i].order;
        sort_order = table_head[i].order;
        table_head[i].icon = true;
      } else table_head[i].icon = false;
    }
    this.setState({tableHeadData : table_head, sort_order: sort_order, sort_key:key,pageNum: 0}, () => {
      this.fetchResults(this.state.pageNum);
    });
  };
 
 /*==========filters table data based on doc categories/locations/status selected with checkboxes ========*/
 handleCheckBoxes = (event, index, modal, all_checklist) => {
  this.setState({ right_plane: false });
  this.setState({
    [event.target.name]: event.target.checked,
  });
  let all_loc = "";
  let all_cat = "";

  if (all_checklist && all_checklist.length > 0){
    if(modal === 'category'){
      all_cat = [...all_checklist];
    }else{
      all_loc = [...all_checklist];
    }
  } 
  else{
    if(modal === 'category'){
      all_cat = [...this.state.doc_cat];
    }else{
      all_loc = [...this.state.doc_loc];
    }
  } 
  if (event.target.checked) {
    if (event.target.name === "Select All") {
      if (modal === "category") {
        all_cat && all_cat.forEach((doc_cat) => {
          doc_cat.isChecked = true;
        })
       
      }
     else if (modal === "location") {
        all_loc && all_loc.forEach((loc) => {
          loc.isChecked = true;
        })
    }
      this.setState({pageNum: 0,},() => {
        this.fetchResults(this.state.pageNum);
      });
      
    } else {
      if (modal === "category") {
        all_cat[index].isChecked = true;
      }
     else if (modal === "location") {
        all_loc[index].isChecked = true;
      }
     else if (modal === "status") {
        this.state.status_arr[index].isChecked = true;
      }
      this.setState({pageNum: 0,},() => {
        this.fetchResults(this.state.pageNum);
      });
    }
  } else {
    if (event.target.name !== "Select All") {
      if (modal === "category") {
        all_cat[index].isChecked = false;
        this.state.doc_cat && this.state.doc_cat.forEach((doc_cat) => {
          doc_cat.name === "Select All" &&
          (doc_cat.isChecked = false)
        })
      }
    else if (modal === "location") {
        all_loc[index].isChecked = false;
        this.state.doc_loc && this.state.doc_loc.forEach((doc_loc) => {
          doc_loc.name === "Select All" &&
          (doc_loc.isChecked = false)
        })
      }
    else if (modal === "status") {
        this.state.status_arr[index].isChecked = false;
      }
     this.setState({pageNum: 0},() => {
          this.fetchResults(this.state.pageNum);
        });
    } else {
      if (modal === "category") {
        all_cat && all_cat.forEach((doc_cat) => {
          doc_cat.isChecked = false
        })
      }
     else if (modal === "location") {
        all_loc && all_loc.forEach((loc) => {
          loc.isChecked = false
        })
      }
      this.setState({pageNum: 0,},() => {
        this.fetchResults(this.state.pageNum);
      });
    }
  }
  let new_cat = [];
  let new_loc = [];
  for(var dc = 1; dc < this.state.doc_cat.length; dc++)
      new_cat.push(this.state.doc_cat[dc]);
  for(var dl = 1; dl < this.state.doc_loc.length; dl++)
      new_loc.push(this.state.doc_loc[dl]);
  const cat_all = new_cat.every((val) => val.isChecked === true)
  const loc_all = new_loc.every((val) => val.isChecked === true)
  if(cat_all){
    this.state.doc_cat.map((val) => 
        val.isChecked = true
    )
  }
  if(loc_all){
    this.state.doc_loc.map((val) => 
        val.isChecked = true
    )
  }
 };
 /* Function to handle flag input*/
   handleFlags = (event, index) => {
    this.setState({ [event.target.name]: event.target.checked });
    var flag_list = JSON.parse(JSON.stringify(this.state.flag_data));
    if(event.target.name === "select_all"){
      flag_list && flag_list.forEach((fg) => {
      fg.enabled = event.target.checked;
      })
    }else
      flag_list[index].enabled = event.target.checked;
    /* Conditions to show/hide flag glyph icon*/
    if(JSON.stringify(this.state.last_saved_flags) !== JSON.stringify(flag_list))
      this.setState({flag_glph : true});
    else
      this.setState({flag_glph : false});
    this.setState({ flag_data: flag_list });
  };
  /*Last applied flags is saved to a state */
  updateFlags = () => {
      this.setState({last_saved_flags : this.state.flag_data},() => {
        this.setState({ pageNum: 0 }, () => {
          this.fetchResults(this.state.pageNum);
        });
      });
  }
  /* Function to open/close right preview pane*/
  showRightPlane = (modal, id, format) => {
     var comp = c.UI_COMP.document.toString();
     var action = c.UI_ACTION.Document_previewed;
    if (modal === "open"){
    this.setState({image_url : ''});
    if(id)
     this.props.getDocImageUrl(c.ROOT_URL +"/getDocFileSignedUrl/"+id+'?comp='+comp+'&action='+action);
    this.setState({ right_plane: true, doc_id: id, doc_format : format });
    }
    else this.setState({ right_plane: false });
  };
  /* Function to re-direct to doc details screen if clicked on doc name or status or edit icon on right preview pane*/
  goToIntervention = ( doc_id) => {
    localStorage.setItem(c.DOC_ID, doc_id ? doc_id : this.state.doc_id);
    localStorage.setItem(c.PREV_ROUTE, 'docMgmt');
    var _props = this.props;
    _props.handlePageChange(c.PAGE_MANUAL_REVIEW);
    _props.history.push(`${c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}?doc_id=${localStorage.getItem(c.DOC_ID)}`);
  };
  //Location search bar
  findLocation = (search, filter_type) => {
    if (search.length >= 2) {
      let copy = [...this.state[filter_type]];
      let searched = [];
      copy.forEach((data, i) => {
        if (data.name.toUpperCase().includes(search.toUpperCase()))
          searched.push(data);
      });
      if (filter_type === 'doc_loc') {
        this.setState({ loc_search_result: searched });
      }else {
        this.setState({ cat_search_result: searched });
      }
    }
  };
  render() {
    let latest_data = this.state.data;
    return (
      <Grid className="full-view">
        <Navbar
          handlePageChange={this.handlePageChange}
          changeCurrentPage={(page) => this.setState({ currentPage: page })}
        />
       
         {this.state.isLoading ? 
          <CircularProgress style = {{color : 'grey',margin: "40vh auto"}}/>
          :
          <>
           { this.state.refresh_list &&
          <CircularProgress size ={30} className = "common-spinner"
         />}
        <div className= {this.state.refresh_list ? "block-area-common" : ""}>
        <Grid className="only-flex">
          <LeftPlane
            handleCheckBoxes={this.handleCheckBoxes}
            doc_cat_name={this.state.doc_cat_name}
            doc_cat={this.state.doc_cat}
            finalData={this.state.finalData}
            allRecords={this.state.allRecords}
            doc_loc={this.state.doc_loc}
            findLocation={this.findLocation}
            loc_search_result={this.state.loc_search_result}
            cat_search_result={this.state.cat_search_result}
          />

          <Grid
            className={
              this.state.right_plane ? "table-width-less" : "table-width"
            }
          >
            <Grid className="flex-margin" style={{ margin: "0px" }}>
            <div className = "status-icon">
                <p className="page-title">Document Management</p>
                <div className = "text-button-upload"  onClick = {() => this.fetchResults(this.state.pageNum)}>
                <div style = {{marginLeft : '3px'}}>Refresh List</div>
                </div>
             </div>
              <div className="flex-style">
                <FileUpload 
                user_locs = {this.state.user_locs}/>

                <SearchBar
                  search={this.state.search}
                  handleChange={this.handleChange}
                  findData={this.findData}
                  suggest_box={this.state.suggest_box}
                  cancelFilter={this.cancelFilter}
                  is_search={this.state.is_search}
                />
              </div>
            </Grid>
            <Filter
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              cancelFilter={this.cancelFilter}
              status_arr = {this.state.status_arr}
              handleCheckBoxes = {this.handleCheckBoxes}
              flag_data = {this.state.flag_data}
              handleFlags = {this.handleFlags}
              flag_glph = {this.state.flag_glph}
              updateFlags = {this.updateFlags}
              
            />           

            <TableContainer component={Paper} className = 'full-table'>
              <Table stickyHeader aria-label = 'customized table' >
                <TableHeader
                  sort={this.sort}
                  tableHeadData={this.state.tableHeadData}
                />
                <TableData
                  finalData={latest_data}
                  startRecordNum={this.state.startRecordNum}
                  recordsPerPage={this.state.recordsPerPage}
                  data_id={this.state.data_id}
                  open={this.props.open}
                  showRightPlane={this.showRightPlane}
                  doc_id={this.state.doc_id}
                  goToIntervention={this.goToIntervention}
                  right_plane = {this.state.right_plane}
                />
              </Table>
              {latest_data.length === 0 && <DialogModel /> }
            </TableContainer>
            <TablePage
              count={this.state.allRecords}
              recordsPerPage={this.state.recordsPerPage}
              pageNum={this.state.pageNum}
              handleChangePage={this.handleChangePage}
              refresh_list={this.state.refresh_list}
            />
          </Grid>
          {this.state.right_plane && (
            <RightPlane
              showRightPlane={this.showRightPlane}
              doc_id={this.state.doc_id}
              doc_format = {this.state.doc_format}
              goToIntervention={this.goToIntervention}
              image_url = {this.state.image_url}
            />
          )}
        </Grid>
        </div>
        </>}
        
      </Grid>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    doc_image_url : state.doc_image_url,
    locations_user : state.locations_user,
    filterResults : state.filterResults,
    category_list : state.category_list,
    flags : state.flags
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDocImageUrl: (url) => dispatch(getDocImageUrl(url)),
    getLocations_user_doc: (url) => dispatch(getLocations_user_doc(url)),
    filterResults: (url, query) => dispatch(filterResults(url,query)),
    getCategories: (url) => dispatch(getCategories(url)),
    getFlags: (url) => dispatch(getFlags(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocManagement);
