import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./AddUser.css";
import Alert from '@material-ui/lab/Alert';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      first_name: "",
      last_name: "",
      phone: "",
      active: "true",
      role: "Staff",
      email: ""
    };
  }

  _handleTextFieldChange = (event) => {
    let val = event.target.value;
    let id = event.target.id;
    const block = (id === 'phone' && event.target.value.length > 200);
    if(!block){
    this.props.userdetails[id] = val;
    this.setState({ id: val });}
  }

 
  
  handleSubmit = (event) => {
    const user_id = this.props.userdetails.user_id;
    let userdata = {...this.props.userdetails};
    userdata.active = JSON.parse(userdata.active);
    this.props.locationlist.forEach((loc) => {
      if(loc.is_auth) {
        userdata.location_ids.push(loc.id);
      }
    });
    delete userdata.user_id;
    if(user_id) {
      this.props.editExistingUser(userdata, user_id);
    }
    if(!user_id) {
      this.props.createNewUser(userdata);
    }
    //this.props.closeDialog();
  }
  
  render() {
    return (
      <div>
        <Dialog open={this.props.openDialog}>
          <DialogTitle className="title-bg">
            <div className="upload-title">
              <p style={{ margin: "0px", color: "white", fontSize: "16px" }}>
                {" "}
                {this.props.userdetails.user_id ? 'Edit User' : 'Add User'}
              </p>
              <CloseIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  this.props.closeDialog();
                }}
              />
            </div>
          </DialogTitle>
          <DialogContent>
           { this.props.showErrorMessage ? <Alert severity="error">{this.props.errorMessage}</Alert> : null }
            <ValidatorForm
              ref="form"
              onSubmit={this.handleSubmit}
              onError={errors => console.log(errors)}>
              <div className="field-control">
                <FormControl className="form-control">
                  <label>First Name</label>
                  <TextValidator
                    id="first_name"
                    variant="outlined"
                    placeholder="First Name"
                    onChange={this._handleTextFieldChange}
                    value={this.props.userdetails.first_name}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </FormControl>
                <FormControl className="form-control">
                <label>Last Name</label>
                  <TextValidator
                      id="last_name"
                      variant="outlined"
                      placeholder="Last Name"
                      onChange={this._handleTextFieldChange}
                      value={this.props.userdetails.last_name}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                  />
                </FormControl>
              </div>
              <div className="field-control">
                <FormControl className="form-control">
                  <label>Email ID</label>
                  <TextValidator
                    id="email"
                    variant="outlined"
                    placeholder="Email ID"
                    onChange={this._handleTextFieldChange}
                    value={this.props.userdetails.email}
                    validators={["isEmail", "required"]}
                    errorMessages={["Please enter a valid email", "This field is required"]}
                  />
                </FormControl>
                <FormControl className="form-control">
                <label>Phone Number</label>
                  <TextValidator
                      id="phone"
                      variant="outlined"
                      placeholder="Phone"
                     // type="number"
                      onChange={this._handleTextFieldChange}
                      value={this.props.userdetails.phone}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                  />
                </FormControl>
              </div>
              <div>
              <FormControl className="field-control radio-container"  component="fieldset" value={this.props.userdetails.role} onChange={this.props._handleRadioFieldChange}>
                <label >Role</label>
                <div className="radio-fields">
                  <RadioGroup row aria-label="role" name="role" defaultValue={this.props.userdetails.role} onChange={this.props._handleRadioFieldChange}>
                    <FormControlLabel defaultChecked={this.props.userdetails.role === 'Staff'} value="Staff" control={<Radio />} label="Staff" />
                    <FormControlLabel defaultChecked={this.props.userdetails.role === 'Admin'} value="Admin" control={<Radio />} label="Admin" />
                  </RadioGroup>
                </div>
            </FormControl>
              <FormControl component="fieldset" value={this.props.userdetails.active} onChange={this.props._handleRadioFieldChange}>
                <label className="status-radio">Status</label> 
                <div className="radio-fields status-radio">
                <RadioGroup row aria-label="active" name="active" defaultValue={this.props.userdetails.active} onChange={this.props._handleRadioFieldChange}>
                    <FormControlLabel defaultChecked={this.props.userdetails.active === 'true'} value="true" control={<Radio />} label="Active" />
                    <FormControlLabel defaultChecked={this.props.userdetails.active === 'false'} value="false" control={<Radio />} label="Inactive" />
                </RadioGroup>
                </div>
            </FormControl>
            
            </div>
            {this.props.userdetails.role !== 'Admin' ?
              <FormControl component="fieldset" >
                <div className = 'icon-text'>
              <div className = 'loc-title'>Location :</div>
              <div className = 'icon-text'><p className = 'loc-action-text' onClick = {() => this.props.checkboxAction('all')}>Select all</p><p className = 'loc-action-text' onClick = {() => this.props.checkboxAction('none')}>Deselect all</p></div>
              </div>
             
              <div className="locations-box">
                {this.props.loc_loader ? <CircularProgress className = 'loc-loader' size = {15}/> : 
              this.props.locationlist &&
                this.props.locationlist.map((location, i) => (
                 
                  <FormControlLabel key = {i}
                  control={
                    <Checkbox
                      onChange={this.props._handleCheckboxChange}
                      value={location.id}
                      checked = {location.is_auth}
                      //defaultChecked={location.is_auth}
                      name={location.name}
                      color="primary"
                    />
                  }
                  label={location.name}
                />
                   
                ))} 
              </div>
            </FormControl>:
            <div>
              Admin role can access all locations.
              </div>}
            <div className="div-center">
              <button
                className="apply-button"
                type="submit"
              >
                <p className="apply-button-text">Save</p>
              </button>
              <button
                className="reset-button"
                type="button"
                onClick={() => {
                  this.props.locationlist.length = 0;
                  this.props.closeDialog();
                }}
              >
                <p className="reset-button-text">Cancel</p>
              </button>
            </div>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default AddUser;
