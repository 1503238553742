import React from "react";
import {
  Typography,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import "./TableHeader.css";
import HelpIcon from "@material-ui/icons/Help";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VisibilityIcon from "@material-ui/icons/Visibility";

class TableHeader extends React.Component {
  
  render() {
    return (
      <TableHead className="thBorder">
        <TableRow>
          {this.props.tableHeadData.map((data, i) => (
            <TableCell
              key={i}
              className="tableHeadStyle"
              align="left"
              onClick={() => this.props.sort(data.name)}
            >
              <div className="sortSection">
                {data.name === "Patient Name" && (
                  <div className="patient-icon-hide">
                    <HelpIcon
                      fontSize="small"
                      style={{
                        color: "var(--hti-brick)",
                        fontSize: "1em",
                        marginRight: "3px",
                      }}
                    />
                  </div>
                )}
                {data.name === "Document Name" && (
                  <div className="file-icon-hide" style = {{marginRight : "13px"}}>
                    <SupervisorAccountIcon className = "blue-icon" />
                  </div>
                )}
                {data.name === "Status" &&
                 <VisibilityIcon
                 style={{ color: "#f58018", fontSize: "1em",visibility : "hidden" }}
               />}
                <Typography className="textTheme">{data.display_name}</Typography>

                <div
                  className={
                    data.icon === true ? "sort-icons" : "sort-icons-hide"
                  }
                >
                  {data.order === true ? (
                    <ArrowUpwardIcon
                      fontSize="small"
                      style={{ color: "grey" }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{ color: "grey" }}
                      fontSize="small"
                    />
                  )}
                </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;
