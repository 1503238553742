import React from "react";
import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import InfoIcon from '@mui/icons-material/Info';
import "./Dialogs.css";
import * as msg from "../../../utils/constants/messages";
import CloseIcon from "@mui/icons-material/Close";

/*This component all dialog component required in doc details screen except upload button prompt*/
class Dialogs extends React.Component {
  render() {
    return (
      <>
      {/*dialog appears after data is successfuly saved to backend*/}
        <Dialog
          open={this.props.dialog_flags.save_dialog}
          onClose={() => this.props.onDialogAction("sucess")}
        >
          <DialogTitle>
            <div className="icon-text">
              <DoneOutlineIcon style={{ color: "#27AE60" }} />
              <p style={{ marginLeft: "10px" }}>{msg.data_save}</p>
            </div>
          </DialogTitle>
        </Dialog>
        {/*dialog appears when discard changes button is clicked*/}
        <Dialog open={this.props.dialog_flags.discard_dialog}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.data_discard}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("discard", "ok");
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
              <button
                className="reset-button"
                style={{ width: "70px" }}
                onClick={() => this.props.onDialogAction("discard", "cancel")}
              >
                <p className="reset-button-text" >Cancel</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>

        {/*dialog appears file duplication warning is cleared.*/}
        <Dialog open={this.props.dialog_flags.warning_dialog}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.file_duplication_prompt}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("duplicate", "ok");
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
              <button
                className="reset-button"
                style={{ width: "70px" }}
                onClick={() => this.props.onDialogAction("duplicate", "cancel")}
              >
                <p className="reset-button-text" >Cancel</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
        {/*dialog appears when conflict arises. when multiple users working on same document.*/}
        <Dialog open={this.props.dialog_flags.block_save}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>This document has been updated by another user.</div>
              <div>Please refresh to review the latest changes.</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "max-content", marginRight: "0px" }}
                onClick={() => {
                  this.props.onDialogAction("block", "ok");
                }}
              >
                <p className="apply-button-text">Refresh</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
        <Dialog open={this.props.dialog_flags.patientCreateError}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{this.props.content || "Something went wrong. Unable to create patient."}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("patientCreateError", "ok");
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
        {/*dialog appears when flag notes are missed for particular flags.*/}
        <Dialog open={this.props.dialog_flags.flag_notes_warning}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.flag_notes_missing_message}</div>
            </div>
            <Divider />
            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("flag_notes", "ok");
                }}
              >
                <p className="apply-button-text" >Proceed</p>
              </button>
              <button
                className="reset-button"
                style={{ width: "70px" }}
                onClick={() => this.props.onDialogAction("flag_notes", "cancel")}
              >
                <p className="reset-button-text" >Cancel</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>

        {/*dialog appears when flag notes conatian not valid fax number for spam flags.*/}
        <Dialog open={this.props.dialog_flags.spam_alert}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.spam_validation_message}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "max-content", marginRight: "0px" }}
                onClick={() => {
                  this.props.onDialogAction("spamAlert", "ok");
                }}
              >
                <p className="apply-button-text">OK</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>

        {/*dialog appears when flag notes conatian not valid fax number for spam flags.*/}
        <Dialog open={this.props.dialog_flags.spam_warning}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.spam_warning_message}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "max-content", marginRight: "0px" }}
                onClick={() => {
                  this.props.onDialogAction("spamWarning", "ok");
                }}
              >
                <p className="apply-button-text">OK</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>

        {/*dialog appears when order completion status fields not filled.*/}      
        <Dialog open={this.props.dialog_flags.order_status_warning}>
          <DialogTitle>
            <div className="block-save">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <div>{msg.order_completion_status}</div>
            </div>
            <Divider />

            <div
              className="icon-text"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <button
                className="apply-button"
                style={{ width: "70px" }}
                onClick={() => {
                  this.props.onDialogAction("order", "ok");
                }}
              >
                <p className="apply-button-text" >OK</p>
              </button>
            </div>
          </DialogTitle>
        </Dialog>
      </>
    );
  }
}

export default Dialogs;
