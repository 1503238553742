import React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import * as c from "../../../../utils/constants/constants";
import * as msg from "../../../../utils/constants/messages";
import * as f from "../../../../commonFunctions/commonFunctions";
import api from "../../../../utils/API/api";
import { forceLogout } from "../../../../commonFunctions/forceLogout";
import { Dialog, DialogTitle} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { CustomTooltip } from "../../../../components/CustomTooltip/CustomTooltip";
//import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";

 /*------------------------------------
 When next or prev button is clicked, following is the order of execution.
 1. Get the new doc ID 
 2. Release old doc
 3. Load left side details by calling /getDoc with new doc ID
 4. Load doc pdf by calling /getDocImage with new doc ID
 */

class DocNavigation extends React.Component {
  state = {
    filter_query: {},
    noDoc: false,
    anchorEl: null,
  };
  componentDidMount = () => {
    var all_filters = JSON.parse(localStorage.getItem(c.FILTER));
    var categories = [];
    var locations = [];
    var status = [];
    var flags = [];
    if (
      all_filters.doc_cat &&
      all_filters.doc_cat !== "All" &&
      all_filters.doc_cat.length > 0
    ) {
      all_filters.doc_cat.map((cat, i) => {
        if (cat.isChecked === true && cat.name !== "Select All")
          categories.push(cat.name);
      });
    }
    if (
      all_filters.doc_loc &&
      all_filters.doc_loc !== "All" &&
      all_filters.doc_loc.length > 0
    ) {
      all_filters.doc_loc.map((loc, i) => {
        if (loc.isChecked === true && loc.name !== "Select All")
          locations.push(loc.name);
      });
    }
    if (all_filters.status && all_filters.status.length > 0) {
      all_filters.status.map((st, i) => {
        if (st.isChecked === true) status.push(st.name);
      });
    }
    if (
      all_filters.flag_data &&
      all_filters.flag_data !== "All" &&
      all_filters.flag_data.length > 0
    ) {
      all_filters.flag_data.map((fg, i) => {
      fg.enabled  && fg.tag_display_name !== "Not Flagged" && flags.push(fg.tag_display_name);
      });
    }
    var query = {
      selected_category:
        all_filters.doc_cat && all_filters.doc_cat === "All"
          ? "All"
          : categories,
      selected_status: status,
      selected_locations:
        all_filters.doc_loc && all_filters.doc_loc === "All"
          ? "All"
          : locations,
      selected_tags : all_filters.flag_data && all_filters.flag_data === "All" ? "All" : flags,
      include_tag_less_docs : all_filters.flag_data && all_filters.flag_data === "All" ? true : all_filters.flag_data[0].enabled,
      from_date: all_filters.fromDate
        ? f.dateToUtc(all_filters.fromDate, "from")
        : "",
      to_date: all_filters.toDate ? f.dateToUtc(all_filters.toDate, "to") : "",
      search_string: all_filters.search,
      sorting: {
        sort_column: all_filters.sort_key,
        is_asc: all_filters.sort_order,
      },
      is_stat: all_filters.isStat,
    };
    this.setState({ filter_query: query });
  };

  getDocId = (doc_id, step) => {
    this.props.fullLoader("show");
    if (this.state.filter_query) {
      api.get_doc_id(this.state.filter_query, doc_id, step)
        .then((response) => {
          if (response.status === 200 && response.data.data.length === 0) {
            this.props.fullLoader("hide");
            this.setState({ noDoc: true });
            setTimeout(() => {
              this.setState({ noDoc: false });
            }, 2000);
          } else if (response.status === 200 && response.data.data[0].doc_id) {
            this.props.exitDoc(doc_id);
            this.props.getDocDetails(response.data.data[0].doc_id);
            this.props.getDocImage(response.data.data[0].doc_id);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            forceLogout();
          } else window.alert(msg.api_error);
        });
    }
  };
  closeDialog = () => {
    this.setState({ noDoc: false });
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
   // let filters = JSON.parse(localStorage.getItem(c.FILTER));
    return (
      <>
      {/*  <IconButton onClick={this.handleClick}>
          <FilterAltTwoToneIcon style={{ color: "#aa1752" }} />
        </IconButton>
        <Menu
          className="filter-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          onClose={this.handleClose}
          open={Boolean(this.state.anchorEl)}
        >
          <div className="ftr-each">
            <div className="ftr-label">Category: </div>
            <div>
              {this.state.filter_query.selected_category &&
                (this.state.filter_query.selected_category === "All"
                  ? "Default"
                  : this.state.filter_query.selected_category.join(", "))}
            </div>
          </div>
          <div className="ftr-each">
            <div className="ftr-label">Location: </div>
            <div>
              {this.state.filter_query.selected_locations &&
                (this.state.filter_query.selected_locations === "All"
                  ? "Default"
                  : this.state.filter_query.selected_locations.join(", "))}
            </div>
          </div>

          <div className="ftr-each">
            <span className="ftr-label">Status: </span>
            {this.state.filter_query.selected_status &&
              this.state.filter_query.selected_status.join(", ")}
          </div>

          <div className="ftr-each">
            <span className="ftr-label">From Date: </span>
            {filters.fromDate && filters.fromDate}
          </div>

          <div className="ftr-each">
            <span className="ftr-label">To Date: </span>
            {filters.toDate && filters.toDate}
          </div>

          <div className="ftr-each">
            <span className="ftr-label">STAT: </span>
            {filters.isStat && filters.isStat === true ? "Yes" : "No"}
          </div>

          <div className="ftr-each">
            <span className="ftr-label">Sorted by: </span>
            {filters.sort_key && filters.sort_key},{" "}
            <span className="ftr-label">Order: </span>
            {filters.sort_order && filters.sort_order === true
              ? "Ascending"
              : "Descending"}
          </div>

          <div className="ftr-each">
            <span className="ftr-label">Search String: </span>{" "}
            {filters.search && filters.search}
          </div>
            </Menu>*/}
        <div className = "nav-buttons">
        {this.props.all_state.isSave || this.props.all_state.flag_save  ? (
          <CustomTooltip title={msg.stat_tooltip} placement="bottom-end">
            <div className="block-cursor">
              <div
                className="flex-space"
                style={{ width: "9em", color: "#aa1752" }}
              >
                <div
                  className="nav-icon"
                  style={{ paddingRight: "7px" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <ArrowLeftIcon />
                  <div>Prev</div>
                </div>
                <div
                  className="nav-icon"
                  style={{ paddingLeft: "7px" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <div>Next</div>
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
          </CustomTooltip>
        ) : (
          
            <div
              className="flex-space"
              style={{ width: "9em", color: "#aa1752",cursor: "pointer" }}
            >
              <div
                className="nav-icon"
                style={{ paddingRight: "7px" }}
                onClick={() =>
                this.getDocId(this.props.all_state.doc_id, "prev")
                }
              >
                <ArrowLeftIcon />
                <div>Prev</div>
              </div>
              <div
                className="nav-icon"
                style={{ paddingLeft: "7px" }}
                onClick={() =>
                 this.getDocId(this.props.all_state.doc_id, "next")
                }
              >
                <div>Next</div>
                <ArrowRightIcon />
              </div>
            
          </div>
        )}
        </div>
        <Dialog open={this.state.noDoc} onClose={() => this.closeDialog()}>
          <DialogTitle>
            <div className="icon-text">
              <InfoIcon style={{ color: "var(--hti-magenta)" }} />
              <p style={{ marginLeft: "10px" }}>No Document Available</p>
            </div>
          </DialogTitle>
        </Dialog>
      </>
    );
  }
}

export default DocNavigation;
