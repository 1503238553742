import React, {useRef, useEffect} from "react";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./FlagList.css";

const FlagList = (props) => {
  const flag_box = useRef(null);
  useOutsideAlerter(flag_box);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      // Function for click event outside flag box
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.updateFlags("cancel");
        }
      }
     // Adding click event listener
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref]);
  }
  var flag_count = [];
  props.flag_list && props.flag_list.forEach((flag) => {
    flag.enabled && flag_count.push(flag.tag_display_name);
  })
  /*var glph = false;
  if(JSON.stringify(props.flag_list) !== JSON.stringify(props.flags_old))
    glph = true;*/
  return (
    <div ref={flag_box}>
    <div className="fg-flag-icon" onClick={() => props.showFlagList()}>
    <div className = {props.flag_glph ? "icon-show" : "icon-hide"}>
    <FiberManualRecordIcon className = "glph-icon"/>
    </div>
    <div>Flags</div>
    <div className = "fg-count"> 
      <div className="fg-count-number">{flag_count.length}</div>
      <ArrowDropDownIcon />
    </div>
   
    </div>
    {props.showFlags &&
    <div className="fg-popup" >
      <div className =  {props.flag_glph ? "fg-check-icon" : "icon-hide"}>
        <CheckCircleIcon
          className= "icon-review-style"
          onClick={() => props.updateFlags("","","save")}
        />
      </div>
      <div className = "fg-list-section">
      {props.modal === "doc_mgmt" &&
      <FormGroup className = "fg-select-all">
            <FormControlLabel
              name="select_all"
              control={
                <Checkbox
                  size="small"
                  checked= {props.flag_list.every((val) => val.enabled === true)}
                  className="check-box-color"
                  onChange={(event) => props.handleFlags(event)}
                />
              }
              label={
                <div style={{ textAlign: "left" }}>Select All</div>
              }
              labelPlacement="end"
            />
      </FormGroup>
      }
      <div className="fg-data">
      {props.flag_list.map((flag, i) => (
        <div key={i}>
          <FormGroup>
            <FormControlLabel
              name={flag.tag_display_name}
              control={
                <Checkbox
                  size="small"
                  checked={flag.enabled}
                  disabled={flag.disabled}
                  className="check-box-color"
                  onChange={(event) => props.handleFlags(event, i, "modify")}
                />
              }
              label={
                <div className = {flag.tag_display_name === "Not Flagged" && "fg-flag-italise"}>{flag.tag_display_name}</div>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      ))}
      </div>
      </div>
    </div>}
   
    </div>
  );
};

export default FlagList;
