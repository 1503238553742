import React from "react";
import {
  Paper,
  Divider,
  FormControl,
  TextField,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import "./LocationTable.css";

class LocationTable extends React.Component {
  state = {
    search: "",
    search_str: ''
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value});
    if(event.target.value?.length >= 2) {
      let data = (this.props.locationStatics?.categories || []).map((key, i) => {
        return {
          name: key,
          count: this.props.locationStatics?.data[i]
        }
      });
  
      let filteredData = data.filter(m => {
        if (m.name?.toLowerCase().includes(event.target.value?.toLowerCase())) return m;
      });
  
      this.setState({search: filteredData });
    }
  };

  getExportData() {
    let { data = [], categories = [] } = this.props.locationStatics || {};
    return categories.map((m, i) => {
        return {
            s_no: i + 1,
            Doc_Count: data[i],
            Name: m,
        }
    })
}

  render() {
    let data = (this.props.locationStatics?.categories || []).map((key, i) => {
      return {
        name: key,
        count: this.props.locationStatics?.data[i]
      }
    });

    let { selectedFilters = {} } = this.props || {};
    let from_date = new Date(selectedFilters?.fromDate),
      to_date = new Date(selectedFilters?.toDate);
    return (
      <>
        {/*=================SCREEN VIEW========================*/}
        <div className="rp-paper location-table">
          <Paper
            elevation={1}
            className="paper-style"
            style={{ margin: "10px" }}
          >
            <div className="paper-title">
              <div className="report-chart-header">
                <div className="report-chart-title">
                  {"Overall Date Range Statistics by Location"}
                  <div className="chart-subtitle">
                    {`This section shows overall statistics by location for a date range between ${from_date.getMonth() + 1}/${from_date.getDate()}/${from_date.getFullYear()} and 
                             ${to_date.getMonth() + 1}/${to_date.getDate()}/${to_date.getFullYear()}`}
                  </div>
                </div>
              </div>
            </div>
            <Divider />

            <div className="table-body">
            <div style={{width: "max-content"}}>
            <div className="rp-avg-page-row">
              <FormControl className="search-input-field">
                <TextField
                  variant="standard"
                  // style={{ width: "480px" }}
                  id="input-with-icon-textfield"
                  placeholder="Search Location"
                  name="search_str"
                  autoComplete="off"
                  value={this.state.search_str}
                  onChange={(e) => {
                    this.handleChange(e);
                    // this.props.findData(e.target.value, "location");
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {/*  <CloseIcon
                                className="icon-action"
                                //onClick={() => this.props.cancelFilter("search")}
                            />*/}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <div className="rp-avg-page-row-count">
                <p className="rp-avg-data" style={{ color: "grey", width: "150px" }}>Total</p>
              </div>
              <div className="rp-avg-page-row-count">
                <p className="rp-avg-data" style={{ color: "grey" }}>1</p>
              </div>
              <div className="rp-avg-page-row-count">
                <p className="rp-avg-data" style={{ color: "grey" }}>2</p>
              </div>
              <div className="rp-avg-page-row-count">
                <p className="rp-avg-data" style={{ color: "grey" }}>3</p>
              </div>
              <div className="rp-avg-page-row-count">
                <p className="rp-avg-data" style={{ color: "grey" }}>4</p>
              </div>
              <div className="rp-avg-page-row-count">
                <p className="rp-avg-data" style={{ color: "grey" }}>5</p>
              </div>
            </div>
            <Divider />
            <div className="doc-view">
              {this.state.search_str.length > 0 ?
                (this.state.search && this.state.search.map((data, i) =>
                  <div key={i}>
                    <div className="rp-avg-page-row">
                      <div className="rp-left-text">{data.name}</div>
                      <div className="rp-avg-page-row-count" style={{width: "150px"}}>
                        <div className="rp-avg-data">{data.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{data.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{data.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{data.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{data.count.toLocaleString()}</div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                )) :
                this.props.loader.locationStatics ? <CircularProgress style={{ color: "grey", margin: "100px auto" }} /> : (data && data.length && data.map((obj, i) => (
                  <div key={i}>
                    <div className="rp-avg-page-row">
                      <div className="rp-left-text">{obj.name}</div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{obj.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{obj.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{obj.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{obj.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{obj.count.toLocaleString()}</div>
                      </div>
                      <div className="rp-avg-page-row-count">
                        <div className="rp-avg-data">{obj.count.toLocaleString()}</div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                ))
                )}
            </div>
            </div>
            </div>
          </Paper>
        </div>
        {/*=================PRINT VIEW========================*/}

        <div className="rp-paper-print-cat">
          <div>
            <p className="rp-title">Average Pages by Category</p>
            <div className="flex-style">
              <p className="rp-print-text" style={{ width: "40%", color: "grey" }}>Name</p>
              <p className="rp-print-text" style={{ width: "20%", color: "grey" }}>Doc Count</p>
              <p className="rp-print-text" style={{ width: "20%", color: "grey" }}>Avg Pages</p>
            </div>
            {this.props.avg_pg_category &&
              this.props.avg_pg_category.map((data, i) => (
                <div className="flex-style" key={i}>
                  <p className="rp-print-text" style={{ width: "40%" }}>{data.Name}</p>
                  <p className="rp-print-text" style={{ width: "20%" }}> {data.Doc_Count.toLocaleString()}</p>
                  <p className="rp-print-text" style={{ width: "20%" }}>{data.Average_Pages}</p>

                </div>
              ))
            }
          </div>
        </div>
        <div className="fake-pg-brk"></div>
        <div className="rp-paper-print-loc">
          <div>

            <p className="rp-title">Average Pages by Location</p>
            <div className="flex-style">
              <p className="rp-print-text" style={{ width: "40%", color: "grey" }}>Name</p>
              <p className="rp-print-text" style={{ width: "20%", color: "grey" }}>Doc Count</p>
              <p className="rp-print-text" style={{ width: "20%", color: "grey" }}>Avg Pages</p>
            </div>

            {this.props.avg_pg_loc &&
              this.props.avg_pg_loc.map((data, i) => (
                <div className="flex-style" key={i}>
                  <p className="rp-print-text" style={{ width: "40%" }}>{data.Name}</p>
                  <p className="rp-print-text" style={{ width: "20%" }}> {data.Doc_Count.toLocaleString()}</p>
                  <p className="rp-print-text" style={{ width: "20%" }}>{data.Average_Pages}</p>
                </div>

              ))
            }
          </div>
        </div>

      </>
    )

  }
}

export default LocationTable;
