import React from "react";
import { Dialog, DialogTitle} from "@material-ui/core";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';


class AlertBox extends React.Component {
  state = {
    open: true,
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount = ()=> {
    this.setState({open : true});
  }
  render() {
     // console.log('inside alert box ',this.state.open);
    return (
      <>
    {/*  <Snackbar 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }} 
      open={this.state.open} 
      autoHideDuration={2000} 
      onClose={this.handleClose} 
    message ='Data saved successfully'/>*/}
      <Dialog  open={this.state.open}  onClose={this.handleClose}>
         <DialogTitle>
           <div className = 'icon-text'>
              <DoneOutlineIcon />
            <p style = {{marginLeft : '10px'}}>Data saved successfully</p>
            </div>
         </DialogTitle>
    </Dialog>
       
      </>
    );
  }
}

export default AlertBox;
