import { Input } from "@material-ui/core";
import { Close, Minimize, Save, VideoLabel, WebAsset } from "@mui/icons-material";
import React, { Component, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { connect, useDispatch } from "react-redux";
import PatientCreation from "./PatientCreation/PatientCreation";
import { PATIENT_CREATE_ACTIONS } from "../../redux/patientCreate/actions/actionCreators";
import "./PatientCreate.css"
import { PATIENT_CREATE_ACTIONS_CONSTANTS } from "../../redux/patientCreate/actions/actionTypes";
import { CircularProgress, Tooltip } from "@mui/material";
import Dialogs from "./Dialog/Dialogs";
import api from "../../utils/API/api";

const miniWindowStyle = {
  width: "280px",
  minWidth: "240px",
  height: "50px",
  minHeight: "40px",
  overflow: "hidden",
  resize: "unset"
}

const maxWindowStyle = {
  width: "50%",
  minWidth: "30%",
  height: "580px",
  minHeight: "30%",
  overflow: "auto",
  resize: "both"
}

const PatientCreate = (props) => {

  const dispatch = useDispatch();
  const [showDraggableContainer, toggleDraggableContainer] = useState(false);
  const [draggablePosition, updateDraggablePosition] = useState({});
  const [patientCreationData, updatePatientCreationData] = useState({});
  const [showDialog, updateWarningDialog] = useState(false);
  const [fieldEdited, updateFieldEdited] = useState(false);
  const [showFullLoading, updateFullLoading] = useState(false);
  const [disableSave, updateSaveButton] = useState(false);


  useEffect(() => {
    if (props.patient_create_details.popup_open && Object.keys(props.patient_create_details).length) updatePatientCreationData(props.patient_create_details.patient_info);
  }, [props.patient_create_details.popup_open]);


  useEffect(() => {
    if (Object.keys(props.patient_create_details).length) {
      if (props.patient_create_details.maximise) {
        toggleDraggableContainer(false);
      } else {
        toggleDraggableContainer(false);
      }
    }

  }, [props.patient_create_details?.maximise, props.patient_create_details?.popup_open]);

  useEffect(() => {
    if(showDraggableContainer == false) {
      setTimeout(() => toggleDraggableContainer(true), 100)
    }

  }, [showDraggableContainer])

  const handleFieldChange = (col, val) => {
    let patientInfo = JSON.parse(JSON.stringify(patientCreationData))
    if(typeof col === "string") {
      updateFieldEdited(true);
      patientInfo[col] = val;
    } else {
      patientInfo = {...patientInfo, ...col};
    }
    updatePatientCreationData(patientInfo)
    dispatch(props.update_patient_create_info({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.UPDATE_PATIENT_CREATE_DETAILS, payload: patientInfo }));
  }

  const resetPatientCreateData = () => {
    updatePatientCreationData({});
    props.patient_create({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.TOGGLE_PATIENT_CREATE_POPUP, payload: false })
    props.maximise_patient_create({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.TOGGLE_PATIENT_CREATE_POPUP, payload: true });
    props.patient_create({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.TOGGLE_PATIENT_CREATE_POPUP, payload: false });
    dispatch(props.update_patient_create_info({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.UPDATE_PATIENT_CREATE_DETAILS, payload: {} }));
    
  }

  const onDialogAction = (action, type) => {
    
    if (action == "ok") {
      if(type == "error" || type == "close" || type == "success") {
        updateFieldEdited(false);
        updateWarningDialog();
        resetPatientCreateData()
      } else if(type == "mandatoryFields") {
        updateWarningDialog();
      }
    }else if (action == "cancel") {
      updateWarningDialog();
    }
  }

  const onClose = () => {
    if(fieldEdited) {
      return updateWarningDialog("close");
    }
    resetPatientCreateData();
  }

  const handlePatientCreate = e => {
    const { given_name, family_name, birth_date, gender, mobile_number = '', email = '', marital_status = '', language = '', city = '', state = '', zip_code = '', country = '',
      address_line_1 = '', address_line_2 = '', practitioner_display = '', practitioner_reference = '' } = props.patient_create_details.patient_info || {}

    let patietnData = {
      given_name: given_name?.trim(), family_name: family_name?.trim(), birth_date, gender, mobile_number, email, marital_status, language,
      "general_practitioner": [{
        "display": practitioner_display,
        "reference": practitioner_reference
      }],
      "address": [{
        "use": "home",
        "line": [
          address_line_1 || '',
          address_line_2 || ''
        ],
        city,
        state,
        postalCode: zip_code,
        country,
      }]
    }

    if (given_name?.trim() && family_name?.trim() && birth_date && gender) {
      updateFullLoading(true)
      api
        .createPatient(patietnData)
        .then(res => {
          updateFullLoading(false);
          updateFieldEdited(false);
          updateWarningDialog("success");
          updatePatientCreationData({});
          resetPatientCreateData();
          setTimeout(() => updateWarningDialog(false), 2000)
        })
        .catch(err => {
          updateFullLoading(false);
          updateWarningDialog("error");
          updatePatientCreationData({});
        })
    } else {
      updateFullLoading(false)
      updateWarningDialog("mandatoryFields");
    }
  }

  return (
    <>
      
      {props.patient_create_details.popup_open && showDraggableContainer ? (props.patient_create_details.maximise ? <Draggable
        handle=".draggable-header"
        className="draggable-component"
        defaultPosition={{ x: window.screen.availWidth / 4, y: 20 }}
        // position={draggablePosition}
        bounds={"parent"}
        onDrag={(e, data) => {
          updateDraggablePosition({ x: data.x, y: data.y })
        }}
      >
        <div style={maxWindowStyle} id="draggable-container">
          <div className="draggable-header" >
            <div className="draggable-header-content" style={{pointerEvents: showFullLoading ? "none" : "auto"}}>
              <div className="patient-create-title">Create Patient</div>
              <div className="draggable-header-icons">
                {/* <Tooltip title={ disableSave ? "Not allowed" : "Create Patient"}>
                  <Save onClick={e => !disableSave && handlePatientCreate()} style={{cursor: disableSave ? "not-allowed" : "pointer"}}/>
                </Tooltip> */}
                  <Tooltip title="Minimize">
                    <VideoLabel onClick={e => props.maximise_patient_create({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.MAXIMISE_PATIENT_CREATE_POPUP, payload: false })} />
                  </Tooltip>
                <Tooltip title="Close">
                  <Close onClick={onClose} />
                </Tooltip>

              </div>
            </div>
          </div>
          { showFullLoading ?
            <div className="loading-block-area-common">
              <CircularProgress size={30} className="common-spinner" />
            </div> : null }
            <div className="draggable-body" style={{ overflow: "auto" }}>
            <div className="patient-information-title">Patient Information</div>
            <PatientCreation
              all_state={{ patientCreationData }}
              handleFieldChange={handleFieldChange}
              handleErrors={error => updateSaveButton(error)}
              updateFullLoading={updateFullLoading}
            />
            <div className="div-center">
              <button
                className="apply-button"
                type="submit"
                style={{cursor: disableSave ? "not-allowed" : "pointer"}}
                onClick={e => !disableSave && handlePatientCreate()}
              >
                <p className="apply-button-text">Save</p>
              </button>
              <button
                className="reset-button"
                type="button"
                onClick={onClose}
              >
                <p className="reset-button-text">Cancel</p>
              </button>
            </div>
          </div>
        </div>
      </Draggable> : <Draggable
        handle=".draggable-header"
        className="draggable-component"
        defaultPosition={{ x: window.screen.availWidth - 300, y: window.screen.availHeight - 220 }}
        // position={draggablePosition}
        bounds={"parent"}
        onDrag={(e, data) => {
          updateDraggablePosition({ x: data.x, y: data.y })
        }}
      >
        <div style={miniWindowStyle} id="draggable-container">
          <div className="draggable-header" >
            <div className="draggable-header-content" style={{pointerEvents: showFullLoading ? "none" : "auto"}}>
              <div className="patient-create-title">Create Patient</div>
              <div className="draggable-header-icons">
                <Tooltip title="Maximize">
                    <WebAsset onClick={e => props.maximise_patient_create({ type: PATIENT_CREATE_ACTIONS_CONSTANTS.MAXIMISE_PATIENT_CREATE_POPUP, payload: true })} />
                  </Tooltip>
                <Tooltip title="Close">
                  <Close onClick={onClose} />
                </Tooltip>

              </div>
            </div>
          </div>
        </div>
      </Draggable>) : null}
      <Dialogs
        showDialog={showDialog}
        onDialogAction={onDialogAction} />
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    patient_create_details: state.patient_create || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    patient_create: (action) => dispatch(PATIENT_CREATE_ACTIONS.TOGGLE_PATIENT_CREATE_POPUP(action)),
    maximise_patient_create: (action) => dispatch(PATIENT_CREATE_ACTIONS.MAXIMISE_PATIENT_CREATE_POPUP(action)),
    update_patient_create_info: (action) => dispatch(PATIENT_CREATE_ACTIONS.UPDATE_PATIENT_CREATE_DETAILS(action))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientCreate);
