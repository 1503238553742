import { CSVLink } from "react-csv";
import React from "react";
import * as c from "../../utils/constants/constants";
import * as f from "../../commonFunctions/commonFunctions";
import LaunchIcon from '@mui/icons-material/Launch';

/* Making an API call to fetch all data accoding to filters applied.
Only after data is fetched, export component will be triggered.
This is achieved using "ref" logic. ref is assigned to click "export" link.
Export link is clicked after a setimeout inside success block of API call.
All dta along with headers and export info is combined to single json and passed to the library
*/

const ExportAudit = (props) => {
    const dummy_headers = [
        { label: "Document: " +c.PRODUCT_NAME+ " Audit Report", key: "name" },
        { label: "", key: "component" },
        { label: "", key: "document_name" },
        { label: "", key: "original_file_name" },
        { label: "", key: "action" },
        { label: "", key: "last_updated_date" }
      
      ];

    const export_info = [
        {name: "Export By:", component: localStorage.getItem(c.USER_NAME)},
        {name: "Export Date/Time:", component: f.currDate()+ ', ' + f.currTime() }
    ]
    const audit_filters = [
        { name: ""},
        { name: ("User Name: "+props.audit_filter.user_name)
        + (" ,From Date: "+props.audit_filter.fromDate)
        + (" ,To Date: "+props.audit_filter.toDate)
        + (" ,Action: "+props.audit_filter.action.replace(/(^|_)./g, str => " " + str.slice(-1).toUpperCase()))
        + (" ,Sorted by: "+props.audit_filter.sort_key)
        + (" ,Sorted Order: "+(props.audit_filter.sort_order ? "Ascending" : "Descending"))
        + (" ,Search String: "+props.audit_filter.search)
         }
    ]
    const audit_headers = [
        { name: ""},
        { name: "User Name", component : "Component",document_name: "Document Name",original_file_name: "Document Old Name",
        action: "Action",last_updated_date: "Updated on"}
      ];
      
    const report_end = [
        {name: ""},
        {name: "<End of Report>"}
    ]

   var audit_data =  props.audit_data ? JSON.parse(JSON.stringify(props.audit_data)) : [];
   //iterating and converting utc to local time zone
  audit_data && audit_data.forEach((data,i) => {
    data.last_updated_date = f.utcToLocal(
      data.last_updated_date +
        " " +
        data.last_updated_time +
        " UTC",
      "full"
    );
  })
  let combined_json = [];
  combined_json = export_info.concat([{name: ""}, {name: "Audit Log"}]).concat(audit_headers).concat(audit_data).concat(report_end);  
return(
        <div className="audit-export-link">
        <LaunchIcon  />
        <input type="button" className = "button-as-link" value="Export" onClick={props.generateReport} />
        <CSVLink
          headers={dummy_headers}
          filename="readabl-audit-report.csv"
          data={combined_json}
          ref={props.csvLinkEl}
        />
        </div>
)
}
export default ExportAudit;