import {PATIENT_CREATE_ACTIONS_CONSTANTS } from "../actions/actionTypes";

const defaultState = {popup_open: false, maximise: true, patient_info: {}};
export function patient_create(state = {...defaultState}, action) {
    switch(action.type) {
        case PATIENT_CREATE_ACTIONS_CONSTANTS.TOGGLE_PATIENT_CREATE_POPUP:
            return  { ...state, popup_open: action.payload} 
        case PATIENT_CREATE_ACTIONS_CONSTANTS.MAXIMISE_PATIENT_CREATE_POPUP:
            return { ...state, maximise: action.payload} 
        case PATIENT_CREATE_ACTIONS_CONSTANTS.UPDATE_PATIENT_CREATE_DETAILS:
            return { ...state, patient_info: action.payload} 
        default:
            return state;
    }
}