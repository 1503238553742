import React from "react";
import _ from "lodash";
import "./ManualReview.css";
import * as c from "../../utils/constants/constants";
import * as msg from "../../utils/constants/messages";
import { connect } from "react-redux";
import api from "../../utils/API/api";
import PreviewSection from "./PreviewSection/PreviewSection";
import Navbar from "../../components/Navbar/Navbar";
import {
  getManualReviewData,
  getImageUrl,
  getCategories,
  getCategoriesMap
} from "../../redux/manualreview/actions/actions";
import HeaderSection from "./HeaderSection/HeaderSection";
import {
  CircularProgress,
} from "@mui/material";
import Dialogs from "./Dialogs/Dialogs";
import { forceLogout } from "../../commonFunctions/forceLogout";
import EditManual from "./EditManual/EditManual";
import DialogModel from "../../components/DialogModel/DialogModel";
import ButtonHandler from "./EditManual/ButtonHandler";
import * as dayjs from "dayjs";
import { PATIENT_CREATE_ACTIONS } from "../../redux/patientCreate/actions/actionCreators";
import { PATIENT_CREATE_ACTIONS_CONSTANTS } from "../../redux/patientCreate/actions/actionTypes";

class ManualReview extends React.Component {
  state = {
    data: [], //stores document data fetch from /getDoc API
    switch: true,
    std_key: "",
    dialog_flags : {
      save_dialog: false, //flag for save sucess popup
      discard_dialog: false,  //flag for discard changes popup
      block_save: false, //flag for muliple users info msg popup
      warning_dialog: false, // flag for file duplicate warning msg popup
      flag_notes_warning: false, // flag for not filling flag notes warning msg popup
      spam_warning: false, // flag for not filling flag notes warning msg popup
      order_status_warning:false, //order status not filling warning msg popup
    },
    flag_glph:false, // to show and hide glph icon for flags component
    card_error : "",
    image_url: "", //stores document URl fetched from API
    isSave: false, //becomes 'true' whenever check icon in any field/card is clicked.
    delete_card: "",
    isLoading: true, //on/off loader for the oage
    mini_loading: false, //shows a overlay spinner only in left section.
    full_loading: false, //shows a overlay spinner for entire screen
    category_list : [], //stores list of category fetched from API
    category_map : '', //stores sub-category mapping data fetched from API
    edit_free_text : false, //handles edit mode for Notes. 'true' will make Notes behave as edit mode. 'false' will make Notes behave as view mode. 
    edit_doc_desc: false,//handles edit mode for Doc desc. 'true' will make doc desc behave as edit mode. 'false' will make doc desc behave as view mode. 
    card_edit: false, //handles edit mode for card. 'true' will make card behave as edit mode. 'false' will make card behave as view mode. This is made false  whenever /getDocdetails is called
    card_add: false, //handles add new card condition. 'true' will open a new card.This is made false  whenever /getDocdetails is called
    category_card: false, //handles edit mode for category card. 'true' will make card behave as edit mode. 'false' will make card behave as view mode. This is made false  whenever /getDocdetails is called
    is_flag_notes_proceed: false, // handle flag notes not filled prompt, proceed button clicks
    notesChanged: false ,// checks flag notes changing
    doc_id: '' // get the docid from url params.
  };
  /*calls when component first laods, API call to load document data, document URL, category list, category mapping data*/
  componentDidMount = () => {
    const Params = new URLSearchParams(window.location.search);
    let doc_id = Params.get('doc_id');
    let duplicate = Params.get('duplicate');
    document.title = Boolean(duplicate) ? c.PRODUCT_NAME + " - Duplicate Doc Review" : c.PRODUCT_NAME + " - Doc Review";
    this.getDocDetails(doc_id);
    this.getDocImage(doc_id);
    this.props.getCategories(c.ROOT_URL+"/getDocumentCategoryList");
    this.props.getCategoriesMap(c.ROOT_URL+"/getDocClassificationMapping");
    // this.state.doc_id = doc_id;
    // this.state.isDuplicateView = Boolean(duplicate);
    this.setState({
      doc_id: doc_id, 
      isDuplicateView: Boolean(duplicate)
    });
    if(this.props.category_list.category_list)
      this.setState({category_list : this.props.category_list.category_list});
    if(this.props.category_map.category_map)
      this.setState({category_map : this.props.category_map.category_map[0]});
  };
  componentWillUnmount = () => {
   this.exitDoc();
   localStorage.removeItem(c.PREV_ROUTE);
  };
  /*makes API call whenever user has left the document. used for file lock feature.*/
  exitDoc = () => {
    var data = {
      "Is_Active": false
    }
    if(!(localStorage.getItem(c.LOGOUT) === "inactive")){
    api.exit_doc_details(this.state.doc_id,data)
    .then((response) => {
    })
    .catch((error) => {
     if (error.response.status === 401) {
       forceLogout();
      }else{
        this.setState({mini_loading : false});
        window.alert(msg.api_error);
      }
      
    });
  }
  }
  //Called when page first loads - logged as 'document viewed' in audit.
  getDocDetails = (doc_id) => {
    const Params = new URLSearchParams(window.location.search);
    let duplicate = Params.get('duplicate');
    localStorage.removeItem(c.ENC_DATA);
    localStorage.removeItem(c.ORDER_DATA);
    this.props.getManualReviewData(
      c.ROOT_URL +
        "/getDoc/" +
        doc_id +
        "?comp=" +
        c.UI_COMP.document.toString() +
        "&action=" +
        c.UI_ACTION.Document_viewed
    );
    if(Boolean(duplicate)) this.props.history.replace(`${c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}?doc_id=${doc_id}&duplicate=true`);
    else this.props.history.replace(`${c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}?doc_id=${doc_id}`);
    
    if (this.props.data.data && this.props.data.data.length > 0)
      this.setState({ data: this.props.data.data[0],edit_free_text:false,card_edit:false,card_add: false,category_card: false,edit_doc_desc: false, doc_id: doc_id});

  };
  componentDidUpdate = (prevProps) => {
    if(this.props.data.hasError  === 401 || this.props.image_url.hasError  === 401 || this.props.category_map.hasError === 401 ){
      forceLogout();
    }else if(this.props.data.hasError || this.props.image_url.hasError || this.props.category_map.hasError){
        this.setState({isLoading : false,mini_loading : false});
        window.alert(msg.api_error);
    }
    if (this.props.data.data && this.props.data.data !== prevProps.data.data) {
      let data = JSON.parse(JSON.stringify(this.props.data.data[0]));
      let isSpamEnabled = (data.Tags || []).findIndex(m => m.tag_display_name == "SPAM" && m.enabled);
      (data.Tags || []).forEach(m => {
        if(isSpamEnabled != -1 && m.tag_display_name != "SPAM") m.disabled = true;
      })
      this.setState({ data: data,isLoading: false,mini_loading :false,edit_free_text:false,card_edit:false,card_add: false,flag_glph: false,category_card: false });
    }
      
    if (
      this.props.image_url.image_url &&
      this.props.image_url.image_url !== prevProps.image_url.image_url
    ){
      this.setState({ image_url: this.props.image_url.image_url,full_loading: false});
    }
    if(this.props.category_list.category_list && this.props.category_list.category_list !== prevProps.category_list.category_list)
      this.setState({category_list : this.props.category_list.category_list});
    if(this.props.category_map.category_map && this.props.category_map.category_map !== prevProps.category_map.category_map)
      this.setState({category_map : this.props.category_map.category_map[0]});
  };
//Called after any update on the screen - this action will not get logged in audit
  getDocDetails_save = () => {
    this.props.getManualReviewData(c.ROOT_URL + "/getDoc/" + this.state.doc_id);
    //To hide save button, discard button and mini loader when any data on a document is saved to DB
    this.setState({isSave:false,mini_loading:true});
   /* if (this.props.data.data && this.props.data.data !== this.state.data )
      this.setState({ data: this.props.data.data[0]});*/
  };
  getDocImage = (doc_id) => {
   /*   var doc_ids = JSON.parse(localStorage.getItem('image'));
    if(doc_ids && Object.keys(doc_ids).find((id) => id == doc_id)) {
    if(f.url_expiry(JSON.parse(localStorage.getItem('image'))[doc_id][0].signed_url) === true){
      this.setState({ image_url: JSON.parse(localStorage.getItem('image'))[doc_id] });
    }
    else
      this.props.getImageUrl(c.ROOT_URL + `/getDocFileSignedUrl/${doc_id}`);
  }else*/
    this.props.getImageUrl(c.ROOT_URL + `/getDocFileSignedUrl/${doc_id}`);
    if (
      this.props.image_url.image_url &&
      this.props.image_url.image_url !== this.state.image_url
    ){
      this.setState({ image_url: this.props.image_url.image_url });
     /* c.IMAGE[localStorage.getItem(c.DOC_ID)] = this.props.image_url.image_url;
      localStorage.setItem('image', JSON.stringify(c.IMAGE));*/
    }
  };
   /*This calls when user saves after editing any card*/
  saveData = (modal, type, states,classification) => {
    /*modal- calssification,extraction. type-edit,add. states- for extraction, it contains label,value,normalised value.
    for classification, it is empty. classification- contains category,sub-category,doc_level*/
    this.setState({ isSave: true});
    var new_key = "";
    var card_details = this.state.data;
    let extractionKeys = {
      "Patient Name": "patientName",
      "Patient Date of Birth": "dateOfBirth",
      "Address": "address",
      "To": "provider_details",
      "Phone Number": "phone_number",
    }
    if (modal === "edit") {
      new_key = extractionKeys[states.normalised] || '';
      if (type === "extraction") {
        /*deleted the existing card and adds new card, this would change the position of cards*/
        if (states.ref !== new_key) {
          card_details.Entity_Details.extraction[new_key] = card_details.Entity_Details.extraction[states.ref];
          delete card_details.Entity_Details.extraction[states.ref];
          card_details.Entity_Details.extraction[new_key] = {
            normalized: states.normalised,
            confidence: 1.0,
            key: states.label_type,
            value: states.value_type,
            modified: true,
          };
        } else {
          card_details.Entity_Details.extraction[states.ref].normalized =
            states.normalised;
          card_details.Entity_Details.extraction[states.ref].confidence = 1.0;
          card_details.Entity_Details.extraction[states.ref].key =
            states.label_type;
          card_details.Entity_Details.extraction[states.ref].value =
            states.value_type;
          card_details.Entity_Details.extraction[states.ref].modified = true;
        }
      } else if (type === "classify") {
        card_details.Entity_Details.classify["documentSubCategory"] =
          classification.subCategory;
        card_details.Entity_Details.classify["docClassification"] =
          classification.category;
        card_details.Entity_Details.classify["documentLevel"] =
          classification.docLevel;
        card_details.Entity_Details.classifyConfidence = 1.0;
        card_details.Entity_Details.classModify = true;
      }
      this.setState({ data: card_details});
    } /*This calls when user add new card*/ 
    else if (modal === "add") {
      new_key = extractionKeys[states.normalised_new] || '';
     
      
      card_details.Entity_Details.extraction[new_key] = {
        normalized: states.normalised_new,
        confidence: 1.0,
        key: states.label_type_new,
        value: states.value_type_new,
        modified: true,
      }
      this.setState({ data: card_details});
    }
  };
  /*Delete the card when user clicks on delete icon*/
  deleteData = (key) => {
    this.setState({ isSave: true });
    delete this.state.data.Entity_Details.extraction[key];
    this.setState({ data: this.state.data, delete_card: true });
    this.setState({
      list_of_keys: ["None", "Patient Name", "Patient Date of Birth"],
    });
  };
  /*upload button click action*/
  uploadToOnbase = () => {
    var comp = c.UI_COMP.document.toString();
    var action = c.UI_ACTION.Document_uploaded_to_onbase;
    this.setState({mini_loading: true,card_add: false,card_edit: false,category_card: false});
    var query = {
      "doc_id": this.state.doc_id,
      "revision_id" : this.state.data.Revision_ID
    }
  api.upload_to_onbase(query,comp,action).then((response) => {
    if (response.status === 200) {
      this.getDocDetails_save();
    }
  }).catch((error) => {
    if(error.response.status === 401){
      forceLogout();
     }else if(error.response.status === 409){
           this.setState({dialog_flags : {block_save : true},mini_loading : false});
     }else{
         this.setState({mini_loading : false});
         window.alert(msg.api_error);
       }
  });
  }
  /*complete button click action*/
  completeDocument = () => {
    var comp = c.UI_COMP.document.toString();
    var action = c.UI_ACTION.Document_completed;
     this.setState({mini_loading: true,card_add: false,card_edit: false,category_card: false});
    var query = {
      "doc_id": this.state.doc_id,
      "revision_id" : this.state.data.Revision_ID
    }
  api.complete_document(query,comp,action).then((response) => {
    if (response.status === 200) {
      this.getDocDetails_save();
    }
  }).catch((error) => {
    if(error.response.status === 401){
      forceLogout();
     }else if(error.response.status === 409){
           this.setState({dialog_flags : {block_save : true},mini_loading : false});
     }else{
         this.setState({mini_loading : false});
         window.alert(msg.api_error);
       }
  });
  }
  /*Make as complete button click*/
  skipUpload = () => {
    var comp = c.UI_COMP.document.toString();
    var action = c.UI_ACTION.Document_marked_as_completed;
     this.setState({mini_loading: true,card_add: false,card_edit: false,category_card: false});
    var query = {
      "doc_id": this.state.doc_id,
      "revision_id" : this.state.data.Revision_ID
    }
  api.skip_upload(query,comp,action).then((response) => {
    if (response.status === 200) {
      this.getDocDetails_save();
    }
  }).catch((error) => {
    if(error.response.status === 401){
      forceLogout();
     }else if(error.response.status === 409){
           this.setState({dialog_flags : {block_save : true},mini_loading : false});
     }else{
         this.setState({mini_loading : false});
         window.alert(msg.api_error);
       }
  });
  }
  //saves ehr data to backend. update the key "ehrDetails" inside Entity_Details
  saveEHRDetails = (doc_level,ehr) => {
    var res = JSON.parse(JSON.stringify(this.state.data));
    if(doc_level === "encounter"){
      //checks if data is changed from previous value. if so, shows save and discard button
      if( _.isEqual(this.state.data.Entity_Details.ehrDetails && this.state.data.Entity_Details.ehrDetails["encounter"], ehr.ehrDetails["encounter"]) !== true)
        this.setState({isSave: true});  
      const final_enc = Object.assign(res.Entity_Details,ehr);
      res.Entity_Details = final_enc;
    }else if(doc_level === "order"){
      if( _.isEqual(this.state.data.Entity_Details.ehrDetails && this.state.data.Entity_Details.ehrDetails["order"], ehr.ehrDetails["order"]) !== true)
           this.setState({isSave: true});  
      const final_order = Object.assign(res.Entity_Details,ehr);
      res.Entity_Details = final_order;
    }  
    this.setState({data : res});
  }
  /* save data button click action, takes a copy of data in res variable, does modification and saves only Entity_Details to backend*/ 
  saveAllData = (str) => {
    var res = JSON.parse(JSON.stringify(this.state.data));
    if(res.Entity_Details.ehrDetails){
    if(res.Entity_Details.classify.documentLevel === "Encounter"){
      res.Entity_Details.ehrDetails["order"] = {};
    }else if(res.Entity_Details.classify.documentLevel === "Order"){
      res.Entity_Details.ehrDetails["encounter"] = {};
    }else if(res.Entity_Details.classify.documentLevel === "Patient"){
      res.Entity_Details.ehrDetails["order"] = {};
      res.Entity_Details.ehrDetails["encounter"] = {};
    }
   } 
    if (str === 'duplicate') {
         res.Entity_Details.notifications = {};
    }
    let { notes, selected_tags } = res.Entity_Details || {};
    let flagCheck = res.Tags && res.Tags.findIndex(m => m.enabled);
    if(str == "save" && ((selected_tags && selected_tags.length) || (this.state.notesChanged && flagCheck > -1)) && !notes && !this.state.is_flag_notes_proceed) {
      this.onDialogAction("flag_notes", "open");
      return false
    }
    this.setState({ data: res});
    this.setState({mini_loading: true,card_add: false,card_edit: false,category_card: false, is_flag_notes_proceed: false, notesChanged: false});
    var comp = c.UI_COMP.document.toString();
    var action = c.UI_ACTION.Document_updated;
    api
      .update_after_review(
        res.Entity_Details,
        this.state.doc_id,
        this.state.data.Revision_ID,
        comp,
        action,
        str
      )
      .then((response) => {
        if (response.status === 200) {
         if(str !== "pm" ){
              this.setState({ dialog_flags : { save_dialog: str === 'duplicate' ? false : true} , edit_free_text: false,edit_doc_desc: false});
              setTimeout(() => {
                this.setState({ dialog_flags : {save_dialog: false}});
              }, 1000);
        }
        this.setState({isSave: false});
        this.getDocDetails_save();
        }
      }).catch((error) => {
        if(error.response.status === 401){
         forceLogout();
        }else if(error.response.status === 409){
              this.setState({dialog_flags : {block_save : true},mini_loading : false});
        }else{
            this.setState({mini_loading : false});
            window.alert(msg.api_error);
          }
        });
  };
  discardChanges = () => {
    this.setState({dialog_flags : {discard_dialog: true}, card_add: false,card_edit: false,category_card: false});
  };
  highlight = (key, vertices) => {
    var width = "";
    var height = "";
    var a1 = "";
    var a2 = "";
    var b1 = "";
    var c2 = "";
    var a_point = "";
    if (document.getElementById("docImage")) {
      width = document.getElementById("docImage").clientWidth;
      height = document.getElementById("docImage").clientHeight;

      a_point = document.getElementById("highlight-a");

      if (key === "hide") {
        a_point.style.visibility = "hidden";
      } else {
        a_point.style.visibility = "visible";
      }
      if (vertices) {
        for (var i = 0; i < Object.entries(vertices).length; i++) {
          if (Object.entries(vertices)[i][0] === key) {
            var vert = Object.entries(vertices)[i][1].valueVertices;
            if (vert) {
              a1 = width * vert[0].x;
              a2 = height * vert[0].y;
              b1 = width * vert[2].x;
              c2 = height * vert[2].y;
              //a_point.style.top = (((a2/height) *100) +19) +'%';
              a_point.style.top = a2 + "px";
              a_point.style.left = a1 + "px";
              a_point.style.width = b1 - a1 + "px";
              a_point.style.height = c2 - a2 + "px";
            }
          }
        }
      }
      /*a1 = width * 0.721;
  a2 = ((1 * (width * 1.29)) + ((width * 1.29) * 0.350));
  b1 = width * 0.825;
 
  c2 = ((1 * (width * 1.29)) + ((width * 1.29) * 0.366));;
  a_point.style.top = a2 +'px';
  a_point.style.left = a1 + 'px'; 
  a_point.style.width = (b1 - a1) + 'px';
  a_point.style.height = (c2 - a2) + 'px';*/
    }
  };
  /*
  zoomIn = () => {
    var image = document.getElementById("docImage");
    var currWidth = image.clientWidth;
    image.style.width = currWidth + 40 + "px";
    document.getElementById("img-div").style.overflow = "scroll";
  };
  zoomOut = () => {
    var image = document.getElementById("docImage");
    var currWidth = image.clientWidth;
    image.style.width = currWidth - 40 + "px";
  };*/
  /*"back to document list" link action */
  gotToDocMgmt = () => {
    var filter_data = JSON.parse(localStorage.getItem('is_filter'));
    filter_data.isFilter ? localStorage.setItem('from_details', 'true') : localStorage.setItem('from_details', 'false');
    var _props = this.props;
    _props.handlePageChange(c.PAGE_DOC_MGMT);
    _props.history.push(c.PAGE_URLS[c.PAGE_DOC_MGMT]);
  }
  /*handles edit mode and view mode for KVP cards*/
  handleCardAction = (edit,edit_val,add,add_val) => {
      if(edit)
        this.setState({card_edit: edit_val});
      if(add)
        this.setState({card_add: add_val});
  }
  /*handles edit mode and view mode for category card*/
  handleCategoryCardAction = (isEdit) => {
    if(isEdit)
      this.setState({category_card: true});
    else
      this.setState({category_card: false});
  }
  /*handles flag notes section. edit,save,cancel action */
  handleNotes = (action,data) => {
     if(action === "edit")
        this.setState({ edit_free_text : true});
     else if (action === "save") {
       let flag = false;
       (this.state.data?.Tags || []).forEach(m => {
         if (m.tag_display_name == "SPAM" && m.enabled) {
           flag = true;
         }
       });
       if (flag && data?.trim() && !(data?.match(/^(\+\d{1,2}\s)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/i))) {
         this.onDialogAction("spamAlert", "open");
         return true;
       }

       this.setState({ edit_free_text: false, isSave: true, notesChanged: true });
       var res = this.state.data;
       res.Entity_Details.notes = data;
       this.setState({ data: res });
      }else if(action === "cancel")
           this.setState({edit_free_text : false});
}
/*handles doc description section.edit,save,cancel action */
handleDocDesc = (action,data) => {
  if(action === "edit")
        this.setState({ edit_doc_desc : true});
      else if(action  ===  "save"){
          this.setState({edit_doc_desc : false, isSave : true});
          var res = this.state.data;
          res.Entity_Details.doc_description = data;
          this.setState({data : res});
      }else if(action === "cancel")
           this.setState({edit_doc_desc : false});
}
/*handles doc flags component.edit,save action */
handleFlags = (event, index,modal) => {
  var flag_list = JSON.parse(JSON.stringify(this.state.data));
   if(modal !== "save"){
     if (event.target.checked) { 
       if(flag_list.Tags[index]?.tag_display_name == "SPAM") {
        this.onDialogAction("spamWarning", "open");
        flag_list.Tags.forEach((m, i )=> {
          if(i != index) {
            m.enabled = false;
            m.disabled = true
          }
        });
       }
      flag_list.Tags[index].enabled = true;
     }
    else {
      flag_list.Tags[index].enabled = false;
      if(flag_list.Tags[index]?.tag_display_name == "SPAM") {
        flag_list.Tags.forEach((m, i )=> {
            m.disabled = false;
        });
       }
    }
    if(JSON.stringify(flag_list) !== JSON.stringify(this.props.data.data[0]))
      this.setState({flag_glph : true});
    else
      this.setState({flag_glph : false});
    
    flag_list.Entity_Details.notes = "";
    this.setState({ data : flag_list });
  
   }else{
    this.setState({isSave: true,flag_glph: false});
    var final_flags = [];
    flag_list.Tags && flag_list.Tags.forEach((flag) => {
       flag.enabled && final_flags.push(flag.tag_display_name);
     })
     flag_list.Entity_Details.selected_tags = final_flags;
     flag_list.Entity_Details.notes = "";
     this.setState({ data : flag_list });
   }
  };
  /* handles open,close of all dialogs used in the page. type- type of dialog, action- action performed for the dialog*/
  onDialogAction = (type,action) => {
    if(type === 'sucess'){
      this.setState({ dialog_flags : {save_dialog: false }});
      //this.getDocDetails();
    }else if(type === 'discard'){
      if(action === 'ok'){ 
        this.setState({dialog_flags : {discard_dialog: false}});
          this.getDocDetails_save();
      }else if(action === 'cancel')
        this.setState({dialog_flags : {discard_dialog: false}});
    }else if(type === 'block'){
      this.setState({dialog_flags : {block_save: false}});
      this.getDocDetails_save();
    }else if(type === 'duplicate'){
      if(action === 'open') {
        this.setState({ dialog_flags: { warning_dialog: true } });
      } else if(action === 'ok'){ 
        this.saveAllData(type);
        this.setState({ dialog_flags: { warning_dialog: false } });
      } else if (action === 'cancel')
        this.setState({ dialog_flags: { warning_dialog: false } });
    } else if(type === 'spamAlert'){
      if (action === 'open') {
        this.setState({ dialog_flags: { spam_alert: true } });
      } else if (action === 'ok') {
        this.setState({ dialog_flags: { spam_alert: false } });
      }
    } else if(type === 'spamWarning'){
      if (action === 'open') {
        this.setState({ dialog_flags: { spam_warning: true } });
      } else if (action === 'ok') {
        this.handleFlags("", "" , "save");
        this.setState({ dialog_flags: { spam_warning: false } });
      }
    } else if (type == "flag_notes") {
      if (action == "open") {
        this.setState({ dialog_flags: { flag_notes_warning: true } })
      }
      if (action == "ok") {
        this.setState({ dialog_flags: { flag_notes_warning: false }, is_flag_notes_proceed: true }, () => {
          this.saveAllData("save");
        })
      } else if (action == "cancel") {
        this.setState({ dialog_flags: { flag_notes_warning: false }, is_flag_notes_proceed: false })
      }
    }
    else if (type == "order"){
      if (action == "open"){
        this.setState({dialog_flags:{order_status_warning:true}})
      }
      else if (action == "ok"){
        this.setState({dialog_flags:{order_status_warning:false}})
      }
    }
}
/*handles on/off for overlay spinner*/
 fullLoader = (action) => {
    if(action === "show")
     this.setState({full_loading : true});
    else
    this.setState({full_loading : false});
  }

    render() {
    return (
      <>
        <Navbar
          all_state={this.state}
          handlePageChange={this.handlePageChange}
          changeCurrentPage={(page) => this.setState({ currentPage: page })}
        />
         {this.state.isLoading ? 
            <CircularProgress style={{ color: "grey", margin: "40vh auto" }} />
            :
            (
          <>
           {this.state.full_loading && 
             <CircularProgress size ={30} className = "common-spinner"/>
           }
          <div className={this.state.full_loading  ? "block-area-common" :""}>
             <div className="only-flex">
              <div className = "extract-area">
                 {this.state.mini_loading && 
                <CircularProgress size ={30} className = "common-spinner"/>
                  }
                  <div className={this.state.mini_loading  ? "block-area-common" :""}>
                        <HeaderSection
                          saveAllData={this.saveAllData}
                          gotToDocMgmt = {this.gotToDocMgmt}
                          all_state = {this.state}
                          getDocImage = {this.getDocImage}
                          getDocDetails = {this.getDocDetails} 
                          fullLoader = {this.fullLoader}
                          exitDoc = {this.exitDoc}
                        />
                    {this.state.data &&
                     (this.state.data.Status === c.status.queued ||
                      this.state.data.Status === c.status.in_progress) ? (
                      <div style={{ marginTop: "50px" }}>
                        <DialogModel
                          page="review"
                          status={
                            this.state.data && this.state.data.Status
                          }
                        />
                      </div>
                    ) : (
                      this.state.data && this.state.category_map &&
                          <>
                            <ButtonHandler
                              patient_create ={this.props.patient_create}
                              maximise_patient_create ={this.props.maximise_patient_create}
                              update_patient_create_info ={this.props.update_patient_create_info}
                              all_state={this.state}
                              saveAllData={this.saveAllData}
                              discardChanges={this.discardChanges}
                              uploadToOnbase={this.uploadToOnbase}
                              completeDocument={this.completeDocument}
                              skipUpload={this.skipUpload}
                            />
                            <EditManual
                              saveAllData={this.saveAllData}
                              discardChanges={this.discardChanges}
                              saveData={this.saveData}
                              deleteData={this.deleteData}
                              confirm_dialog={this.confirm_dialog}
                              highlight={this.highlight}
                              all_state={this.state}
                              handleNotes = {this.handleNotes}
                              handleDocDesc = {this.handleDocDesc}
                              handleCardAction = {this.handleCardAction}
                              handleFlags = {this.handleFlags}
                              handleCategoryCardAction = {this.handleCategoryCardAction}
                              uploadToOnbase = {this.uploadToOnbase}
                              completeDocument = {this.completeDocument}
                              saveEHRDetails = {this.saveEHRDetails}
                              onDialogAction= {this.onDialogAction}
                              skipUpload = {this.skipUpload}
                            />
                          </>
                      
                        
                      
                    )}
                 </div>
              </div>
              <div className="preview-area">
                <PreviewSection
                  data={this.state.data}
                  key={this.state.std_key}
                  image_url={this.state.image_url}
                />
              </div>
              <Dialogs
              dialog_flags = {this.state.dialog_flags}
              onDialogAction = {this.onDialogAction}
              content={this.state.patientCreateErrorMsg}
              />
         
          </div>
        </div>
        </>)}
        {/*<Snackbar
          open={this.state.delete_card}
          autoHideDuration={3000}
          onClose={() => this.setState({ delete_card: false })}
          message="Deleted Paramater can be brought back by clicking on Discard changes."
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        ></Snackbar>*/}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.data,
    image_url: state.image_url,
    doc_id: state.doc_id,
    category_list : state.category_list,
    category_map: state.category_map,
    patient_create_details: state.patient_create || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getManualReviewData: (url) => dispatch(getManualReviewData(url)),
    getImageUrl: (url) => dispatch(getImageUrl(url)),
    getCategories: (url) => dispatch(getCategories(url)),
    getCategoriesMap: (url) => dispatch(getCategoriesMap(url)),
    patient_create: (action) => dispatch(PATIENT_CREATE_ACTIONS.TOGGLE_PATIENT_CREATE_POPUP(action)),
    maximise_patient_create: (action) => dispatch(PATIENT_CREATE_ACTIONS.MAXIMISE_PATIENT_CREATE_POPUP(action)),
    update_patient_create_info: (action) => dispatch(PATIENT_CREATE_ACTIONS.UPDATE_PATIENT_CREATE_DETAILS(action))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualReview);
