import { GNRL_SETTINGS_ACTION } from "../actions/actionTypes";

export function settings(state = [], action) {
    switch(action.type) {
        case GNRL_SETTINGS_ACTION.GNRL_SETTINGS_SUCCESS:
            return { ...state, settings: action.settings}
        default:
            return state;
    }
}


export function doc_id(state = [], action) {
    switch(action.type) {
        case GNRL_SETTINGS_ACTION.PASS_DOC_ID:
            return { ...state, doc_id: action.doc_id}
        default:
            return state;
    }
}
