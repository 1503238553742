import React from "react";
import * as c from "../../../utils/constants/constants";
import * as dayjs from "dayjs";
import './Filter.css';
import { Box, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMonthObj: dayjs(),
            month: "",
            month_arr: c.MONTH_ARR,
            year: "",
            years: [],
            from_date: "",
            to_date: ""
        }
    }

    handleFilterChange(col, value) {
        this.setState({ [col]: value });
        if (col === 'year') {
            var d = new Date();
            var n = d.getMonth();
            if (value == this.state.years[this.state.years.length - 1]) {
                var res = [...this.state.month_arr].slice(0, n + 1);
                this.setState({ month_arr: res });
                if (this.state.month > n)
                    this.setState({ month: n });
            } else {
                this.setState({ month_arr: c.MONTH_ARR });
            }
        }
        this.setState({
            [col]: value
        });
    }

    getCurrentMonthAndYear = () => {
        var d = new Date();
        var n = d.getMonth();
        var res = [...this.state.month_arr].slice(0, n + 1);
        this.setState({ month: n, month_arr: res });
        var max_yr = d.getFullYear();
        var min_yr = 2020;
        var years = [];
        for (var i = min_yr; i <= max_yr; i++) {
            years.push(i);
        }
        this.setState({ years: years, year: max_yr });
        var res = n + "," + max_yr;
        return res;
    };

    getDefaultDate = () => {
        let toDate = new Date();
        let fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);
        let from_date = `${fromDate.getFullYear()}-${(Number(fromDate.getMonth()) + 1) < 10 ? ("0" + (Number(fromDate.getMonth()) + 1)) : (Number(fromDate.getMonth()) + 1)}-${fromDate.getDate() < 10 ? ("0" + Number(fromDate.getDate())) : fromDate.getDate()}`;
        let to_date = `${toDate.getFullYear()}-${(Number(toDate.getMonth()) + 1) < 10 ? ("0" + (Number(toDate.getMonth()) + 1)) : (Number(toDate.getMonth()) + 1)}-${toDate.getDate() < 10 ? ("0" + Number(toDate.getDate())) : toDate.getDate()}`;
        this.setState({from_date, to_date}) 
    }

    componentDidMount() {
        this.getCurrentMonthAndYear();
        this.getDefaultDate()
    }

    render() {
        return (
            <div className="report-filters">
                    {this.props.selectedUsageTab == "custom_range" ? <>
                        {/* <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '15ch' },
                            }}
                        ><TextField
                                variant="standard"
                                style={{ width: "100%" }}
                                id="fromDate"
                                label="From"
                                type="date"
                                value={this.state.from_date || ''}
                                name="fromDate"
                                onChange={e => this.handleFilterChange("from_date", e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                    // min: "2022-09-01"
                                }}
                            />
                        </Box>
                        <div style={{ width: '30px' }}></div>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '15ch' },
                            }}
                        >
                            <TextField
                                variant="standard"
                                style={{ width: "100%" }}
                                id="toDate"
                                label="To"
                                type="date"
                                value={this.state.to_date || ''}
                                name="toDate"
                                onChange={e => this.handleFilterChange("to_date", e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box> */}
                    </> :
                        <>
                            <div className="rp-filter">
                                <FormControl
                                    style={{ width: "110px", marginRight: "10px" }}
                                >
                                    <Select
                                        native
                                        value={this.state.year}
                                        variant="standard"
                                        margin="dense"
                                        onChange={e => this.handleFilterChange("year", e.target.value)}
                                        inputProps={{
                                            name: "year",
                                            id: "outlined-age-native-simple",
                                        }}
                                    >
                                        {this.state.years.map((yr, i) => (
                                            <option key={i} value={yr}>
                                                {yr}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    style={{ width: "110px", marginRight: "10px" }}
                                >
                                    <Select
                                        native
                                        variant="standard"
                                        value={this.state.month}
                                        margin="dense"
                                        onChange={e => this.handleFilterChange("month", e.target.value)}
                                        inputProps={{
                                            name: "month",
                                            id: "outlined-age-native-simple",
                                        }}
                                    >
                                        {this.state.month_arr.map((month, i) => (
                                            <option key={i} value={i}>
                                                {month}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                        </div>
                        <button
                            className="apply-button"
                            type="submit"
                            onClick={() => this.props.getReportData(this.state)}
                        >
                            <p className="apply-button-text">Apply</p>
                        </button>
                        </>}

                    
            </div>
        )
    }

}
export default Filter;