import { combineReducers } from 'redux';
import { items, recentdocs, actionitems,logs,int_metrics,perform_metrics } from './getdocsstatus/reducers/reducer';
import { data,image_url,category_list,category_map } from './manualreview/reducers/reducer';
import { categoryitems, tabledata, totalrecords, alllocations, doc_image_url,results,locations_user,flags } from './docmanagement/reducers/reducer';
import {doc_id,settings} from './generalSettings/reducers/reducer';
import { usertabledata, alllocations_user, adduserresponse,allusers } from './usermanagement/reducers/reducer';
import { userprofiledata } from './userprofile/reducers/reducer';
import {audit,audit_all,auditResult} from './auditLog/reducers/reducer';
import {rp_metrics,avg_pg_category,avg_pg_loc,rp_graph} from './monthly_reports/reducers/reducer';
import {docstuck} from "./superAdmin/reducers/reducer";
import {patient_create} from "./patientCreate/reducers/reducer";

export default combineReducers({
    items,
    recentdocs,
    actionitems,
    logs,
    int_metrics,
    perform_metrics,
    categoryitems,
    tabledata,
    data,
    totalrecords,
    alllocations,
    doc_id,
    settings,
    usertabledata,
    allusers,
    adduserresponse,
    image_url,
    doc_image_url,
    audit,
    audit_all,
    auditResult,
    userprofiledata,
    alllocations_user,
    results,
    locations_user,
    category_list,
    category_map,
    flags,
    rp_metrics,
    avg_pg_category,
    avg_pg_loc,
    rp_graph,
    docstuck,
    patient_create
});