import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import * as f from "../../../../commonFunctions/commonFunctions";
import * as dayjs from "dayjs";

class TableData extends React.Component {
    render() {
        return (
            <TableBody>
        {this.props.finalData &&
          this.props.finalData.map((mp, i) => (
          
              <TableRow
                key = {i}
              >
                <TableCell
                  className="tableCellStyle"
                  align={mp.first_name === "" ? "center" : "left"}
                >
                  <div>{mp.first_name}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.last_name === "" ? "center" : "left"}
                >
                  <div>{mp.last_name}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.email === "" ? "center" : "left"}
                >
                  <div>{mp.email}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.role === "" ? "center" : "left"}
                >
                  <div>{mp.role}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.status === "" ? "center" : "left"}
                >
                  {mp.status ? "Active" : "Inactive"}
                </TableCell>
                {/* <TableCell
                  className="tableCellStyle"
                  align={mp.department === "" ? "center" : "left"}
                >
                  {mp.department}
                </TableCell> */}
                <TableCell
                  className="tableCellStyle"
                  align={mp.last_logged_time === "" ? "center" : "left"}
                >
                 <div> {mp.last_logged_date ? f.utcToLocal(
                    mp.last_logged_date + " " + mp.last_logged_time + " UTC"
                  ) : null}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.last_edited_date === "" ? "center" : "left"}
                >
                 <div> {mp.last_edited_date && dayjs(mp.last_edited_date).format("MM/D/YYYY, hh:mm A") || ""}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={"left"}
                >
                  <EditIcon onClick={() => this.props.getRowData(mp)} className = 'icon-review-style'></EditIcon>
                </TableCell>
              </TableRow>
            
          ))}
      </TableBody>
        )
    }
}

export default TableData;