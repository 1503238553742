import React, { Component } from "react";
import "./Filter.css";
import {
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox
} from "@material-ui/core";
import FlagList from "../../../components/FlagList/FlagList";

class Filter extends Component {
  state = {
    showFlags : false,
  }
  showFlagList = () => {
    this.setState({ showFlags: !this.state.showFlags});
  };
  updateFlags = (event,i,modal) => {
    this.setState({ showFlags: false});
    if(modal === "save")
      this.props.updateFlags();
  };
  render() {
    return (
      <>
        <div className = 'status-icon'>
          <Grid className="filter-fields">
           <div style = {{marginRight : '10px'}}>Date: </div>
            <Grid style={{ paddingRight: "10px" }}>
              <form onChange={this.props.handleChange}>
                <TextField
                  style={{ width: "100%" }}
                  id="fromDate"
                  label="From"
                  type="date"
                  //defaultValue="2017-05-24"
                  value={this.props.fromDate}
                  name="fromDate"
                  //className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </Grid>
            <Grid style={{ paddingRight: "10px" }}>
              <form onChange={this.props.handleChange}>
                <TextField
                  style={{ width: "100%" }}
                  id="toDate"
                  label="To"
                  type="date"
                  //  defaultValue="2017-05-24"
                  value={this.props.toDate}
                  name="toDate"
                  //className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </Grid>
            <div className="buttons-filter">
              <button
                className="apply-button"
                type="submit"
                onClick={() => this.props.handleSubmit()}
              >
                <p className="apply-button-text">Apply</p>
              </button>
              <button
                style={{ width: "120px" }}
                className="reset-button"
                type="submit"
                onClick={() => {
                  this.props.cancelFilter();
                }}
              >
                <p className="reset-button-text">Reset all filters</p>
              </button>
            </div>
          </Grid>
          </div>
          <div className = "status-icon">
            <div className = "status-checkbox">
                {this.props.status_arr && this.props.status_arr.map((status,i) => 
                   <FormGroup>
                   <FormControlLabel
                   style = {{
                     paddingRight : "5px",
                     backgroundColor : status.isChecked ? "#ececec" : ""}}
                     name={status.name}
                     control={
                       <Checkbox
                        size = "small"
                        checked = {status.isChecked}
                        className = 'check-box-color'
                        onChange = {(event) => this.props.handleCheckBoxes(event,i, 'status')}
                       />
                     }
                     label={<div style = {{textAlign : 'left'}}>{status.name}</div>}
                     labelPlacement="end"
                   />
                 </FormGroup>
                )}
                </div>
                <div className="vertical-mini-divider" style = {{height: "3em"}}></div>
               <div style={{ margin: "5px 0px 0px 10px" }}>
              <FlagList
                flag_list={this.props.flag_data}
                updateFlags={this.updateFlags}
                handleFlags={this.props.handleFlags}
                showFlagList = {this.showFlagList}
                showFlags = {this.state.showFlags}
                flag_glph = {this.props.flag_glph}
                modal = "doc_mgmt"
              />
            </div>
        </div>
      </>
    );
  }
}

export default Filter;
